.logbook-wrapper{

    height:calc(100% - 96px);
    padding: 0;

    .logbook{

        background: #FFFFFF;
        padding: 16px;
        border-radius: 8px; 
        margin:0px 24px ;
        display: flex;

        form{

            width: 67%;
            display: flex;

            .select-box{

                width: 65%;
                display: flex;
    
                .input-col{
    
                    width: 100%;
                    padding-right: 24px;
                    padding-left: 24px;

                    &:first-child{

                        padding-left: 0;
                        
                    }
    
                    .custom-float-field{
    
                        width: 100%;
                        margin-bottom: 0;
        
                    }
    
                }
    
            }
    
            .border-divider{
    
                position: relative;
    
                &::after{
    
                    content: "";
                    height: 100px;
                    width: 1px;
                    background-color: #ced4da;
                    position: absolute;
                    top: 0;
                    right: 0;
    
                }
    
            }
    
            .check-box{
    
                width: 35%;
                padding-left: 24px;
                padding-right: 8px;
    
                .title{
    
                    display: flex;
        
                    h6{
            
                        color: $Siemens_Petrol;
                        font-weight: 600;
                        font-size: 15px;
                        margin-right: 24px;
            
                    }
            
                }
    
                .check-box-col{
    
                    width: 100%;
                    margin-top: 12px;
                    height: 84px;
    
                    .box-col{

                        display: grid;
                        grid-template-columns: repeat(2, 1fr);
                        grid-column-gap: 16px;
                        grid-row-gap: 4px;
    
                        .custom-float-field{
    
                            margin-top: 8px;
                            overflow: hidden;
                            

                            label{

                                overflow: hidden;
                                white-space: nowrap;
                                text-overflow: ellipsis;

                            }
    
                        }
    
                    }
    
                }
    
            }

        }
    
        .btn-wrapper{
    
            width: 12%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
    
            .box-btn{
    
                box-shadow:none;
                height: 25px;
                font-weight: 600;
                font-size: 12px;
                border: none;
                background: none;
                color: $Siemens_Petrol ;
                width: 135px;
                justify-content: space-between;

                svg {

                    height: 16px;
                    width: 16px;
                    margin-left: 8px;

                }
                
            }
    
        }
    
        .search-col {
    
            width: 21%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
    
            button {
                padding: 0 40px;
                height: 35px;
                margin: 0 1rem 0 0;
    
                &:focus {
                    box-shadow: none;
                }
    
                &.submit-btn {
                    background: $Siemens_Petrol;
                    border-color: $Siemens_Petrol;
                    color: $white;
                    width: 60%;
                }
    
                .p-button-label {
                    font-size: $font-size-h4;
                    font-weight: 500;
                }
            }
        }
    
    }

    .logbook-table{

        background: #FFFFFF;
        border-radius: 8px; 
        margin:8px 24px 0 24px;
        height: calc(100% - 140px);

        .icon-wrapper {
            background-color: #fff;
            align-items: flex-end;
            padding-bottom: 8px;
            display: flex;
            justify-content: flex-end;

            .table-config {
                @include display(flex);
                @include flex-alignItems(center);

                .action-btns {
                    @include display(flex);
                    @include flex-alignItems(center);
                    @include flex-justifyContents(space-evenly);
                    background: $Siemens_Border__Light;
                    color: $Siemens_Petrol;
                    @include border-radius(4px);
                    padding: 3px 7px 3px 15px;
                    cursor: pointer;

                    .button-icon {
                        background: inherit;
                        color: inherit;
                        border: none;
                        padding: 0;
                        box-shadow: none;
                        margin-right: 20px;

                        .icon {
                            width: 16px;
                            height: 16px;
                        }
                        span {
                            height: 10px;
                            width: 10px;
                            margin-top: -21px;
                        }
                        .caret-icon {
                            width: 15px;
                            height: 12px;
                            position: relative;
                            left: 2px;
                            color: $Blue__Light;
                            padding: 0;

                            &:focus {
                                box-shadow: none;
                            }
                        }
                    }
                }
            }
        }

        .custom-table{

            height: calc(100% - 38px);

            .p-datatable-header{

                display: none;
                
            }

            .p-datatable-wrapper {

                height: calc(100% - 46px);
                

            }

        }
        
    }

}

.logbook-activity{
    width: 92vw;
    top: 36px;
    left: 20px;
    height: 100%;

    .p-dialog-content {
        padding-left: 0;
        padding-right: 0;
        overflow-y: unset;
        background: transparent;
        height: 100%;

        .custom-header {
            background: $Siemens_Petrol;
            border-radius: 8px 8px 0px 0px;
            padding: 0px 24px;

            .box {
                @include display(flex);
                @include flex-direction(column);
                @include flex-justifyContents(center);
            }

            .title {
                color: $white;
                font-size: $font-size-h5;
            }

            .sub-title {
                color: $white;
                font-size: $font-size-h5;
                font-weight: 600;
            }

            .pi.pi-times {
                color: $white;
                cursor: pointer;
                font-weight: 700;
            }
        }

        .custom-table {

            height: calc(100% - 27.5px);
            background: #fff;
      
              .p-datatable {
      
                height: calc(100% - 54px);
      
                .p-datatable-header {
                    padding: 0.2rem;
                }
        
                tr {
                    th {
                        padding: 0.5rem 1rem !important;
                    }
        
                    td {
                        padding: 0.5rem 1rem;
        
                        input {
                            width: 30px;
                            height: 19px;
                            padding: 0;
                            text-align: center;
                            font-size: 12px;
                            border-radius: 4px;
                        }
                    }
                }
            }
      
        }
       
    }
}