.customers-container {
    margin: 0 25px 0 20px;
    height: calc(100% - 120px);

    .customer-filter {
        flex: 1;
        max-width: 2%;
        margin-right: 7px;

        &.expanded {
            max-width: 18%;
        }
    }

    .customer-table {
        flex: 1;
        max-width: 98%;

        &.expanded {
            max-width: 82%;
        }
    }
}