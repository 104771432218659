.custom-card {
    margin: 0 24px;

    .title-section {
        .title {
            font-size: $font-size-h4;
            color: $Siemens_Petrol;
            position: relative;

            &::after {
                content: "";
                position: absolute;
                bottom: -10px;
                left: 9px;
                width: 80%;
                border-bottom: 2px solid $Siemens_Petrol;
            }
        }
    }

    .body-section {
        background: #FFFFFF;
        margin: 10px 0;
        height: calc(100vh - 9rem);

        .modal-dialog {
            max-width: 100%;
            margin: 0;
            height: 100%;

            .modal-content {
                border: 0;

                .modal-body {
                    padding: 2rem;

                    .box {
                        .modal-icons {
                            width: 55px;
                            height: 55px;
                            color: light-shade($Siemens_Petrol, 20%);
                        }

                        .heading {
                            color: light-shade($Siemens_Petrol, 10%);
                            font-size: $font-size-h4;
                            padding-bottom: 5px;
                        }

                        .sub-heading {
                            color: $Grey__Dark;
                            font-size: $font-size-h5;
                            padding-bottom: 5px;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
}