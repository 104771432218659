$body-font: 'Source Sans Pro',
    sans-serif;
$heading-font: 'Source Sans Pro',
    sans-serif;
$body-font-color: #37465a;
$body-bg: #F5F9FF;

// ============== Colors ==================

$white :#FFFFFF;
$black :#000000;

// ================== Primary Colours ==================

$Siemens_Petrol :#009999;

$Siemens-Dark_Green :#495057;

$Siemens_Stone__Light :#BECDD7;
$Siemens_Stone__Dark :#879BAA;

$Siemens_Border__Light: #CCEBEB;

$Siemens_Sand__Light :#D7D7CD;
$Siemens_Sand__Dark :#AAAA79;


// ================== Accent Colours ==================

$Yellow__Light :#FFB900;
$Yellow__Dark :#EB780A;
$Yellow_Medium: #F2C94C;

$Red__Light :#CC0000;
$Red__Dark :#990000;
$Red_Medium: #AF2E5F;

$Blue__Light :#55A0B9;
$Blue__Dark :#006487;

$Green__Light :#AAB414;
$Green__Dark :#647D2D;
$Green_Medium: #24F1C1;

$Grey__Light :#505A64;
$Grey_Medium: #EEEEEE;
$Grey__Dark :#0F1923;
$Grey_Disabled: #999999;

$Slate_Grey: #F0F4F7;

$Border_Light: #CCCCCC;

$badge-red-count: #C76D8F;


$selection-bg: #879BAA;
$selection-text-color: #879BAA;

$Grey_Theme_Medium: #EBF0F5;
$Grey_Theme_Border: #D9EEEE;


// === === Different font sizes =========

$font-size-base: 0.875rem;
$font-size-h1: 1.625rem;
$font-size-h2: 1.25rem;
$font-size-h3: 1.0625rem;
$font-size-h4: 0.9375rem;
$font-size-h5: 0.8125rem;
$font-size-h6: 0.625rem;
$font-size-sm : 0.563rem;
$font-size-xs : 0.563rem;


$display-1 : 4rem;
$display-2 : 3.25rem;
$display-3 : 2.625rem;
$display-4 : 2rem;

// ========= Media Query Break Points ======= 

// Small tablets and large smartphones (landscape view)
$screen-sm : 576px;

// Small tablets (portrait view)
$screen-md : 768px;

// Tablets and small desktops
$screen-lg : 992px;

// Large tablets and desktops
$screen-xl : 1200px;

// ======= Gradient ========

// $color-1                    : rgba(80,190,190,0.8);
// $color-2                    : rgba(0,153,153,0.8);
// $color-3                    : rgba(0,153,176,0.8);
// $color-4                    : rgba(0,153,203,0.8);
// $list                       : $color-1 0, $color-2 53%, $color-3 80%, $color-4 100%;
// $direction                  : to right;
// $image-url                  : url('../img/login-bg.jpg');