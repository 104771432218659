.compare-inspection {
    .p-dialog-content {
        height: 640px;

        .content-box {
            height: 100%;

            .table-wrap {
                max-height: none;

                height: calc(100% - 34px);

                .custom-table {
                    height: 100%;

                    .p-datatable-wrapper {
                        height: calc(100% - 72px);
                    }
                }
            }
        }
    }
    .fw-700 {
        font-weight: 700 !important;
    }
}
