.drawing-area {

    height: 100%;
    width: 100%;
    position: relative;
    padding: 8px;
    border: 8px solid $white;
    overflow: hidden;

    canvas {

        height:100%;
        width: 100%;
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;

    }

    .content-loader {

        &.map-loader {

            position: absolute;
            top: 50%;
            right: 50%;
            transform: translate(50%, -50%);
            height: 128px;
            width: 128px;

        }

    }

}
    .drawing-spinner{

        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .p-progress-spinner {
            width: 50px !important;
            height: 50px !important;
            z-index: 99;
    
            .p-progressbar-value {
    
                background-color: $Siemens_Petrol;
            }
        }
    
    }