.field-order-model{

    .p-dialog-content{

        padding: 0 8px 16px 16px;

        .col-list__wrapper{

            .list-group{
    
                .list-group-item{
    
                    display: flex;
                    padding: 4px 4px 4px 24px;
                    background: #fbfbfb;
                    border-bottom:1px solid #009999;
                    font-size: 0.8125rem;
                    color: #009999;
                    border-radius: 0;
                    align-items: center;
                    cursor: pointer;
                    position: relative;
    
                    .grip-dots{

                        position: absolute;
                        z-index: 1010;
                        width: 24px;
                        height: 28px;
                        overflow: hidden;
                        display: flex;
                        left: 0;
                        top: 0;
                        opacity: 0;
    
                        svg{
    
                            color: $Siemens-Petrol;
                            position: absolute;
                            top: 0;
                            bottom: 0;
                            margin: auto;
                            height: 20px;
                            width: 20px;

                            &:first-child {

                                left: -4px;

                            }

                            &:last-child {

                                right: 1px;
                            }

                        }
                    }

                    &:hover,
                    &:active{

                        background-color: #D9EEEE;

                        .grip-dots {

                            opacity: 1 !important;
    
                        }

                    }
    
                }
    
            }
    
        }

    }

}