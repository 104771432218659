.asBuild-parent{

    margin: 0 25px 0 35px;
    height: calc(100% - 96px);

    .asBuild-wrapper{

        display: flex;
        position: relative;
        margin-top: 2px;
        height: 100%;

        .as-build-drawing__wrapper{

            height: 100%;
            width:50%;
            padding-right: 8px;

            .asBuild-drawing{

                position: relative;
                height: 100%;
                background-color: #fff;

                .drawing-expand-btn{

                    position: absolute;
                    right: -4px;
                    top: 0px;
                    bottom: 0;
                    margin: auto;
                    box-shadow: 1px 1px 7px #acacac;
                    cursor: pointer;
                    background: white;
                    border-radius: 4px;
                    height: 24px;
                    width: 24px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    z-index: 99;

                    .icon{

                        color:$Siemens_Petrol;
                        width: 17px;
                        height: 17px;

                    }

                }

                .type-list{

                    background-color: $Siemens_Petrol;
                    border-radius: 8px 8px 0 0;
                    display: grid;
                    grid-template-columns: repeat(4,1fr);
                    grid-column-gap: 16px;
                    grid-row-gap:4px;
                    padding-right: 16px;
                    


                    .item{

                        padding: 0 8px;
                        min-width: 0;
                        height: 54px;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;

                        h6{

                            line-height: 20px;
                            color: $white;
                            font-size: $font-size-h5;
                        
                        }

                        span {

                            display: block;
                            line-height: 20px;
                            color: $white;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            font-size: $font-size-h5;
                            font-weight: 600;

                        }

                    }


                }

                .drawing-data{

                    background-color:$white;
                    height:calc(100% - 112px);
                    position: relative;

                    .toolbar{

                        display: flex;
                        position: absolute;
                        top: 16px;
                        z-index: 999;
                        padding-left: 16px;
                        width: 100%;

                        .left-toolbox{

                            display: flex;
                            overflow: auto;
                            width: calc(100% - 141px);

                            .comments-icon{

                                background: $Siemens_Border__Light;
                                color: $Siemens_Petrol;
                                border-radius: 4px;
                                cursor: pointer;
                                display: flex;
                                padding: 0 0 0 12px;
                                margin-right: 20px;

                                &.comments-icon:last-child{

                                    margin-right: 0;
    
                                }
    
                                .button-icon{
                                    
                                    background: inherit;
                                    color: inherit;
                                    border: none;
                                    padding: 0;
                                    box-shadow: none;
                                    height: 32px;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    margin-right: 12px;
    
                                    .icon{
    
                                        width: 16px;
                                        height: 16px;
                                    }
    
                                }
    
                            }
    
                            .search-box{
    
                                background: $Siemens_Border__Light;
                                color: $Siemens_Petrol;
                                @include border-radius(4px);
                                cursor: pointer;
                                margin-right: 16px;
    
                                .button-icon {
    
                                    background: inherit;
                                    color: inherit;
                                    border: none;
                                    padding: 0;
                                    box-shadow: none;
                                    height: 32px;
                                    width: 32px;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                          
                                    .icon {
    
                                        width: 16px;
                                        height: 16px;
    
                                    }
    
                                    .caret-icon {
    
                                        width: 15px;
                                        height: 12px;
                                        position: relative;
                                        left: 2px;
                                        color: $Blue__Light;
                                        padding: 0;
                          
                                        &:focus {
    
                                            box-shadow: none;
    
                                        }
    
                                    }
    
                                }
    
                            }
                            
                            .tools{
    
                                background: $Siemens_Border__Light;
                                color: $Siemens_Petrol;
                                @include border-radius(4px);
                                cursor: pointer;
                                display: flex;
                                padding: 0 0 0 12px;
                                margin-right: 20px;
                                
                                .button-icon {
    
                                    background: inherit;
                                    color: inherit;
                                    border: none;
                                    padding: 0;
                                    box-shadow: none;
                                    height: 32px;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    margin-right: 12px;
                          
                                    .icon {
    
                                        width: 16px;
                                        height: 16px;
    
                                    }
    
                                    .caret-icon {
    
                                        width: 15px;
                                        height: 12px;
                                        position: relative;
                                        left: 2px;
                                        color: $Blue__Light;
                                        padding: 0;
                          
                                        &:focus {
    
                                            box-shadow: none;
    
                                        }
    
                                    }
    
                                }

                                .select-col{

                                    margin-right: 12px;
                                    margin-right: 12px;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
    
                                    .p-dropdown{
        
                                        .p-dropdown-label {
    
                                            padding: 0px 10px;
                                            width: 100%;
                                            height: 20px;
                                            display: flex;
                                            justify-content: center;
                                            align-items: center;
                                            font-size: 14px;
                                            line-height: 14px;
    
                                        }
    
                                        &:not(.p-disabled) {
                                            &:hover {
                                                border-color: $Siemens_Petrol;
                                            }
                            
                                            &.p-focus {
                                                border-color: $Siemens_Petrol;
                                                box-shadow: none;
                                            }
                                        }
    
                                        .p-dropdown-trigger{
    
                                            width: 20px;
    
                                            .pi {
                                                font-size: 12px;
                                            }
    
                                        }
    
                                    }
    
                                }
    
                            }

                        }

                        .right-toolbox{

                            background: #CCEBEB;
                            color: #009999;
                            border-radius: 4px;
                            cursor: pointer;
                            display: flex;
                            padding: 0 0 0 12px;
                            position: absolute;
                            right: 16px;
                            top: 0px;

                            .button-icon{
                                
                                background: inherit;
                                color: inherit;
                                border: none;
                                padding: 0;
                                box-shadow: none;
                                height: 32px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                margin-right: 12px;

                                .icon{

                                    width: 16px;
                                    height: 16px;
                                }

                                .caret-icon {
                                    width: 15px;
                                    height: 12px;
                                    position: relative;
                                    left: 2px;
                                    color: $Blue__Light;
                                    padding: 0;
        
                                    &:focus {
                                        box-shadow: none;
                                    }
                                }

                            }

                        }

                    }

                    .drawing-wrapper{

                        height:calc(100% - 5px);

                        img{
                            width: 100%;
                            height: 100%;
                        }

                    }

                }

            }

        }
               
        .as-build-table__wrapper{

            height: 100%;
            width: 50%;
            position: relative;
            padding-left: 24px;

            .table-expand-btn{
                        
                position: absolute;
                left: 20px;
                top: 0;
                bottom: 0;
                margin: auto;
                box-shadow: 1px 1px 7px #acacac;
                cursor: pointer;
                background: white;
                border-radius: 4px;
                height: 24px;
                width: 24px;
                display: flex;
                justify-content: center;
                align-items: center;
                z-index: 1000;

                .icon{

                    color:$Siemens_Petrol;
                    width: 17px;
                    height: 17px;

                }

            }

            .as-build-table{

                height: 100%;
    
                .table-header{
    
                    background-color: $Siemens_Petrol;
                    color: $white;
                    font-size: $font-size-h5;
                    @include border-radi(4px, 4px, 0, 0);
                    padding: 0px 16px;
    
                }
    
                .header-second-row{
    
                    display: flex;
                    justify-content: space-between;
                    background-color: #fff;
                    align-items: center;
                    padding: 0 8px 0 8px;
            
                    .left-col{
            
                        display: flex;
                        align-items: center;
                        width: calc(100% - 268px);
    
                        .select-box{
    
                            display: flex;
                            align-items: center;
                            padding: 8px 0 8px 8px;
                            width: 100%;
                    
                            .custom-float-field{
                    
                                display: flex;
                                align-items: center;
                                margin-bottom: 0;
                                width: 100%;
                    
                                .field-name-col{
                    
                                    display: flex;
                                    align-items: center;
                                    width: 70px;
                                    white-space: nowrap;
                    
                                    span{
                                        color: red;
                                    }
                    
                                }
                    
                                .field{
                    
                                    margin-bottom: 0;
                                    padding: 0;
                                    width: calc(100% - 110px);
                                    max-width: 200px;
    
                                    .p-dropdown{
    
                                        .p-dropdown-label {

                                            padding: 2px 10px;
                                            width: 100%;

                                        }
    
                                    }

                                    .action-btns {
                                        @include display(flex);
                                        @include flex-alignItems(center);
                                        @include flex-justifyContents(space-between);
                                        background: $Siemens_Border__Light;
                                        color: $Siemens_Petrol;
                                        @include border-radius(4px);
                                        cursor: pointer;
                                        height: 25px;
                                        width: 25px;
                                        margin-left: 8px;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
            
                                        &.add-icons {
                                            padding: 0 10px;
                                            width: 65px;
                                        }
            
                                        .button-icon {
                                            background: inherit;
                                            color: inherit;
                                            border: none;
                                            padding: 0;
                                            box-shadow: none;
            
                                            .icon {
                                                width: 12px;
                                                height: 12px;
                                            }
                                        }
                                    }
                    
                                }
                    
                                .icon-fields {
                                    
                                    margin-left: 8px;
                                    
                                    .field {
                                        margin-bottom: 0px;
                                        .action-btns {
                                            @include display(flex);
                                            @include flex-alignItems(center);
                                            @include flex-justifyContents(space-between);
                                            @include border-radius(4px);
                                            cursor: pointer;
                                            height: 25px;
                                            width: 25px;
                                            padding: 7px;
                                            background: $Siemens_Border__Light;
                                            color: $Siemens_Petrol;
                                            margin-right: 10px;
                    
                                            .button-icon {
                                                background: inherit;
                                                color: inherit;
                                                border: none;
                                                padding: 0;
                                                box-shadow: none;
    
                                            }
                                        }
                                    }
                                }
                    
                            }
                    
                        }
            
                    }
            
                    
            
                }
    
                .table-wrapper{
    
                    height:calc(100% - 61px);
                    background-color:$white;

                    .p-datatable-header{

                        display: none;

                    }

                    .p-datatable-wrapper {

                        height: calc(100% - 38px);

                    }
    
                }
    
            }

        }

        &.drawing-f {

            .as-build-drawing__wrapper {

                width: calc(100% - 36px);

                .asBuild-drawing{

                    .drawing-expand-btn{
                        
                        //display: none;
                        transform: rotate(180deg);

                    }

                }

            }

            .as-build-table__wrapper {

                width: auto;

                &:after {

                    content: "";
                    width: 2px;
                    height: 100%;
                    background-color: #bdc0c3;
                    position: absolute;
                    top: 0;
                    right: -9px;
                }

                .as-build-table{

                    width: auto;
    
                    .table-header {
    
                        display: none;

                    }
                    
                    .header-second-row {
    
                        display: none;
    
                    }
    
                    .table-wrapper {
    
                        display: none;
    
                    }
                }

            }
        }

        &.table-f {

            .as-build-drawing__wrapper{

                width:auto;

                &:after {

                    content: "";
                    width: 2px;
                    height: 100%;
                    background-color: #bdc0c3;
                    position: absolute;
                    top: 0;
                    left: -9px;
                }

                .type-list{

                    display: none;

                }

                .drawing-data{

                    display: none;

                }

            }

            .as-build-table__wrapper { 

                width: calc(100% - 8px);

                .table-expand-btn{

                    transform: rotate(180deg);
                    
                }

            }

        }

    }


}

/* list markups modal stayle */
.list-markups{
    width: 92vw;
    top: 36px;
    left: 20px;
    height: 100%;
    margin-bottom: 30px;
    background-color: #fff;

    .p-dialog-content {
        padding: 0;
        overflow-y: unset;
        background: transparent;
        height:90%;

        .header {

            background: $Siemens_Petrol;
            border-radius: 8px 8px 0px 0px;
            padding: 4px 16px;
            display: flex;
            align-items: center;

            .box-left {

                width: 50%;
                display: flex;
                align-items: center;

                .title {

                    color: $white;
                    font-size: $font-size-h5;
                    
                }

            }

            .box-right {

                width: 50%;
                display: flex;
                justify-content: flex-end;
                align-items: center;
    
                .pi.pi-times {

                    color: $white;
                    cursor: pointer;
                    font-weight: 700;

                }

            }

        }

        .container-fluid {

            .second-row {
                display: flex;
                background-color: #fff;

                .task-name{

                    display: flex;
                    align-items: center;
                    height: 42px;

                    span{
                        
                        cursor: pointer;
                        
                    }

                    h6{

                        margin-left: 16px;
                        
                    }

                }
        
                .icon-wrapper {
                    background-color: #fff;
                    align-items: flex-end;
                    padding-bottom: 8px;
                    display: flex;
                    justify-content: flex-end;
                }
            }
        }

        .custom-table{

            height: calc(100% - 70px);

        }

    }

}

/*drawing markup list*/
.drawing-markup-list{

    .custom-table{

        .color-select__wrapper {

            position: relative;

            .color-select {

                height: 18px;
                width: 32px;
                border: 1px solid #dee2e6;
                cursor: pointer;
                position: relative;
                border-radius: 4px;

            }

            #color-picker {

                display: block;
                z-index: 99;
                position: absolute;
                right: 38px;

            }
            
        }

    }

}
