.inspection-contracts{

    &.pre-loader {

        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        z-index: 9999;
        background-color: var(--maskbg);
    
    
        @keyframes p-progress-spinner-color {
    
            100%,
            0% {
                stroke: $Siemens_Petrol;
            }
    
            40% {
                stroke: $Siemens_Petrol;
            }
    
            66% {
                stroke: $Siemens_Petrol;
            }
    
            80%,
            90% {
                stroke: $Siemens_Petrol;
            }
        }
    
    }

}

.contracts-parent{
    
    background-color: #fff;
    padding: 0px 12px 12px 12px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    height: 100%;

    .questions-wrap{

         height: 100%;
         position: relative;

        .question-refresh{
            
            position: absolute;
            right: 48px;
            z-index: 99;
            top: 32px;

            &.action-btns {
                @include display(flex);
                @include flex-alignItems(center);
                @include flex-justifyContents(space-evenly);
                background: $Siemens_Border__Light;
                color: $Siemens_Petrol;
                @include border-radius(4px);
                padding: 4px;
                cursor: pointer;
          
                .button-icon {
                    background: inherit;
                    color: inherit;
                    border: none;
                    padding: 0;
                    box-shadow: none;
          
                    .icon {
                        width: 18px;
                        height: 18px;
                    }
                }
            }

        }

        .inspection-forms-wrapper{

            height: calc(100% - 40px);

            .main-wrapper{

                .title-header {
                
                    width: calc(100% - 18px);
                    
                }
                
            }

        }

        .footer-btn{

            padding-left: 10px;
    
            .field-checkbox{

                margin-bottom: 16px;
    
                label{
    
                    color: #495057;
                    font-size: $font-size-h5;
                    font-weight: 600;
    
                }
    
            }
    
            button {

                height: 40px;

                &:focus {

                    box-shadow: 0 0 0 0.1rem $Siemens_Petrol;

                }

                &.save-btn {

                    background: $Siemens_Petrol;
                    border-color: $Siemens_Petrol;
                    color: $white;
                    
                    span{

                        font-size: $font-size-h4;
                        font-weight: 500;

                    }

                }

            }

        }

    }
    

}