.inspection-reports {
    @include spacing();
    border-radius: 8px; 
    margin:0px 24px ;
    padding: 0;
    height: calc(100% - 96px);
    position: relative;

    .p-tabview{
        height: 100%;

    .p-tabview-nav-container{
        
        .p-tabview-nav-content{

            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
            
            .p-tabview-nav{

                .p-tabview-ink-bar{
                    background-color: $Siemens_Petrol;
                }
                .p-unselectable-text{
                    .p-tabview-nav-link{
                        color: $Siemens_Petrol;  
                        border-color:#dee2e6;

                        &:focus{  
                            box-shadow: none;
                        }
                      }    
                }
                .p-highlight{
                   
                      .p-tabview-nav-link{
                        color: $Siemens_Petrol;  
                        border-color: $Siemens_Petrol;

                        &:focus{  
                            box-shadow: none;
                        }
                        
                      }
                   
                   }
    
            }
            
        }
    }

    .p-tabview-panels{
        height: calc(100% - 58px);
        padding: 8px 0 0; 

        .p-tabview-panel {
            height: 100%;
            
        }
    }
}

    .generate-btn{

        position: absolute;
        right: 16px;
        top: 12px;
        background: #099;
        border-color: #099;
        color: #fff;
        height: 32px;
        font-size: 13px;

        &:hover {
            
            background: #099;
            border-color: #099;
            color: #fff;

        }

        &:focus {

            box-shadow: none;

        }

        span{

            font-weight: 600;

        }

    }

    .error-show{
        color: $Red__Light;
        position: relative;
        font-size: $font-size-h5;
        display: flex;
        align-items: center;
        top: 8px;

        svg {
            width: 15px;
            height: 15px;
            margin-right: 5px;
          }
    }

    .checkbox-error {
        top: 0px !important;
    }
    
    .generate-reports-wrapper{

        padding: 0 0 0 16px;
        border-radius: 10px;
        height:100%;

        form{

            height: calc(100% - 66px);
    
                    .generate-reports{
                        height: 100%;
                        padding-right: 32px;

                        .data-wrapper-scroll {
                            height: 100%; 
                            overflow: auto;  
                        }

                        .field{
        
                            .p-dropdown {
                                .p-dropdown-label {
                                padding: 0.5rem;
                                font-size: $font-size-h5;
                                }
                
                                &:not(.p-disabled) {
                                &:hover {
                                    border-color: $Siemens_Petrol;
                                }
                
                                &.p-focus {
                                    border-color: $Siemens_Petrol;
                                    box-shadow: 0 0 0 0.1rem $Siemens_Petrol;
                                }
                                }
                            }
                
                            .p-calendar,
                            .p-dropdown,
                            .p-inputnumber {
                                &.p-inputwrapper-focus + label,
                                &.p-inputwrapper-filled + label {
                                color: $Siemens_Petrol;
                                top: 0;
                                background: $white;
                                padding-left: 2px;
                                }
                
                                &.p-inputwrapper-filled + label {
                                color: $Siemens_Petrol;
                                }
                            }
                
                            .p-dropdown {
                                &.error {
                                border-color: $Red__Light;
                                box-shadow: none;
                                }
                            }
                
                            .p-float-label {
        
                                font-size: $font-size-h5;
                
                                .p-inputtext {

                                    padding: 0.5rem;
        
                                    &.error {
        
                                        border-color: $Red__Light;
                                        box-shadow: none;
                                    }
        
                                    &:enabled {
        
                                        &:hover {
        
                                            border-color: $Siemens_Petrol;
        
                                        }
        
                                        &:focus {
        
                                            border-color: $Siemens_Petrol;
                                            box-shadow: 0 0 0 0.1rem $Siemens_Petrol;
        
                                        }
                                    }
                                }
                
                            }
                
                            .title{                
                                color: #101010;
                                font-size: $font-size-h5;
                                font-weight: 400;
                                margin-bottom: 0;
                                
                            }
                
                            .border-box{                
                                background: #ffffff;
                                border: 1px solid #ced4da;
                                transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
                                border-radius: 6px;
                                position: relative;
                                padding:15px;

                                .title{
                
                                    position: absolute;
                                    top: 0;
                                    background: #FFFFFF;
                                    padding-left: 2px;
                                    font-size: $font-size-h5;
                                    margin-top: -0.7rem;
                                    left: 0.75rem;
                
                                }
                
                                .report-min-height{
                
                                    max-height: 180px;
                                    overflow: auto;

                                    .field-checkbox{
                                    
                                        label{ 

                                            color: #101010;
                                            font-size: 13px;
                                            font-weight: 400;
                                            line-height: 17px;
                                        
                                        }

                                        .no-branch-office {

                                            color: $Siemens_Petrol;

                                            cursor: pointer;

                                            &:hover {

                                                text-decoration: underline;

                                            }

                                        }   

                                        .p-checkbox-disabled {
                                            cursor: not-allowed !important;
                                        }   

                                    }

                                    .branch-office-validation{
                                        color: $Red__Light;
                                        font-size:$font-size-h5;                                       
                                        margin:4px 0 0 26px;
                                        span{
                                            font-size:$font-size-h4; 
                                            font-weight: 600;
                                        }
                                    }
                        
                                    .cal-title{                                      
                                        font-size: $font-size-h4;
                                        color: $Siemens_Petrol;
                                        font-weight: 600;
                                    }
                
                                }
                
                            }
                
                        }
                
                    }
        
        }

        .footer-btn{

            padding: 24px 0 16px 0;
            background-color: #fff;
    
            .field-checkbox{

                margin-bottom: 8px;
    
                label{
    
                    color: #495057;
                    font-size: $font-size-h5;
                    font-weight: 600;
    
                }
    
            }
    
            button {

                height: 40px;

                &:focus {

                    box-shadow: 0 0 0 0.1rem $Siemens_Petrol;

                }

                &.save-btn {

                    background: $Siemens_Petrol;
                    border-color: $Siemens_Petrol;
                    color: $white;
                    
                    span{

                        font-size: $font-size-h4;
                        font-weight: 500;

                    }

                }

            }

        }
        .pre-loader{

            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            z-index: 9999;
            background-color: var(--maskbg);

            
            @keyframes p-progress-spinner-color {
                100%,
                0% {
                    stroke:$Siemens_Petrol;
                }
                40% {
                    stroke:$Siemens_Petrol;
                }
                66% {
                    stroke:$Siemens_Petrol;
                }
                80%,
                90% {
                    stroke:$Siemens_Petrol;
                }
            }

        }
    
    }
    
    .reports-wrapper{

        height: 100%;

        .system-table{

            .custom-table {

                .p-datatable {

                    .p-datatable-wrapper {

                        height: calc(100% - 100px);

                    }
                    
                }

            }

        }
        
    }

}



.data-template {
    color: #009999;

    &:hover {

        text-decoration: underline;

    }
}

.report-dropdown-wrapper {
    .p-dropdown {

        .p-dropdown-label {
            padding: 0.2rem;
            font-size: $font-size-h5;
        }

        &:not(.p-disabled) {
            &:hover {
                border-color: $Siemens_Petrol;
            }

            &.p-focus {
                border-color: $Siemens_Petrol;
                box-shadow: 0 0 0 0 $Siemens_Petrol;
            }
        }

        .p-dropdown-trigger {
            width: 2rem;

            .p-dropdown-trigger-icon {
                font-size: 12px;
            }
        }

    }

}

