.sap-import{

    margin:0px 24px ;
    padding: 0;
    height: calc(100% - 96px);

    .breadcrumb-sub-page-title{
        color: $Siemens_Petrol;
        font-size: $font-size-h4;
        font-weight: 600;
    }

    .heading {
        background-color: $Siemens_Petrol;
        color: $white;
        font-size: $font-size-h5;
        @include border-radi(4px, 4px, 0, 0);
        padding: 0px 16px;
    }

    .second-row{

        display: flex;
        padding:8px;
        background-color: #FFFFFF;
        justify-content: space-between;
        align-items: center;

        .left-col{

            display: flex;

            .field{

                margin-bottom: 0;
                margin-right: 8px;

                .p-float-label {
                
                    .p-inputtext {
                        
                        height: 26px;
                        width: 15rem;
    
                    }
    
                }

            }

            .btn-col {

                display: flex;
                margin-left: 8px;
        
                .btn {

                    margin-right: 8px;
                    padding: 0px 8px;
                    height: 26px;
                    font-size: 13px;

                    &.cancel {

                        border-color: $Siemens_Petrol;
                        background: $white;
                        color: $Grey__Dark;
                    }

                    &.pre {

                        border-color: $Siemens_Petrol;
                        background: $white;
                        color: $Siemens_Petrol;

                    }

                    background: $Siemens_Petrol;
                    border-color: $Siemens_Petrol;
                    color: $white;
                    
        
                    &:focus {

                        outline: none;
                        box-shadow: none;
                        text-decoration: none;

                    }

                }

            }

        }

    }

    .sap-import-table{

        height: calc(100% - 106px);

        .custom-table{

            .p-datatable{

                .p-datatable-header{
                    display: none;
                }

                .p-datatable-wrapper{
                    height: calc(100% - 38px);
                }
            }
        }

    }

}

.sap-import-details{

    height: auto;

    .custom-table{

        .p-datatable{

            .p-datatable-header{

                padding: 0;

            }

            .p-datatable-wrapper {

                height: calc(100% - 40px);

            }

        }

    }

}