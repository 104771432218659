.filtered-result {
    max-height: calc(100vh - 18rem);
    overflow: auto;

    .field-checkbox {
        padding: 0.2rem 0.5rem;
        font-size: $font-size-h5;

        &.site-header {
            background: $Siemens_Border__Light;
            border: none;

            label {
                color: $Grey__Dark;
                font-weight: 500;
            }
        }

        &.site-item {
            background: $white;
            border-color: $Siemens_Border__Light;
            font-size: $font-size-h5;
            border-bottom: 1px solid $Siemens_Border__Light;

            &.selected,
            &:hover {
                background: $Siemens_Border__Light;

                label {
                    color: $Siemens_Petrol;
                }
            }

            .checked {
                +label {
                    color: $Siemens_Petrol;
                }
            }

            label {
                color: $Grey__Light;
                font-weight: 400;
            }
        }

        .p-checkbox {
            width: 20px;
            height: 20px;

            .p-checkbox-box {
                width: 20px;
                height: 20px;
                border-radius: 2px;
                border-color: $Siemens_Petrol;
                border-width: 2px;

                &.p-focus {
                    box-shadow: none;
                }

                &.p-highlight {
                    background: $Siemens_Petrol;
                }

                &:hover {
                    border-color: $Siemens_Petrol;
                }
            }

            &.p-checkbox-disabled {
                .p-disabled {
                    background: $Grey_Disabled;
                    border-color: $Grey_Disabled;
                }
            }
        }

        label {
            margin-left: 1.5rem;
        }
    }
}