.customize-report{
    width: 92vw;
    top: 36px;
    left: 20px;
    height: 100%;
    z-index: -9;
    position: relative;

    .p-dialog-content {
        padding: 0;
        overflow-y: unset;
        height: 100%;
        border-radius: 8px;

        .header {

            background: $Siemens_Petrol;
            border-radius: 8px 8px 0px 0px;
            padding: 4px 16px;
            display: flex;
            align-items: center;

            .box-left {

                width: 50%;
                display: flex;
                align-items: center;

                .title {

                    color: $white;
                    font-size: $font-size-h5;
                    
                }

            }

            .box-right {

                width: 50%;
                display: flex;
                justify-content: flex-end;
                align-items: center;
    
                .pi.pi-times {

                    color: $white;
                    cursor: pointer;
                    font-weight: 700;

                }

            }

        }

        .view{

            padding:16px;
            background-color: #fff;

            .position{

                display: flex;
                align-items: center;
                margin-bottom: 16px;

                span{

                    font-size:12px ;
                    margin-right: 16px;
                    font-weight: 500;
    
                }

                .field-radiobutton{

                    margin-bottom: 0;
                    margin-right: 16px;

                }

            }

        }

        .customize-report-grid{

            height: calc(100% - 169px);
            background-color: #fff;

            .p-datatable {

                overflow-x: hidden;
                height: 100%;
                
                .p-datatable-wrapper{
    
                    height: 100%;
    
                    .p-datatable-table{
    
                        //height: 100%;
    
                        .p-datatable-thead{
    
                            position: sticky;
                            top: 0;
                            z-index: 999;
    
                            tr {
        
                                th {
    
                                    background: #D9EEEE;
                                    color: $Grey__Dark;
                                    font-weight: 600;
                                    font-size: $font-size-h5;
                                    padding: 0.2rem 1rem;
    
                                    &.p-frozen-column {
                                        display: flex;
                                        flex: 0 0 60px;
                                    }
    
                                    &:focus {
                                        box-shadow: inset 0 0 0 0.1rem $Siemens_Petrol;
                                    }
    
                                    .p-column-header-content {
                                        .p-sortable-column-icon {
                                            color: $Siemens_Petrol;
                                            font-size: 12px;
                                        }
                                    }
        
                                }
                
                                td {
        
                                    padding: 0.5rem 0.2rem;
        
                                }
        
                            }
                        }
            
                        tbody{
        
                            height: calc(100% - 27px);
                            color: #0F1923;
                            font-size: 0.8125rem;
        
                            tr {

                                height: 30px;

                                th {
                                    padding: 0.5rem 0.2rem !important;
                                }
                
                                td {
                                    padding: 0.5rem 1rem;

                                    .p-inputtext:enabled:focus {
                                        border-color: $Siemens_Petrol;
                                        box-shadow: 0 0 0 0.1px $Siemens_Petrol;
                                    }
                            
                                    .p-inputtext:enabled:hover {
                                        border-color: $Siemens_Petrol;
                                    }
                
                                    .color-select__wrapper {
                                        position: relative;
                
                                        .color-select {
                                            height: 18px;
                                            width: 32px;
                                            border: 1px solid #dee2e6;
                                            cursor: pointer;
                                            position: relative;
                                            border-radius: 4px;
                                        }
                
                                        #color-picker {
                                            display: block;
                                            z-index: 99;
                                            position: absolute;
                                            left: 38px;
                                        }
                                    }
                
                                    input {
                                        width: auto;
                                        height: 19px;
                                        padding: 0;
                                        text-align: center;
                                        font-size: 12px;
                                        border-radius: 4px;
                                    }
                                }
                            }
        
                        }
    
                    }
    
                }
                
            }

        }

        ul.list {
            @include display(flex);
            @include flex-alignItems(center);
            padding: 8px 16px 16px 16px;
            background-color: #fff;
            border-radius: 8px;

            li.item {
                cursor: pointer;
                background: $white;
                color: $Grey__Dark;
                box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
                @include border-radius(8px);
                font-size: $font-size-h5;
                padding: 5px 20px;
                font-weight: 500;
                margin-right: 24px;

                &:hover{

                   //background-color: #D9EEEE;
                   background: $Siemens_Petrol;
                   transition: 0.3s;
                   color: $white;

                }

                &.active {
                    background: $Siemens_Petrol;
                    color: $white;
                }

                &:last-child {
                    margin-right: 0;
                }
            }
        }

    }
}

.report-field-order{

    .p-dialog-content{

        padding: 0 8px 16px 16px;
        display: flex;
        width: 100%;

        .report-field-col{

            width: 50%;

            &:first-child{

                margin-right: 16px;

            }

            h3{

                font-size: 14px;
                color: #009999;
                font-weight: 600;
                position: sticky;
                z-index: 9999;
                top: 0;
                background-color: #fff;

            }

            .col-list__wrapper{

                border: 1px solid #CCEBEB;
                padding: 0.5rem;
                border-radius: 10px;
                margin-top: 8px;
                height: calc(100% - 17px);
                overflow: auto;

                .list-group{
        
                    .list-group-item{
        
                        display: flex;
                        padding: 4px 4px 4px 24px;
                        background: #fbfbfb;
                        border-bottom:1px solid #009999;
                        font-size: 0.8125rem;
                        color: #009999;
                        border-radius: 0;
                        align-items: center;
                        cursor: pointer;
                        position: relative;
        
                        .grip-dots{
    
                            position: absolute;
                            z-index: 1010;
                            width: 24px;
                            height: 28px;
                            overflow: hidden;
                            display: flex;
                            left: 0;
                            top: 0;
                            opacity: 0;
        
                            svg{
        
                                color: $Siemens-Petrol;
                                position: absolute;
                                top: 0;
                                bottom: 0;
                                margin: auto;
                                height: 20px;
                                width: 20px;
    
                                &:first-child {
    
                                    left: -4px;
    
                                }
    
                                &:last-child {
    
                                    right: 1px;
                                }
    
                            }
                        }
    
                        &:hover,
                        &:active{
    
                            background-color: #D9EEEE;
    
                            .grip-dots {
    
                                opacity: 1 !important;
        
                            }
    
                        }
        
                    }
        
                }
        
            }

        }

    }

}