.header-container {
    background: $white;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05), 0px 25px 35px rgba(0, 0, 0, 0.03);
    padding: 0px 24px;
    @include display(flex);
    @include flex-alignItems(center);
    @include flex-justifyContents(space-between);
    max-height: 50px;
    position: relative;

    .logo {
        @include display(flex);
        @include flex-direction(column);
        cursor: pointer;

        img {
           height: 40px;
        }

        span {
            font-weight: 600;
            font-size: $font-size-h5;
            color: $Grey__Dark;
            position: relative;
            top: -9px;
            left: 4px;
        }
    }

    .info-section {
        @include display(flex);
        @include flex-alignItems(center);

        .yammer-link {
            width: 30px;
            height: 30px;
        }

        .old-site-section {
            display: flex;
            margin-right: 10px;
            height: 43px;
            align-items: flex-end;

            .link-site {
                a {
                    font-size: $font-size-h5;
                    color: $Grey__Dark;
                    text-decoration: underline;

                    &:hover {
                        color: #0d6efd;
                    }
                }
            }
        }

        .user-info {
            margin-right: 10px;

            .name {
                font-size: $font-size-h5;
                line-height: 24px;
                color: $Grey__Dark;

                .role {
                    color: $Siemens_Petrol;
                }
            }

            .location {
                font-size: $font-size-h5;
                font-weight: 600;
                line-height: 24px;
                color: $Siemens_Petrol;
                cursor: pointer;
            }
        }

        .avatar {
            margin-right: 15px;
            width: 28px;
            height: 28px;
            cursor: pointer;
            @include border-radius(50%);
            border: 2px solid $black;
            @include display(flex);
            @include flex-alignItems(center);
            @include flex-justifyContents(center);
        }

        .info-icon {
            background: $Grey_Medium;
            @include border-radius(50%);
            width: 22px;
            height: 22px;
            margin-right: 10px;
            color: black;
            font-weight: bold;
            @include display(flex);
            @include flex-alignItems(center);
            @include flex-justifyContents(center);
            cursor: pointer;
        }
    }

    .header-progressbar{

        width: 100%;
        position: absolute;
        left: 0;
        bottom: 0;
    
        .p-progressbar {
    
            height: 2px;
            
            border-radius: 0;
    
            .p-progressbar-value {
    
                background-color:$Siemens_Petrol;
            }
        }
    
    }

}


.selectsite {
    .p-dialog-footer {
        text-align: left;
        padding: 0.5rem 1rem 0.5rem 1.5rem;

        .btn {
            &.cancel {
                border-color: $Siemens_Petrol;
                background: $white;
                color: $Grey__Dark;
                margin: 0 20px 0 0;
            }
            &.pre {
                border-color: $Siemens_Petrol;
                background: $white;
                color: $Siemens_Petrol;
                margin: 0 20px 0 0;
            }
            background: $Siemens_Petrol;
            border-color: $Siemens_Petrol;
            color: $white;

            &:focus {
                outline: none;
                box-shadow: none;
                text-decoration: none;
            }
        }
    }
    .p-dialog-content {
        .pop-subhead {
            b {
                font-size: $font-size-h5;
            }
        }
        .condition-builder {
            display: flex;
            padding: 8px;

            .fields {
                display: flex;
                width: 85%;
                border: 1.5px solid $Siemens_Petrol;
                padding: 10px 5px 10px 5px;
                align-items: center;
                height: 77px;

                .field {
                    margin-right: 10px;
                    margin-bottom: 0px;
                    &.value {
                        width: 200px;
                    }
                    &.field-name {
                        width: 200px;
                    }
                }
            }

            .icon-fields {
                display: flex;
                width: 15%;
                justify-content: flex-end;
                align-items: center;
                .field {
                    margin-bottom: 0px;
                    .action-btns {
                        @include display(flex);
                        @include flex-alignItems(center);
                        @include flex-justifyContents(space-between);
                        @include border-radius(4px);
                        cursor: pointer;
                        height: 32px;
                        width: 35px;
                        padding: 0 10px;
                        background: $Siemens_Border__Light;
                        color: $Siemens_Petrol;
                        margin-right: 10px;

                        .button-icon {
                            background: inherit;
                            color: inherit;
                            border: none;
                            padding: 0;
                            box-shadow: none;

                            .icon {
                                width: 16px;
                                height: 16px;
                            }
                        }
                    }
                }
            }
        }
        .p-float-label {
            .label {
                font-size: 0.8125rem;
                font-weight: 400;
                color: $Siemens_Petrol;

                &.mandatory {
                    &::after {
                        content: " *";
                        color: #fe0808;
                    }
                }
            }
            &.text-container {
                label {
                    position: relative;
                    left: 0;
                    min-width: 70px;
                }
                .text-value {
                    font-size: $font-size-h5;
                    color: $Siemens_Petrol;
                    margin-left: 30px;
                    font-weight: 700;
                }
            }

            .p-dropdown {
                .p-dropdown-label,
                label {
                    padding: 0.5rem;
                    font-size: $font-size-h5;
                }

                &:not(.p-disabled) {
                    &:hover {
                        border-color: $Siemens_Petrol;
                    }

                    &.p-focus {
                        border-color: $Siemens_Petrol;
                        box-shadow: 0 0 0 0.1rem $Siemens_Petrol;
                    }
                }
            }
            .p-calendar,
            .p-dropdown,
            .p-inputnumber {
                &.p-inputwrapper-focus + label,
                &.p-inputwrapper-filled + label {
                    color: $Siemens_Petrol;
                    top: 0;
                    background: $white;
                    padding-left: 2px;
                }

                &.p-inputwrapper-filled + label {
                    color: $Siemens_Petrol;
                }
            }
            .p-calendar,
            .p-dropdown,
            .p-inputnumber {
                &.p-inputwrapper-focus + label,
                &.p-inputwrapper-filled + label {
                    color: $Siemens_Petrol;
                    top: 0;
                    background: $white;
                    padding-left: 2px;
                }

                &.p-inputwrapper-filled + label {
                    color: $Siemens_Petrol;
                }
            }
            .p-inputtext {
                &.error {
                    border-color: $Red__Light;
                    box-shadow: none;
                }

                &:enabled {
                    &:hover {
                        border-color: $Siemens_Petrol;
                    }

                    &:focus {
                        border-color: $Siemens_Petrol;
                        box-shadow: 0 0 0 0.1rem $Siemens_Petrol;
                    }
                }
            }
            .p-calendar,
            .p-inputtext {
                padding: 0.5rem;
                font-size: $font-size-h5;
                width: 100%;

                &:focus + label,
                &.p-filled + label {
                    color: $Siemens_Petrol;
                    top: 0;
                    background: $white;
                    padding-left: 2px;
                }
            }

            label {
                font-size: $font-size-h5;
                font-weight: 400;
                color: $Siemens_Petrol;

                &.mandatory {
                    &::after {
                        content: " *";
                        color: #fe0808;
                    }
                }
            }

            .p-dropdown {
                &.error {
                    border-color: $Red__Light;
                    box-shadow: none;
                }
            }

            .p-autocomplete {
                width: 100%;
                .p-autocomplete-input {
                    width: 100%;
                }
                &.p-inputwrapper-focus + label,
                &.p-inputwrapper-filled + label {
                    color: $Siemens_Petrol;
                    top: 0 !important;
                    background: $white;
                    padding-left: 2px;
                }
            }
        }
        .left-sider-label {
            width: 150px;

            &.upload-left {
                width: 170px;
            }
        }
        .right-side-field {
            width: calc(100% - 0px);
            .filepond--wrapper {
                .filepond--hopper {
                    margin-bottom: 0px;
                }
            }
        }
    }
}


.notification-message-main-wrapper {
    .right-side-field {
        width: calc(100% - 150px) !important;
    }

    .message-wrapper {
        position: relative;
        height: 200px;

        .jodit-wysiwyg table tr td {
            border: 1px solid black ;
        }

        ul,
        ol {
            box-sizing: border-box;
            padding: 0px;
            margin: 0px;
            list-style: outside disc;
            -webkit-user-drag: none;
            overflow: visible;
            font-family: "Helvetica Neue";
            font-size: 13px;
            color: rgb(51, 51, 51);
            display: block;
            margin-block: 1em;
            margin-inline: 0px;
            padding-inline-start: 40px;
            font-style: normal;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-weight: 400;
            letter-spacing: normal;
            orphans: 2;
            text-align: start;
            text-indent: 0px;
            text-transform: none;
            widows: 2;
            word-spacing: 0px;
            -webkit-text-stroke-width: 0px;
            white-space: normal;
            text-decoration-thickness: initial;
            text-decoration-style: initial;
            text-decoration-color: initial;

        }
    }

}


@import "Help/Help.scss";