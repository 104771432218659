.summary-wrapper {
    height: 100%;

    .reports-generated {
        height: 100%;
        padding-left: 1rem;

        .title {
            h5 {
                height: 40px;
                line-height: 40px;
            }
        }

        .summary-chart {
            height: 100%;

            .recharts-responsive-container {
                .recharts-tooltip-wrapper {
                    .custom-tooltip {                                               
                        background-color: $white;   
                        padding: 10px;   
                        box-shadow: 0 3px 3px rgba(0,0,0,0.2);
                        margin-bottom: 0px;
                        font-size: 12px;
                        .tooltip-title {
                            font-weight: 600;
                            color: $Grey__Dark;
                            margin: 0px;
                            margin-bottom: 10px;
                            padding-top: -5px;
                        }
                    }
                }
                svg {
                    height: unset;
                    width: unset;
                }
            }

        }
    }
}