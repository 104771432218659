.salesactivity-reports {

    padding: 0px 24px 16px 24px;
    height: calc(100% - 81px);

    .breadcrumb-sub-page-title {

        span {
            color: $Siemens_Petrol;
            font-size: 0.9375rem;
            font-weight: 600;

        }
    }

    .main-title {

        background: #fff;
        padding: 8px;
        font-size: 16px;
        font-weight: 600;
        color: $Siemens_Petrol;

    }

    .submit-btn {
        background: $Siemens_Petrol;
        border-color: $Siemens_Petrol;
        color: $white;
        padding: 0 35px;
        font-size: $font-size-h4;
        font-weight: 500;
        align-items: center;
    }

    .submit-btn:hover {
        background: $Siemens_Petrol;
        border-color: $Siemens_Petrol;
        color: $white;
        padding: 0 35px;
    }

    .submit-btn:focus {
        box-shadow: none;
    }


}

.report-sort-field {
    background: #fff;
    padding-bottom: 30px;
    padding-top: 30px;


    .report-export-main {
        display: flex;
        justify-content: space-between;

        .btn-wrapper {

            display: flex;
            justify-content: space-between;
            flex-direction: row;

            .box-btn {

                box-shadow: none;
                height: 15px;
                font-weight: 600;
                font-size: 12.5px;
                border: none;
                background: none;
                color: $Siemens_Petrol ;
                justify-content: space-between;
                padding-top: 30px;
                padding-bottom: 30px;


                svg {

                    height: 16px;
                    width: 16px;
                    margin-left: 8px;

                }
            }

            .apply-button {
                display: flex;
                align-items: center;

                .submit-btn {
                    background: $Siemens_Petrol;
                    border-color: $Siemens_Petrol;
                    color: $white;
                    padding: 35 35px;
                    font-size: $font-size-h4;
                    font-weight: 500;
                    height: 32px;
                }
            }


        }

        .report-field {
            width: 300px;
        }

        .report-buttons-main {
            justify-content: flex-end;
            align-items: center;
            padding-right: 8px;

            .report-btn {

                cursor: pointer;
                background: $Siemens_Petrol;
                color: $white;
                box-shadow: 0px 4px 6px rgb(0 0 0 / 20%);
                border-radius: 8px;
                font-size: $font-size-h5;
                padding: 4px 16px;
                font-weight: 500;
                border-color: transparent;

                &:hover {
                    background: $Siemens_Petrol;
                    color: white;
                }
            }

            .export-report-buttons {

                justify-content: flex-end;

                .expor-title {

                    font-weight: 600;
                    align-items: center;
                    display: flex;
                    font-size: 0.8125rem;
                    margin-left: 16px;

                }

                .export-btn {

                    cursor: pointer;
                    background: $Siemens_Petrol;
                    color: $white;
                    box-shadow: 0px 4px 6px rgb(0 0 0 / 20%);
                    border-radius: 8px;
                    font-size: $font-size-h5;
                    padding: 4px 16px;
                    font-weight: 500;
                    border-color: transparent;
                    margin-left: 8px;

                    &:hover {

                        background: $Siemens_Petrol;
                        color: white;

                    }

                    .icon {

                        height: 18px;
                        width: 18px;
                        margin-right: 4px;

                        path {

                            stroke: white;

                        }

                    }



                }

            }
        }
    }
}

.ar-report-viewer {

    height: calc(100% - 108px);
}

.sites-report-view {

    .ar-report-viewer {

        height: 100%;
    }

}

.SalesActivity {
    width: 35vw;
    height: 35vw;
    top: 36px;
    left: 20px;

    .p-dialog-content {
        padding-left: 0;
        padding-right: 0;
        overflow-y: unset;
        background: transparent;
        height: 100%;

        .custom-header {
            background: $Siemens_Petrol;
            border-radius: 8px 8px 0px 0px;
            padding: 0px 24px;

            .box {
                @include display(flex);
                @include flex-direction(column);
                @include flex-justifyContents(center);
            }

            .title {
                color: $white;
                font-size: $font-size-h5;
            }

            .sub-title {
                color: $white;
                font-size: $font-size-h5;
                font-weight: 600;
            }

            .pi.pi-times {
                color: $white;
                cursor: pointer;
                font-weight: 700;
            }
        }

        .custom-table {

            height: calc(100% - 10.5px);
            background: #fff;

            .p-datatable {

                height: 100%;

                .p-datatable-wrapper {
                    height: 100%;

                    .p-datatable-header {
                        padding: 0.2rem;
                    }

                    tr {
                        th {
                            padding: 0.5rem 1rem !important;
                        }

                        td {
                            padding: 0.5rem 1rem;

                            input {
                                width: 30px;
                                height: 19px;
                                padding: 0;
                                text-align: center;
                                font-size: 12px;
                                border-radius: 4px;
                            }
                        }
                    }
                }
            }

        }

    }
}