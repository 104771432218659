    .cancel-btn-no {
        border-color: $Siemens_Petrol;
        background: $white;
        color: $Grey__Dark;
        padding: 0 40px;
        height: 35px;
        margin: 0 1rem 0 0;

        &:hover {
            background-color: #ffffff !important;
            color: $Grey__Dark !important;
            border-color: $Siemens_Petrol !important;
        }
    }

    .save-btn-mapped-reports {
        background: $Siemens_Petrol;
        border-color: $Siemens_Petrol;
        color: $white;
        padding: 0 40px;
        height: 35px;
        margin: 0 1rem 0 0;

        &:hover {
            background: $Siemens_Petrol !important;;
            border-color: $Siemens_Petrol !important;;
            color: #FFFFFF;
        }
    }

    .stickyTableHeader {
        .p-datatable-thead {
            position: sticky;
            top: 0;
            z-index: 1;
        }
    }