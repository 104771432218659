.TAPPerformance {
    @include spacing();
    padding: 0;
    margin: 0px 24px;
    height: calc(100% - 120px);
    position: relative;

    .TAPPerformance-container {
        height: 100%;

        .expanded.TAPPerformance-table {
            height: 100%;
        }
    }

    @import "../../components/Table/ModalWizard/ModalWizard.scss";

}

.p-component-overlay {

    .p-dialog-content {
        .header {
            .title {
                color: $Grey__Dark;
                font-size: $font-size-h4;
                font-weight: 600;
            }

            .value {
                padding-left: 5px;
                color: $Siemens_Petrol;
                font-size: $font-size-h4;
                font-weight: 600;
            }
        }

        .field {
            .textarea-txt-count {
                font-size: 11px;
                float: right;
                position: relative;
                top: -6px;
            }

            .p-float-label {

                textarea#Textarea {
                    height: 152px;
                }

            }
        }
    }

}

.icon-adjust-section {  
    padding: 0px 0px;
    margin-right: 30px;
    margin-left: 20px;

    .icon {
        margin-right: 12px;
        cursor: pointer;

        &:last-child {
            margin-right: 0;
        }

        &.large {
            color: $Siemens_Petrol;
                cursor: pointer;
                margin-right: 8px;
                @include border-radius(50%);
                box-shadow: 1px 5px 5px rgb(0 0 0 / 25%);
                font-size: $font-size-h3;
        }

        &.active {
            color: $Siemens_Petrol;
        }
    }
}

.generate-btn{

        margin-right: 30px;
        margin-left: 20px;
        background: $Siemens_Petrol;
        border-color: $Siemens_Petrol;
        color: white !important;
        height:16px;
        font-size: $font-size-h5;

        &:hover {
            
            background: $Siemens_Petrol !important;
            border-color:$Siemens_Petrol !important;
            color: white !important;
        }

        &:focus {

            box-shadow: none;
        }

        span{

            font-weight: 600;
        }
        

    }

