.organization-zone {
    margin: 0 24px;
   

    .breadcrumb-sub-page-title{
        color: $Siemens_Petrol;
        font-size: $font-size-h4;
            font-weight: 600;
    }

    .table-container {
        height: calc(100% - 120px);


        .organization-filter {
            flex: 1;
            max-width: 2%;
            margin-right: 7px;

            &.expanded {
                max-width: 18%;
            }

            .filters.expanded {
                height: 100%;
            }
        }

        .organization-table {
            flex: 1;
            max-width: 98%;

            &.expanded {
                max-width: 82%;

                .table-header {
                    .title {
                        color: $Siemens_Petrol;
                        font-size: $font-size-h4;
                    }
                }
            }
            .p-datatable{
                height: calc(100% - 20px);
                .p-datatable-wrapper {
                    height: calc(100vh - 230px);
                }
            }

            
        }
    }
}