.s-ag-grid{

    width: 100%;
    height:calc(100% - 56px);

    .ag-main{

        height: calc(100% - 53px) !important;

        .ag-root-wrapper{

            border-color: #dee2e6;
            height: 100%;
    
            .ag-root-wrapper-body{
    
                .ag-root{
    
                    .ag-header{
        
                        background: #d9eeee;
                        border-color: #dee2e6;
        
                        .ag-header-row{
        
                            color: #0f1923;
                            font-weight: 600;
                            font-size: .8125rem;
        
                            .ag-header-cell{
        
                                .ag-header-cell-resize{
        
                                    &::after{
        
                                        background-color: #0099991c;
        
                                    }
        
                                }
        
                                .ag-header-cell-comp-wrapper{
        
                                    .ag-cell-label-container{
        
                                        padding: 0;
        
                                        .ag-sort-indicator-container{
        
                                            .ag-icon{
        
                                                color: #099;
                                                font-size: 12px;
                        
                                            }
        
                                        }
        
                                    }
        
                                }
        
                            }
        
                        }
        
                    }
        
                    .ag-body{
    
                        .ag-body-clipper{
    
                            .ag-body-viewport{
                                
                                .ag-center-cols-clipper{
    
                                    .ag-center-cols-container{
    
                                        .ag-row{
    
                                            background: #fff;
                                            color: #0f1923;
                                            font-size: 11px;
    
                                            &.ag-row-hover {
    
                                                &::before {
    
                                                    background-image: none;
                                                    background-color: white;
    
                                                }
                                            }
    
                                            &.ag-row-selected{
    
                                                &::before{
    
                                                    background-color: #eef2ff;
    
                                                }
                                                
                                                .ag-cell{
                                                    color: #099;
    
                                                    &.ag-cell-focus {
    
                                                        border-color: transparent !important;
    
                                                    }
            
                                                }
    
    
                                            }
    
                                        }
    
                                    }
    
                                }
    
                            }
    
                        }
    
                    }
    
                }
                
            }
    
        }

    }

    .p-paginator{

        justify-content: end;
        padding: 0 1rem;

        .p-paginator-prev, .p-paginator-next{

            color: #099;
            font-size: .8125rem;
            height: 2rem;

        }

        .p-paginator-current{

            font-size: .8125rem;

        }

        .p-paginator-pages{

            .p-paginator-page{

                min-width: 2rem;
                height: 2rem;
                font-size: 13px;

                &.p-highlight{

                    color: #099;
    
                }

            }

        }

    }

}