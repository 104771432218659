.p-component-overlay {

    .p-component {

        &.import-equipment {

            width: 420px;

            .field {

                .upload-left {
                    width: 170px;

                    .p-float-label .label {
                        font-size: 0.8125rem;
                        font-weight: 400;
                        color: #009999;
                    }
                    
                }
                .right-side-field {
                    width: calc(100% - 0px);

                    .filepond--root{
                        margin-bottom: 0;
                    }
                }
            }

            .custom-table{

                .p-datatable-wrapper{

                    height: calc(100% - 51px);
                    padding: 0 8px;

                    .p-datatable-tbody{
    
                        tr{
    
                            td{
    
                                padding: 8px 0;
    
                            }
    
                        }
    
                    }

                }

            }

        }

    }

}