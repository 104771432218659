

.sites-container {
    @include spacing();
    margin: 0 12px 0 24px;
    padding: 0;
    background: transparent;
    height: calc(100% - 120px);



        .administration-site-leftsidebar {

            max-width: 18%;
            flex: 1 1;
            margin-right: 7px;

            .administration-site-listname {

                background: $white;
                padding:12px;
                width: 100%;
                color: $Grey__Dark;
                border-radius: 8px;

                .main {

                    ul.list-items {
                        padding: 0 0.5rem;

                        li{

                            font-size: $font-size-h5;
                             margin-top: 0.5rem;
                            cursor: pointer;

                            &.active,
                            &:hover {
                                background: $Siemens_Border__Light;
                                color: $Siemens_Petrol;
                                    }

                        }
                    }
                }
            }
        }
        .site-table{
            width: 81%;
            background-color: $white;
            border-radius: 8px;

            .custom-header {

                background: $Siemens_Petrol;
                border-radius: 8px 8px 0px 0px;
                padding: 0px 16px;
                display: flex;
                align-items: center;
          
                .title {
                  color: $white;
                  font-size: $font-size-h5;
                  display: flex;
                  align-items: center;

                    svg {

                        height: 20px;
                        width: 20px;
                        margin: 0 8px;

                    }

                }
            }

        }

        .site-building-table{
           @extend.site-table;

           .site-building-siteDetails{

            .list-group{

                .list-group-item{
                    color: $Siemens_Petrol;
                    font-size:$font-size-h6;
                    padding:0px 5px 10px 5px;

                    .iconsDetail{
                        background-color: $Siemens_Border__Light;
                        padding: 3px 5px 3px 5px;
                        border-radius: 5px;

                        span{
                            margin-left: 2px;
                        }
                    }
                }
            }
           }
        }
        .site-floor-table{
        @extend.site-table;

        .site-Floor-siteDetails{

            .list-group{

                .list-group-item{
                    color: $Siemens_Petrol;
                    font-size:$font-size-h6;
                    padding:0px 5px 10px 5px;
                    
                    .iconsDetail{
                        background-color: $Siemens_Border__Light;
                        padding: 3px 5px 3px 5px;
                        border-radius: 5px;

                        span{
                            margin-left: 2px;
                        }
                    }
                }
            }
        }
        .site-Floor-buildingDetails{
            .list-group{

                .list-group-item{
                    color: $Siemens_Petrol;
                    font-size:$font-size-h6;
                    flex: 0 0 136px;
                    padding:0px 5px 10px 5px;

                    .iconsDetail{
                        background-color: $Siemens_Border__Light;
                        padding: 3px 5px 3px 5px;
                        border-radius: 5px;

                        span{
                            margin-left: 2px;
                        }
                    }
                }
            }
        }
        }
}


    .p-component-overlay{

        .p-dialog-content{

            
                .field{
                    
                    .p-float-label{

                        textarea#Textarea {
                            height: 152px;
                        }
                    }
                }    
        }
        
    }


