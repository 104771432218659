.contracts {
    @include spacing();
    padding: 0;
    margin: 0px 24px;
    height: calc(100% - 120px);
   
    .contract-container{
        height:100%;

        .expanded.contract-table{
            height:100%;
        }
    }
}

.p-component-overlay{

    .p-dialog-content{
        .header {
            .title {
                color: $Grey__Dark;
                font-size: $font-size-h4;
                font-weight: 600;
            }

            .value {
                padding-left: 5px;
                color: $Siemens_Petrol;
                font-size: $font-size-h4;
                font-weight: 600;
            }
        }

        
            .field{
                .textarea-txt-count{
                    font-size: 11px;
                    float: right;
                    position: relative;
                    top: -6px;
                }
                .p-float-label{

                    textarea#Textarea {
                        height: 152px;
                    }
                    
                }
            }    
    }
    
}


