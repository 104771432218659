@mixin border-radius($radius) {
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    -ms-border-radius: $radius;
    border-radius: $radius;
}

@mixin border-radi($topleft, $topright, $bottomright, $bottomleft) {
    border-top-left-radius: $topleft;
    border-top-right-radius: $topright;
    border-bottom-right-radius: $bottomright;
    border-bottom-left-radius: $bottomleft;
    -webkit-border-top-left-radius: $topleft;
    -webkit-border-top-right-radius: $topright;
    -webkit-border-bottom-right-radius: $bottomright;
    -webkit-border-bottom-left-radius: $bottomleft;
    -moz-border-radius-topleft: $topleft;
    -moz-border-radius-topright: $topright;
    -moz-border-radius-bottomright: $bottomright;
    -moz-border-radius-bottomleft: $bottomleft;
}

@mixin box-shadow($shadowprop...) {
    -webkit-box-shadow: $shadowprop;
    -moz-box-shadow: $shadowprop;
    -ms-box-shadow: $shadowprop;
    box-shadow: $shadowprop;
}

@mixin transition($transprop...) {
    -webkit-transition: $transprop;
    -moz-transition: $transprop;
    -ms-transition: $transprop;
    -o-transition: $transprop;
    transition: $transprop;
}

@mixin transform-rotate($degree) {
    -webkit-transform: rotate($degree + deg);
    -moz-transform: rotate($degree + deg);
    -ms-transform: rotate($degree + deg);
    -o-transform: rotate($degree + deg);
    transform: rotate($degree + deg);
}

@mixin transform-scale($val) {
    -webkit-transform: scale($val);
    -moz-transform: scale($val);
    -ms-transform: scale($val);
    -o-transform: scale($val);
    transform: scale($val);
}

@mixin transform-rotateY($degree) {
    -webkit-transform: rotateY($degree + deg);
    -moz-transform: rotateY($degree + deg);
    -ms-transform: rotateY($degree + deg);
    -o-transform: rotateY($degree + deg);
    transform: rotateY($degree + deg);
}

@mixin transform-translate($valx, $valy) {
    -webkit-transform: translate($valx, $valy);
    -moz-transform: translate($valx, $valy);
    -ms-transform: translate($valx, $valy);
    -o-transform: translate($valx, $valy);
    transform: translate($valx, $valy);
}

@mixin transform-translateX-rotate($valx, $degree) {
    -webkit-transform: translateX($valx) rotate($degree + deg);
    -moz-transform: translateX($valx) rotate($degree + deg);
    -ms-transform: translateX($valx) rotate($degree + deg);
    -o-transform: translateX($valx) rotate($degree + deg);
    transform: translateX($valx) rotate($degree + deg);
}

@mixin transform-rotateY-scale($degree, $val) {
    -webkit-transform: rotateY($degree + deg) scale($val);
    -moz-transform: rotateY($degree + deg) scale($val);
    -ms-transform: rotateY($degree + deg) scale($val);
    -o-transform: rotateY($degree + deg) scale($val);
    transform: rotateY($degree + deg) scale($val);
}

@mixin transform-skew($degreeX, $degreeY: 0) {
    -webkit-transform: skew($degreeX + deg, $degreeY + deg);
    -moz-transform: skew($degreeX + deg, $degreeY + deg);
    -ms-transform: skew($degreeX + deg, $degreeY + deg);
    -o-transform: skew($degreeX + deg, $degreeY + deg);
    transform: skew($degreeX + deg, $degreeY + deg);
}

@mixin transform-skew-rotate($degreeSkewX, $degreeRotate) {
    -webkit-transform: skew($degreeSkewX + deg) rotate($degreeRotate + deg);
    -moz-transform: skew($degreeSkewX + deg) rotate($degreeRotate + deg);
    -ms-transform: skew($degreeSkewX + deg) rotate($degreeRotate + deg);
    -o-transform: skew($degreeSkewX + deg) rotate($degreeRotate + deg);
    transform: skew($degreeSkewX + deg) rotate($degreeRotate + deg);
}

@mixin transform-rotate-skew($degreeRotate, $degreeSkewX) {
    -webkit-transform: rotate($degreeRotate + deg) skew($degreeSkewX + deg);
    -moz-transform: rotate($degreeRotate + deg) skew($degreeSkewX + deg);
    -ms-transform: rotate($degreeRotate + deg) skew($degreeSkewX + deg);
    -o-transform: rotate($degreeRotate + deg) skew($degreeSkewX + deg);
    transform: rotate($degreeRotate + deg) skew($degreeSkewX + deg);
}

@mixin transform-origin($val...) {
    -webkit-transform-origin: $val;
    -moz-transform-origin: $val;
    -ms-transform-origin: $val;
    -o-transform-origin: $val;
    transform-origin: $val;
}

@mixin perspective($persp) {
    -webkit-perspective: $persp + px;
    -moz-perspective: $persp + px;
    perspective: $persp + px;
}

@mixin gradient($direction, $list) {
    background-image: -webkit-linear-gradient($direction $list), $image-url;
    background-image: -moz-linear-gradient($direction, $list), $image-url;
    background-image: -o-linear-gradient($direction, $list), $image-url;
    background-image: linear-gradient($direction, $list), $image-url;
}

@mixin columns($count, $gap) {
    -webkit-column-count: $count;
    -moz-column-count: $count;
    column-count: $count;

    -webkit-column-gap: $gap;
    -moz-column-gap: $gap;
    column-gap: $gap;
}

@mixin display($val) {
    -webkit-display: $val;
    -moz-display: $val;
    -ms-display: $val;
    display: $val;
}

@mixin flex-direction($val) {
    -webkit-flex-direction: $val;
    -moz-flex-direction: $val;
    -ms-flex-direction: $val;
    flex-direction: $val;
}

@mixin flex-wrap($val) {
    -webkit-flex-wrap: $val;
    -moz-flex-wrap: $val;
    -ms-flex-wrap: $val;
    flex-wrap: $val;
}

@mixin flex($val...) {
    -webkit-flex: $val;
    -moz-flex: $val;
    -ms-flex: $val;
    flex: $val;
}

@mixin flexflow($val...) {
    -webkit-flex-flow: $val;
    -moz-flex-flow: $val;
    -ms-flex-flow: $val;
    flex-flow: $val;
}

@mixin flex-alignItems($val) {
    -webkit-align-items: $val;
    -moz-align-items: $val;
    -ms-align-items: $val;
    align-items: $val;
}

@mixin flex-justifyContents($val) {
    -webkit-justify-content: $val;
    -moz-justify-content: $val;
    -ms-justify-content: $val;
    justify-content: $val;
}

@mixin userSelect($val) {
    -webkit-user-select: $val;
    -moz-user-select: $val;
    -ms-user-select: $val;
    user-select: $val;
}

@mixin truncate($lines-to-show) {
    display: inline-block; // Fallback for non-webkit
    display: -webkit-box;
    -webkit-line-clamp: $lines-to-show;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

// Page Content Spacing
@mixin spacing() {
    background: $white;
    padding: 7px 10px 17px 15px;
    margin: 7px 10px;
}

/// Slightly lighten a color
@function light-shade($color, $percentage) {
    @return mix(white, $color, $percentage);
}

/// Slightly darken a color
@function dark-shade($color, $percentage) {
    @return mix(black, $color, $percentage);
}


// ========= Media Query Break Points ======= 

// Small devices 576px
@mixin sm {
    @media (max-width: #{$screen-sm}) {
        @content;
    }
}

// Medium devices 768px
@mixin md {
    @media (max-width: #{$screen-md}) {
        @content;
    }
}

// Large devices 992px
@mixin lg {
    @media (max-width: #{$screen-lg}) {
        @content;
    }
}

// Extra large devices 1200px
@mixin xl {
    @media (max-width: #{$screen-xl}) {
        @content;
    }
}

// Custom devices
@mixin cmw($screen) {
    @media (max-width: #{$screen + 'px'}) {
        @content;
    }
}

@mixin cmh($screen) {
    @media (max-height: #{$screen+'px'}) {
        @content;
    }
}