.sidebar-container {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: $Grey__Dark;
    max-width: 65px;
    @include display(flex);
    @include flex-direction(column);
    @include flex-alignItems(center);

    &.expanded {
        max-width: 10rem;
        z-index: 9999;

        .ham-icon {
            position: relative;
            left: -3rem;
        }

        .sidebar-links {
            ul {
                li {
                    justify-content: flex-start;
                    padding-left: 20px;
                }
            }
        }
    }

    .ham-icon {
        margin: 30px 0 40px 0;
        cursor: pointer;
        color: $white;

        img {
            width: 30px;
            height: 22px;
        }
    }

    .sidebar-links {
        width: 100%;

        ul {
            li {
                margin-bottom: 25px;
                cursor: pointer;
                color: $white;
                height: 3rem;
                @include display(flex);
                @include flex-alignItems(center);
                @include flex-justifyContents(center);

                img {
                    width: 30px;
                    height: 22px;
                }

                span {
                    font-weight: 400;
                    font-size: $font-size-h5;
                    line-height: 14px;
                    color: $white;
                    padding-left: 14px;
                }

                &:last-child {
                    margin-bottom: 0;
                }

                &.active {
                    background-color: $Siemens_Petrol;
                }
            }
        }
    }
}