.site-reports {

    padding: 0px 24px 16px 24px;
    height: calc(100% - 81px);

    // &.site-report-dropdown {
    //     height: calc(100% - 195px);
    // }

    .breadcrumb-sub-page-title {

        span {
            color: $Siemens_Petrol;
            font-size: 0.9375rem;
            font-weight: 600;

        }
    }

    .main-title {

        background: #fff;
        padding: 8px;
        font-size: 16px;
        font-weight: 600;
        color: $Siemens_Petrol;

    }

    .drop-down {
        background: #fff;
        padding-bottom: 0.5px;
    }

    // .calender-input {
    //     background: #fff;
    //     padding-bottom: 15px;
    //     padding-top: 10px;
    // }

    .submit-btn {
        background: $Siemens_Petrol;
        border-color: $Siemens_Petrol;
        color: $white;
        padding: 0 35px;
        font-size: $font-size-h4;
        font-weight: 500;
    }

    .submit-btn:hover {
        background: $Siemens_Petrol;
        border-color: $Siemens_Petrol;
        color: $white;
        padding: 0 35px;
    }

    .submit-btn:focus {
        box-shadow: none;
    }

    .field {
        .p-dropdown {
            .p-dropdown-label {
                padding: 0.5rem;
                font-size: $font-size-h5;
            }

            &:not(.p-disabled) {
                &:hover {
                    border-color: $Siemens_Petrol;
                }

                &.p-focus {
                    border-color: $Siemens_Petrol;
                    box-shadow: 0 0 0 0.1rem $Siemens_Petrol;
                }
            }
        }

        .p-dropdown {

            &.p-inputwrapper-focus+label,
            &.p-inputwrapper-filled+label {
                color: $Siemens_Petrol;
                top: 0;
                background: $white;
                padding-left: 2px;
            }

            &.p-inputwrapper-filled+label {
                color: $Siemens_Petrol;
            }
        }

        .p-dropdown {
            &.error {
                border-color: $Red__Light;
                box-shadow: none;
            }
        }

        .p-float-label {
            font-size: $font-size-h5;

            .p-inputtext {
                &.error {
                    border-color: $Red__Light;
                    box-shadow: none;
                }

                &:enabled {
                    &:hover {
                        border-color: $Siemens_Petrol;
                    }

                    &:focus {
                        border-color: $Siemens_Petrol;
                        box-shadow: 0 0 0 0.1rem $Siemens_Petrol;
                    }
                }
            }
        }
    }
}

.report-sort-field {
    background: #fff;
    padding-bottom: 8px;
    padding-top: 8px;

    .report-export-main {
        justify-content: space-between;

        .report-field {
            width: 300px;
        }

        .report-buttons-main {
            justify-content: flex-end;
            align-items: center;
            padding-right: 8px;

            .report-btn {

                cursor: pointer;
                background: $Siemens_Petrol;
                color: $white;
                box-shadow: 0px 4px 6px rgb(0 0 0 / 20%);
                border-radius: 8px;
                font-size: $font-size-h5;
                padding: 4px 16px;
                font-weight: 500;
                border-color: transparent;

                &:hover {
                    background: $Siemens_Petrol;
                    color: white;
                }
            }

            .export-report-buttons {

                justify-content: flex-end;

                .expor-title{

                    font-weight: 600;
                    align-items: center;
                    display: flex;
                    font-size: 0.8125rem;
                    margin-left: 16px;

                }
                
                .export-btn{
                    
                    cursor: pointer;
                    background: $Siemens_Petrol;
                    color: $white;
                    box-shadow: 0px 4px 6px rgb(0 0 0 / 20%);
                    border-radius: 8px;
                    font-size: $font-size-h5;
                    padding: 4px 16px;
                    font-weight: 500;
                    border-color: transparent;
                    margin-left: 8px;

                    &:hover {

                        background: $Siemens_Petrol;
                        color: white;

                    }

                    .icon {

                        height: 18px;
                        width: 18px;
                        margin-right: 4px;

                            path{

                                stroke: white;

                            }

                    }
                    
                    

                }

            }
        }
    }
}

.ar-report-viewer{

    height: calc(100% - 108px);
}

.sites-report-view{

    .ar-report-viewer{

        height: 100%;
    }

}