.menu-bar-container {
    @include border-radius(4px);
    @include display(flex);
    @include flex-justifyContents(space-between);
    @include flex-alignItems(center);
    padding: 0px 10px 2px 15px;
    margin: 4px 10px;

    &.bg-white {
            background: $white;
            padding: 5px 10px 7px 15px;
        }
    
        .build-version {
            color: $Grey__Light;
            text-align: center;
            position: relative;
            display: inline-block;
        }
    
        .build-version  .tooltiptext {
            visibility: hidden;
            width: 85px;
            background-color: rgba(0, 0, 0, 0.555);
            color: #fff;
            text-align: center;
            border-radius: 4px;
            padding: 3px 2;
            
            /* Position the tooltip */
            position: absolute;
            z-index: 1;
            top: -5px;
            right: 105%;
        }
    
        .build-version:hover .tooltiptext {
            visibility: visible;
        }
        pi pi-info-circle
        {
            color: rgba(9, 65, 4, 0.719);
            height: .5px;
            width: 50%;
        }
    
        .nav-links {
            ul {
            @include display(flex);

            li {
                font-size: $font-size-h5;
                line-height: 19px;
                color: $Grey__Dark;
                margin-right: 32px;
                cursor: pointer;

                &.active {
                    color: $Siemens_Petrol;

                    &::after {
                        content: "";
                        width: 80%;
                        display: flex;
                        margin: 0 auto;
                        border-bottom: 2px solid $Siemens_Petrol;
                    }
                }

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }

    .config-section {
        ul {
            @include display(flex);

            li {
                font-weight: 400;
                font-size: $font-size-h5;
                margin-right: 32px;
                @include display(flex);
                @include flex-alignItems(center);
                cursor: pointer;

                svg,
                img {
                    width: 20px;
                    height: 20px;
                    margin-right: 4px;
                    color: $Siemens_Petrol;
                }

                &:last-child {
                    margin-right: 0;
                }

                &.active {
                    background: $Siemens_Border__Light;
                    padding: 2px 10px;
                    margin-right: 22px;
                    @include border-radius(4px);
                }
            }

            &.extra-menus {
                position: absolute;
                right: 15px;
                padding: 12px;
                background: white;
                flex-direction: column;
                top: 5rem;
                z-index: 999;

                li {
                    margin-right: 0;
                    margin-bottom: 20px;
                    font-size: $font-size-h5;
                    @include display(flex);
                    @include flex-alignItems(center);

                    &:last-child {
                        margin-bottom: 0;
                    }

                    img {
                        width: 15px;
                        height: 15px;
                        margin-right: 10px;
                    }
                }
            }
        }
    }
}

.plugin {
    .p-timeline-event {
        .p-timeline-event-separator {
            .p-timeline-event-connector {
                display:block;
            }
        }
    }
}
.pre-loader{

    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: sticky;
    z-index: 9999;
    background-color: var(--maskbg);
     
    h6{

        color: #fff;
        margin-top: 120px;
        position: absolute;
        text-shadow: 1px 1px #666363c2;

    }
    
    @keyframes p-progress-spinner-color {
        100%,
        0% {
            stroke:$Siemens_Petrol;
        }
        40% {
            stroke:$Siemens_Petrol;
        }
        66% {
            stroke:$Siemens_Petrol;
        }
        80%,
        90% {
            stroke:$Siemens_Petrol;
        }
    }

}
.release-note {
    padding: 0px 15px 10px 15px;
    
    .title{
        color: $Siemens_Petrol;
        font-size: $font-size-h4;
        font-weight: 600;
    }
    .link-table {
        height: calc(100% - 25px);

        .table-head{
            background-color: $Grey_Theme_Border;
        }

        .link-list {
            height: calc(100% - 65px);

            
            .p-scrollpanel {
                .p-scrollpanel-wrapper {
                    .p-scrollpanel-content {
                        padding: 0;

                        .row-list {
                            .list-group {
                                padding: 5px 2px;
                                border-radius: 0;
                                border-bottom: 1px solid #dee2e6;
                                &:hover {
                                    background-color: $Grey_Theme_Border;
                                }
                                .list-group-item {
                                    font-size: $font-size-h5;
                                    color: #495057;
                                }
                                .des {
                                    width: 50%;
                                    cursor: pointer;
                                    padding: 0 0.5rem;
                                    color: #0078d4;
    
                                    &:hover{
                                        background-color: transparent;
                                    }
                                }
                                .add-on {
                                    width: 25%;
                                    padding: 0 0.5rem;
                                }
                                .add-by {
                                    width: 25%;
                                    padding: 0 0.5rem;
                                }
                                &.active{
                                    background-color: $Grey_Theme_Border;
                                }
                            }
                            
                        }
                    }
                }
            }
        }
        .icons-link-table {
            width: fit-content;
            background: $Siemens_Border__Light;
            border-radius: 4px;
            padding: 0px 7px 3px 15px;
            margin-top: 10px;

            span {
                .icon {
                    width: 16px;
                    height: 16px;
                    color: $Siemens_Petrol;
                    cursor: pointer;
                }
            }
        }
        .table-head {
            .list-group {
                .th {
                    color: $Grey__Dark;
                    font-weight: 600;
                    font-size: $font-size-h5;
                    padding: 0.2rem 0.5rem;
                }
                .des {
                    width: 50%;
                }
                .add-on {
                    width: 25%;
                }
                .add-by {
                    width: 25%;
                }
            }
        }
    }
    .close-icon {
        .pi {
            color: $Siemens_Petrol;
            cursor: pointer;
        }
    }

    .p-timeline-event {
        .p-timeline-event-opposite {
            display: none;
        }
        .p-timeline-event-separator{
            .p-timeline-event-marker{
                border: 2px solid $Siemens_Petrol;
                background-color:$Siemens_Petrol ;
            }
        }
        .p-timeline-event-content {
            .p-card {
                box-shadow: none;
                .p-card-body {
                    padding-top: 0px;
                    .p-card-title {
                        font-size: $font-size-h3;
                        font-weight: 400;
                        margin-bottom: 0;
                        color: $Siemens_Petrol;
                    }
                    .p-card-content {
                        padding: 5px 0px;

                        ul {
                            margin-left: 15px;

                            li {
                                position: relative;
                                color: $body-font-color;
                                font-size: $font-size-h5;
                                padding: 0px 0px 0px 10px;

                                &:before {
                                    content: "";
                                    width: 5px;
                                    height: 5px;
                                    top: 9px;
                                    left: 0px;
                                    position: absolute;
                                    background-color: $body-font-color;
                                    border-radius: 50%;
                                    z-index: 999;
                                }
                            }
                        }

                        .icon-plugin {
                            span {
                                .pi {
                                    font-weight: 700;
                                    cursor: pointer;
                                }
                            }
                        }
                        p {
                            color: $body-font-color;
                            font-size: $font-size-h5;
                            margin-bottom: 5px;
                            font-weight: 500;
                        }
                        .list-group {
                            color: $body-font-color;
                            font-size: $font-size-h5;
                        }
                    }
                }
            }
        }
    }
}

.qr-code {
    margin-bottom: 1px;
    color: $Siemens_Petrol;
    border-radius: 25px;
    

    .p-input-icon-right {
        position: absolute;
        right: 1rem;
        width: 25px;
        height: 24px;
        z-index: 99;
        margin-right: 5px;
        color: $Siemens_Petrol;
        cursor: pointer;
        background: $Siemens_Border__Light;
        border-radius: 25px;
        padding: 12px;
        transition: color 2s;


        .switch-left-icon {
            position: absolute;
            right: .6px;
            width: 1.25rem;
            height: 1.25rem;
            margin-top: -12px;
            z-index: 999;
        }

        .switch-right-icon {
            right: 38px;
            width: 1.25rem;
            height: 1.25rem;
            color: white;
            margin-top: -10px;
            z-index: 999;

            &:hover {
                background: $Siemens_Border__Light;
            }
        }

        .p-inputswitch {
            position: absolute;
            top: 50%;
            margin-top: -0.75rem;
            right: 0.5rem;
            height: 24px;


            .p-inputswitch-slider {
                background: $Siemens_Petrol;

                &:hover {
                    background: $Siemens_Border__Light;
                    border-radius: 50;
                }
            }
        }

        .pi.pi-search {
            color: $Siemens_Petrol;
            font-weight: bold;
            cursor: pointer;
        }

        .p-inputtext {
            width: 100%;
            height: 31px;
            background: $Siemens_Petrol;
            border: 1px solid $Siemens_Petrol;
            font-size: $font-size-h5;
            padding: 0.5rem;
        }
    }
}

.card {
    background: $Siemens_Petrol;
    background: var(--surface-card);
    padding: 2rem;
    border-radius: 100px;
    margin-bottom: 1rem;

    &:hover {
        background: $Siemens_Border__Light;
    }
}
