.health-classification{
    @include spacing();
    padding: 0px 10px 0px 15px;
    margin: 0px 10px;


    .breadcrumb-sub-page-title{
        color: $Siemens_Petrol;
        font-size: $font-size-h4;
            font-weight: 600;
    }
    
        .health-table{

            .p-datatable{

                .p-datatable-wrapper{
                    height: calc(100vh - 216px);
                }
            }
    }
}