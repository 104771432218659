@import "mixins";

// Import Bootstrap and its default variables
@import "../../../node_modules/bootstrap/scss/bootstrap.scss";

/* ==========================================================================
   Reset
   ========================================================================== */

::-moz-selection {
	background: $selection-bg;
	text-shadow: none;
	color: $selection-text-color;
}

::selection {
	background: #014343;
	text-shadow: none;
	color: rgb(243, 247, 249);
}

html,
body {
	width: 100%;
	height: 100%;
	position: relative;
	overflow-x: hidden;
	overflow-y: auto;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
li {
	margin: 0;
	padding: 0;
}

body {
	font-family: $body-font;
	color: $body-font-color;
	background: $body-bg;
}

ul {
	list-style: none;
}

a {
	color: $link-color;
	@include transition(all 0.3s ease-in-out);

	&:link,
	&:visited {
		color: $link-color;
		text-decoration: none;
	}

	&:hover {
		color: dark-shade($Siemens_Petrol, 70%);
		text-decoration: none;
	}
}

*:focus,
a:focus,
a:active {
	outline: none;
	box-shadow: none;
	text-decoration: none;
}

figure {
	line-height: 0;
}

p {
	margin: 0 0 9px;

	&:first-child {
		margin-top: 0;
	}

	&:last-child {
		margin-bottom: 0;
	}
}

img {
	display: inline-block;
	max-width: 100%;
}

svg {
	height: 24px;
	width: 24px;
}

address {
	margin-bottom: 0;
}

::-webkit-input-placeholder {
	color: $body-font-color;
}

:-moz-placeholder {
	/* Firefox 18- */
	color: $body-font-color;
}

::-moz-placeholder {
	/* Firefox 19+ */
	color: $body-font-color;
}

:-ms-input-placeholder {
	color: $body-font-color;
}

//Bootsrap Reset

//Button

// button {
//     font-family: inherit;
// }

// .btn-primary:not(:disabled):not(.disabled):active:focus,
// .show>.btn-primary.dropdown-toggle:focus,
// .btn-primary:focus,
// .btn-primary.focus {
//     box-shadow: unset;
// }

// .btn-primary:focus,
// .btn-primary.focus {
//     color: unset;
//     background-color: unset;
//     border-color: unset;
//     box-shadow: unset;
// }

//Dropdown

.custom-float-field {
	margin-bottom: 5px;

	.p-float-label {
		&.text-container {
			label {
				position: relative;
				left: 0;
				min-width: 65px;
			}

			.text-value {
				font-size: $font-size-h5;
				color: $Siemens_Petrol;
				margin-left: 30px;
			}
		}

		.p-inputtext {
			padding: 0.5rem;
			font-size: $font-size-h5;

			&:focus+label,
			&.p-filled+label {
				color: $Siemens_Petrol;
				top: 0;
				background: $white;
				padding-left: 2px;
			}
		}

		label {
			font-size: $font-size-h5;
			font-weight: 400;

			.pop-subhead {
				b {
					font-size: $font-size-base;
				}
			}

			&.mandatory {
				&::after {
					content: " *";
					color: #fe0808;
				}
			}
		}

		.p-dropdown {
			&.custom-icon {
				.p-dropdown-trigger {
					position: relative;
					left: -25px;
				}

				~.setting-icon {
					position: absolute;
					right: 4px;
					top: 4px;
					width: 30px;
					height: 30px;
					background: $Siemens_Border__Light;
					@include border-radius(4px);
					@include display(flex);
					@include flex-alignItems(center);
					@include flex-justifyContents(center);
					font-size: $font-size-h2;
					color: $Siemens_Petrol;
					cursor: pointer;
				}
			}

			.p-dropdown-label {
				padding: 0.5rem;
				font-size: $font-size-h5;
			}

			&:not(.p-disabled) {
				&:hover {
					border-color: $Siemens_Petrol;
				}

				&.p-focus {
					border-color: $Siemens_Petrol;
					box-shadow: 0 0 0 0.1rem $Siemens_Petrol;
				}
			}
		}

		.p-calendar,
		.p-dropdown,
		.p-inputnumber {

			&.p-inputwrapper-focus+label,
			&.p-inputwrapper-filled+label {
				color: $Siemens_Petrol;
				top: 0;
				background: $white;
				padding-left: 2px;
			}

			&.p-inputwrapper-filled+label {
				color: $Siemens_Petrol;
			}
		}

		.p-dropdown {
			&.error {
				border-color: $Red__Light;
				box-shadow: none;
			}
		}

		.p-calendar,
		.p-inputnumber {
			&.error {
				.p-inputtext {
					border-color: $Red__Light;
					box-shadow: none;
				}
			}
		}

		.p-inputtext {
			&.error {
				border-color: $Red__Light;
				box-shadow: none;
			}

			&:enabled {
				&:hover {
					border-color: $Siemens_Petrol;
				}

				&:focus {
					border-color: $Siemens_Petrol;
					box-shadow: 0 0 0 0.1rem $Siemens_Petrol;
				}
			}
		}
	}
}

.dropdown-item.active,
.dropdown-item:active {
	color: unset;
	text-decoration: unset;
	background-color: unset;
}

.dropdown-toggle::after {
	display: none;
}

.dropdown-item:hover,
.dropdown-item:focus {
	color: unset;
	text-decoration: unset;
	background-color: unset;
}

// Prime react table dropdown

.p-dropdown-panel {
	z-index: 99999999 !important;

	.p-dropdown-items .p-dropdown-item {
		font-size: $font-size-h5;
		padding: 0.5rem;

		&.p-highlight {
			color: $Siemens_Petrol;
		}
	}
}

// Prime react auto complete

.p-autocomplete-panel {
	ul.p-autocomplete-items {
		padding: 0;

		li.p-autocomplete-item {
			font-size: $font-size-h5;
			padding: 0.5rem 0.5rem 0 0.5rem;

			&:last-child {
				padding: 0.5rem;
			}
		}
	}
}

// Prime react multi select
.p-multiselect-panel {
	.p-multiselect-header {
		display: none;
	}

	.p-multiselect-items-wrapper {
		ul.p-multiselect-items {
			padding: 0;

			li.p-multiselect-item-group {
				display: none;
			}

			li.p-multiselect-item {
				&:focus {
					box-shadow: none;
				}

				&.p-highlight {
					color: $Siemens_Petrol;
				}

				.p-checkbox {
					.p-checkbox-box {
						&.p-highlight {
							border-color: $Siemens_Petrol;
							background: $Siemens_Petrol;
						}
					}

					&:not(.p-checkbox-disabled) {
						.p-checkbox-box {
							&.p-focus {
								border-color: $Siemens_Petrol;
								box-shadow: 0 0 0 0.1rem $Siemens_Petrol;
							}

							&:hover {
								border-color: $Siemens_Petrol;
							}
						}
					}
				}

				&:not(.p-highlight) {
					&:not(.p-disabled) {
						&:hover {
							color: $white;
							background: $Siemens_Petrol;
						}
					}
				}
			}
		}
	}
}

// Prime react calendar
.p-datepicker {

	width: 280px !important;
	min-width: unset !important;

	.p-datepicker-group-container {
		.p-datepicker-group {
			.p-datepicker-header {
				padding: 0;

				.p-datepicker-prev:focus,
				.p-datepicker-next:focus {
					box-shadow: 0 0 0 0.1rem $Siemens_Petrol;
				}

				.p-datepicker-title {

					.p-datepicker-month,
					.p-datepicker-year {
						font-size: $font-size-h5;
						padding: 0;

						&:hover {
							color: $Siemens_Petrol;
						}

						&:focus {
							box-shadow: 0 0 0 0.1rem $Siemens_Petrol;
						}
					}
				}
			}

			.p-datepicker-calendar-container {
				.p-datepicker-calendar {
					margin: 0;

					thead {
						tr {
							th {
								text-align: center;
								padding: 0;

								span {
									color: $Siemens_Petrol;
									font-weight: 600;
									font-size: $font-size-h5;
								}
							}
						}
					}

					tbody {
						tr {
							td {
								padding: 0;

								span {
									font-size: $font-size-h5;

									&.p-highlight {
										color: $Siemens_Petrol;
									}
								}
							}
						}
					}
				}
			}
		}
	}

	.p-timepicker {

		.p-hour-picker,
		.p-minute-picker,
		.p-second-picker {

			.p-link {

				height: 18px;
				width: 18px;

				span {

					font-size: $font-size-h5;
					font-weight: 500;

				}

			}

			span {

				line-height: 16px;
				font-size: $font-size-h5;

			}

		}

		.p-separator {

			span {

				font-size: $font-size-h5;

			}

		}

	}
	
	.p-datepicker-buttonbar {

		padding: 8px 0 0 0;
		
		.clearbutton {
            pointer-events: none;
            color:#727272;  
        }

		.p-button-text {

			font-size: $font-size-h5;
			padding: 0.50rem 1.25rem;

			&:hover {

				background-color: $Siemens_Border__Light !important;

			}

			&:focus {

				box-shadow: unset;

			}

			.p-button-label {

				font-weight: 600;

			}

		}

	}

	.p-yearpicker {
		.p-yearpicker-year.p-highlight {
			color: $Siemens_Petrol;
		}
	}

	.p-monthpicker {
		.p-monthpicker-month.p-highlight {
			color: $Siemens_Petrol;
		}
	}
}

// Prime react overlay
.p-component-overlay {
	.p-dialog {

		// Pattern overlay
		&.pattern-overlay {
			.p-dialog-header {
				padding: 0 0.7rem;
				color: $Siemens_Petrol;

				.p-dialog-title {
					font-size: $font-size-h5;
					font-weight: 600;
				}

				.p-dialog-header-icons {
					button.p-dialog-header-close {
						color: $Siemens_Petrol;

						&:focus {
							box-shadow: none;
						}
					}
				}
			}

			.p-dialog-content {
				padding: 0 0.7rem 0.5rem 0.7rem;

				.p-datatable {
					.p-datatable-wrapper {
						.p-datatable-table {
							.p-datatable-thead {
								tr {
									th {
										border: 0;
										background: white;
										padding: 0 0 0.2rem 0;
										font-size: $font-size-h5;
									}
								}
							}

							.p-datatable-tbody {
								tr {
									td {
										border: 0;
										background: white;
										padding: 0 0 0.2rem 0;
										font-size: $font-size-h5;
									}
								}
							}
						}
					}
				}
			}
		}

		// Add/ Edit overlay
		&.add-overlay {
			overflow-y: hidden;

			&:hover {
				overflow-y: visible;
			}

			.addEdit-overlay-spinner {

				height: calc(100% - 28px);
				width: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
				position: absolute;
				z-index: 9999;
				background-color: var(--maskbg);
				margin-left: -8px;
				border-radius: 0 0 6px 6px;

				@keyframes p-progress-spinner-color {

					100%,
					0% {
						stroke: $Siemens_Petrol;
					}

					40% {
						stroke: $Siemens_Petrol;
					}

					66% {
						stroke: $Siemens_Petrol;
					}

					80%,
					90% {
						stroke: $Siemens_Petrol;
					}
				}

			}

			.modal-progressbar {

				.p-progressbar {

					height: 5px;

					border-radius: 0;

					.p-progressbar-value {

						background-color: $Siemens_Petrol;
					}
				}

			}

			.p-dialog-header {
				padding: 0.5rem 1rem 0 1rem;

				.p-dialog-title {
					font-size: $font-size-h5;
					color: $Siemens_Petrol;
					font-weight: 500;
				}

				.p-dialog-header-icons {
					.p-dialog-header-icon {
						color: $Siemens_Petrol;
						width: 20px;
						height: 20px;

						&:focus {
							box-shadow: none;
						}
					}
				}
			}

			.p-dialog-content {
				padding: 0 0.5rem;
				overflow-x: hidden;

				.contractplan-enddate {
					.text-value {
						position: relative;
						left: 5px;
					}
				}

				.field {
					margin-bottom: 5px;

					.pop-subhead {
						b {
							font-size: $font-size-h5;
						}
					}

					.checkbox-list {
						padding: 0.5rem 0.5rem 0.7rem 0.5rem;
						font-size: 0.8125rem;
						border: 1px solid #ced4da;
						transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
						appearance: none;
						border-radius: 6px;

						.checkbox-list-scrollbar {
							.p-scrollpanel-wrapper {
								border-right: 6px solid var(--surface-b);
							}

							.p-scrollpanel-bar {
								background: $Siemens_Petrol;
								border: 0 none;
								opacity: 0.5;
								width: 5px;

								&:hover {
									background-color: $Siemens_Petrol;
									opacity: 1;
								}
							}
						}

						.p-float-label {
							margin-top: -8px;

							.checkbox-list-label {
								position: relative;
								top: -10px;
								left: 5px;
								background-color: $white;
							}
						}
					}

					.p-float-label {
						&.text-container {
							label {
								position: relative;
								left: 0;
								min-width: 70px;
							}

							.text-value {
								font-size: $font-size-h5;
								color: $Siemens_Petrol;
								margin-left: 30px;
							}
						}

						.p-inputtext {
							padding: 0.5rem;
							font-size: $font-size-h5;

							&:focus+label,
							&.p-filled+label {
								color: $Siemens_Petrol;
								top: 0;
								background: $white;
								padding-left: 2px;
							}
						}

						label {
							font-size: $font-size-h5;
							font-weight: 400;

							&.mandatory {
								&::after {
									content: " *";
									color: #fe0808;
								}
							}
						}

						.p-dropdown {
							.p-dropdown-label {
								padding: 0.5rem;
								font-size: $font-size-h5;
							}

							&:not(.p-disabled) {
								&:hover {
									border-color: $Siemens_Petrol;
								}

								&.p-focus {
									border-color: $Siemens_Petrol;
									box-shadow: 0 0 0 0.1rem $Siemens_Petrol;
								}
							}
						}

						.p-calendar,
						.p-dropdown,
						.p-inputnumber {

							&.p-inputwrapper-focus+label,
							&.p-inputwrapper-filled+label {
								color: $Siemens_Petrol;
								top: 0;
								background: $white;
								padding-left: 2px;
							}

							&.p-inputwrapper-filled+label {
								color: $Siemens_Petrol;
							}
						}

						.p-dropdown {
							&.error {
								border-color: $Red__Light;
								box-shadow: none;
							}
						}

						.p-calendar,
						.p-inputnumber {
							&.error {
								.p-inputtext {
									border-color: $Red__Light;
									box-shadow: none;
								}
							}
						}

						.p-inputtext {
							&.error {
								border-color: $Red__Light;
								box-shadow: none;
							}

							&:enabled {
								&:hover {
									border-color: $Siemens_Petrol;
								}

								&:focus {
									border-color: $Siemens_Petrol;
									box-shadow: 0 0 0 0.1rem $Siemens_Petrol;
								}
							}
						}

						&.action-btns {
							@include display(flex);
							@include flex-alignItems(center);
							@include flex-justifyContents(space-between);
							background: $Siemens_Border__Light;
							color: $Siemens_Petrol;
							@include border-radius(4px);
							cursor: pointer;
							height: 32px;
							width: 35px;
							padding: 0 10px;

							&.add-icons {
								padding: 0 10px;
								width: 65px;
							}

							&.edit-icons {
								padding: 0 7px;
								width: 30px;
							}

							.button-icon {
								background: inherit;
								color: inherit;
								border: none;
								padding: 0;
								box-shadow: none;

								.icon {
									width: 16px;
									height: 16px;
								}
							}
						}

						.upload-file-section {
							.upload-detail {
								.filepond--wrapper {
									.filepond--root {
										.filepond--drop-label {
											label {
												top: 30%;
											}
										}
									}
								}
							}
						}
					}
				}

				.field-system-disable {
					.p-float-label {
						font-size: 0.5rem;
						padding: 0.5rem 0.5rem 0.5rem 0.6rem;
						appearance: none;
						border-radius: 6px;
						border: 1px solid #ced4da;

						.text-value {
							position: relative;
							left: -5px;
						}
					}
				}

				.pattern-view {
					cursor: pointer;
					font-size: $font-size-h5;
					font-weight: 600;
					color: $Siemens_Petrol;
				}
			}

			.p-dialog-footer {
				text-align: left;
				padding: 0.5rem 1rem;

				button {
					height: 35px;

					.p-button-label {
						font-size: $font-size-h4;
						font-weight: 500;
					}

					&:focus {
						box-shadow: 0 0 0 0.1rem $Siemens_Petrol;
					}

					&.cancel-btn {
						border-color: $Siemens_Petrol;
						background: $white;
						color: $Grey__Dark;
						margin: 0 20px 0 0;
					}

					&.save-btn {
						background: $Siemens_Petrol;
						border-color: $Siemens_Petrol;
						color: $white;
					}
				}
			}
		}

		// Delete overlay
		&.delete-modal {
			.p-dialog-header {
				padding: 1rem;

				.p-dialog-title {
					font-size: $font-size-h3;
					font-weight: 600;
				}

				.p-dialog-header-icons {
					button.p-dialog-header-close {
						&:focus {
							box-shadow: none;
						}
					}
				}
			}

			.p-dialog-content {
				padding: 0 1rem 1rem 1rem;

				.content {
					font-size: $font-size-h4;
				}
			}

			.p-dialog-footer {
				padding: 1rem;

				button {
					padding: 0 40px;
					height: 35px;
					margin: 0 1rem 0 0;

					&:focus {
						box-shadow: none;
					}

					&:last-child {
						margin: 0;
					}

					&.cancel-btn {
						border-color: $Siemens_Petrol;
						background: $white;
						color: $Grey__Dark;
					}

					&.submit-btn {
						background: $Siemens_Petrol;
						border-color: $Siemens_Petrol;
						color: $white;
					}

					.p-button-label {
						font-size: $font-size-h4;
						font-weight: 500;
					}
				}
			}
		}

		&.custom-modal {
			.p-dialog-header {
				padding: 0 0.7rem;
				color: $Siemens_Petrol;

				.p-dialog-title {
					font-size: $font-size-h5;
					font-weight: 600;
				}

				.p-dialog-header-icons {
					button.p-dialog-header-close {
						color: $Siemens_Petrol;

						&:focus {
							box-shadow: none;
						}
					}
				}
			}

			.p-dialog-content {
				padding: 0 0.7rem 0.5rem 0.7rem;

				.header-tab {
					font-size: $font-size-h4;
					color: $Grey__Dark;

					&.active {
						color: $Siemens_Petrol;
					}
				}

				.required {
					font-size: $font-size-h5;
					color: $Grey__Dark;

					span.asterisk {
						color: $Red__Light;
					}
				}

				.submit-cta {
					text-align: left;
					padding: 0.5rem 1rem;

					button {
						height: 35px;

						.p-button-label {
							font-size: $font-size-h4;
							font-weight: 500;
						}

						&:focus {
							box-shadow: 0 0 0 0.1rem $Siemens_Petrol;
						}

						&.cancel-btn {
							border-color: $Siemens_Petrol;
							background: $white;
							color: $Grey__Dark;
							margin: 0 20px 0 0;
						}

						&.save-btn {
							background: $Siemens_Petrol;
							border-color: $Siemens_Petrol;
							color: $white;
						}
					}
				}
			}
		}
	}
}

// Error Tooltip
.error-tooltip {
	position: relative;

	&:hover {
		~.tooltip-text {
			visibility: visible;
		}
	}

	~.tooltip-text {
		visibility: visible;
		background-color: $Red__Light;
		font-size: $font-size-h5;
		color: $white;
		text-align: center;
		border-radius: 6px;
		padding: 5px 10px;
		position: absolute;
		z-index: 1;
		top: 115%;
		left: 50%;
		margin-left: -60px;
		@include display(flex);
		@include flex-alignItems(center);
		@include flex-alignItems(space-between);

		&::after {
			content: "";
			position: absolute;
			bottom: 100%;
			left: 50%;
			margin-left: -5px;
			border-width: 5px;
			border-style: solid;
			border-color: transparent transparent $Red__Light transparent;
		}

		svg {
			width: 15px;
			height: 15px;
			margin-right: 5px;
		}
	}

	&.cell-error {
		~.tooltip-text {
			margin-left: -5rem;
		}
	}
}

// Tooltip
.p-tooltip {
	.p-tooltip-text {
		padding: 0.2rem 1rem;
	}
}

// overlay panel
.p-overlaypanel {
	margin-top: 10px;

	&::before,
	&::after {
		display: none;
	}

	.p-overlaypanel-content {
		padding: 5px;

		.maildomain-icon-list {
			width: 100px;

			li {
				span {
					.icon {
						color: $Siemens_Petrol;
						width: 15px;
						margin-right: 10px;
					}
				}
			}
		}

		.sign-out {
			background: $Siemens_Petrol;
			border-color: $Siemens_Petrol;
			color: #ffffff;
			height: 35px;
			width: 200px;

			&:focus {
				box-shadow: none;
				outline: 0;
			}

			.p-button-label {
				font-size: 0.9375rem;
				font-weight: 500;
			}
		}

		ul {
			li {
				font-size: $font-size-h5;
				font-weight: 400;
				cursor: pointer;
				padding: 5px 7px;

				&:hover {
					background: $Siemens_Border__Light;
					color: $Siemens_Petrol;
				}
			}
		}
	}
}

// Check button
.p-button.check-btnLink {
	padding: 0;
	background: none;
	border: none;

	&:hover {
		background: none;
		border-color: transparent;
	}

	&:focus {
		box-shadow: none;
	}

	.p-button-label {
		font-size: $font-size-h5;
		font-weight: 500;
		color: $Siemens_Petrol;
		text-decoration: underline;
	}
}

// Custom checkbox
.field-checkbox {
	margin-bottom: 0;

	.p-checkbox {
		width: 18px;
		height: 18px;

		.p-checkbox-box {
			width: 18px;
			height: 18px;
			border-radius: 2px;
			border: 1px solid $Siemens_Stone__Light;

			&.p-highlight {
				border-color: $Siemens_Petrol;
				background: $Siemens_Petrol;

				&:hover {
					border-color: $Siemens_Petrol;
					background: $Siemens_Petrol;
				}
			}

			&.p-focus {
				border-color: $Siemens_Petrol;
				box-shadow: none;
			}

			&:hover {
				border-color: $Siemens_Petrol;
			}
		}
	}

	label {
		font-size: $font-size-h5;
		color: $Siemens_Petrol;
		font-weight: 500;
	}
}

// Custom Radio
.field-radiobutton {
	.p-radiobutton {
		.p-radiobutton-box {
			border: 2px solid $Siemens_Border__Light;

			&:not(.p-disabled):not(.p-highlight):hover {
				border-color: $Siemens_Border__Light;
			}

			&:hover {
				border-color: $Siemens_Border__Light;
				background: $Siemens_Petrol;
			}

			&:focus {
				border-color: $Siemens_Border__Light;
				box-shadow: none;
				outline: none;
				outline-offset: unset;
			}

			&.p-highlight {
				background: $Siemens_Petrol;

				&:not(.p-disabled):hover {
					border-color: $Siemens_Border__Light;
					background: $Siemens_Petrol;
				}

				&:focus-visible {
					outline: none;
				}
			}

			&.p-focus {
				box-shadow: none;
				outline: none;
				outline-offset: unset;
				border-color: $Siemens_Border__Light;
			}

			.p-radiobutton-icon {
				visibility: hidden;
			}
		}
	}

	label {
		font-size: $font-size-h5;
	}
}

//List Group

.list-group-item {
	position: unset;
	display: unset;
	padding: 0;
	background-color: unset;
	border: none;
}

.list-group-item.active {
	z-index: initial;
	color: initial;
	background-color: initial;
	border-color: initial;
}

/* model box style */
.s-modal {
	.p-dialog-header {
		padding: 0.5rem 1rem;

		.p-dialog-title {
			font-size: $font-size-h4;
			color: $Siemens_Petrol;
			font-weight: 600;
		}

		.p-dialog-header-icons {
			.p-dialog-header-close {
				color: $Siemens_Petrol;

				&:focus {
					box-shadow: none;
				}

				.taskscompletion-table {
					.p-datatable-header {
						display: none;
					}
				}

				.filter-field {
					.left-field {
						width: 350px;

						.field-left {
							.dropdown-field {
								width: 200px;
							}

							.value-field {
								width: calc(100% - 170px);
							}
						}
					}
				}
			}
		}
	}

	.p-dialog-content {
		padding: 0 1.5rem 0rem 1.5rem;

		.p-datatable {
			/** table global **/
			width: 100% !important;
			height: 100%;
			//margin-left: -8px;

			/** table body **/
			.p-datatable-wrapper {
				height: calc(100% - 72px);
				background-color: #fff;

				table.p-datatable-table {

					thead.p-datatable-thead {
						tr {
							th {
								background: #D9EEEE;
								color: $Grey__Dark;
								font-weight: 600;
								font-size: $font-size-h5;
								padding: 0.2rem 0.5rem;

								&:focus {
									box-shadow: inset 0 0 0 0.1rem $Siemens_Petrol;
								}
							}
						}
					}

					tbody {

						color: $Grey__Dark;
						font-size: $font-size-h5;

						tr {

							&:focus {
								outline: none;
							}

							td {

								padding: 6px 8px;

								.custom-float-field {

									margin-bottom: 0;

									.p-float-label {

										.p-component {

											height: 37.5px;

										}

									}

								}

							}
						}
					}
				}
			}

			/** table paginator **/
			.p-paginator {
				color: $Grey__Dark;
				padding: 0;
				@include flex-justifyContents(right);

				.p-paginator-prev,
				.p-paginator-next {
					color: $Siemens_Petrol;
					font-size: $font-size-h5;
					height: 2rem;

					.action-btn {
						padding: 0 1rem !important;
					}

					&:disabled {
						color: $Siemens_Stone__Dark;
					}
				}

				.p-paginator-last {
					color: $Siemens_Petrol;
					font-size: $font-size-h5;
					min-width: 2rem;
					height: 2rem;
				}

				.p-paginator-first {
					color: $Siemens_Petrol;
					font-size: $font-size-h5;
					min-width: 2rem;
					height: 2rem;
				}

				.p-paginator-pages {
					.p-paginator-page {
						color: $Siemens_Petrol;
						font-size: $font-size-h5;
						min-width: 2rem;
						height: 2rem;
					}
				}

				.p-dropdown {
					background: $Grey_Medium;
					height: auto;

					.p-dropdown-label {
						font-size: $font-size-h5;
						padding: 2px 0 2px 2px;
					}

					.p-dropdown-trigger {
						width: 2rem;

						.p-dropdown-trigger-icon {
							font-size: 12px;
						}
					}

					&:not(.p-disabled) {

						&:hover {
							border-color: $Siemens_Petrol;
						}

						&.p-focus {
							border-color: $Siemens_Petrol;
							box-shadow: 0 0 0 0.1rem $Siemens_Petrol;
						}
					}
				}

				.current-page {
					font-size: $font-size-h5;

					.p-inputtext {
						font-size: $font-size-h5;
						padding: 2px;
					}
				}
			}
		}

		.field {
			padding-right: 8px;
			padding-left: 4px;
			margin-bottom: 0px;

			.pop-subhead {
				b {
					font-size: $font-size-h5;
				}
			}

			.condition-builder {
				display: flex;
				padding: 8px;

				.fields {
					display: flex;
					width: 85%;
					border: 1.5px solid $Siemens_Petrol;
					padding: 10px 5px 10px 5px;

					.field {
						margin-right: 10px;
						margin-bottom: 0px;

						&.value {
							width: 200px;
						}

						&.field-name {
							width: 200px;
						}
					}
				}

				.icon-fields {
					display: flex;
					width: 15%;
					justify-content: flex-end;
					align-items: center;

					.field {
						margin-bottom: 0px;

						.action-btns {
							@include display(flex);
							@include flex-alignItems(center);
							@include flex-justifyContents(space-between);
							@include border-radius(4px);
							cursor: pointer;
							height: 32px;
							width: 35px;
							padding: 0 10px;
							background: $Siemens_Border__Light;
							color: $Siemens_Petrol;
							margin-right: 10px;

							.button-icon {
								background: inherit;
								color: inherit;
								border: none;
								padding: 0;
								box-shadow: none;

								.icon {
									width: 16px;
									height: 16px;
								}
							}
						}
					}
				}
			}

			.p-float-label {
				&.action-btns {
					@include display(flex);
					@include flex-alignItems(center);
					@include flex-justifyContents(space-between);
					background: $Siemens_Border__Light;
					color: $Siemens_Petrol;
					@include border-radius(4px);
					cursor: pointer;
					height: 37.5px;
					width: 35px;
					padding: 0 10px;

					&.add-icons {
						padding: 0 10px;
						width: 65px;
					}

					&.edit-icons {
						padding: 0 7px;
						width: 30px;
					}

					.button-icon {
						background: inherit;
						color: inherit;
						border: none;
						padding: 0;
						box-shadow: none;

						.icon {
							width: 16px;
							height: 16px;
						}
					}
				}

				.label {
					font-size: 0.8125rem;
					font-weight: 400;
					color: $Siemens_Petrol;

					&.mandatory {
						&::after {
							content: " *";
							color: #fe0808;
						}
					}
				}

				&.text-container {
					label {
						position: relative;
						left: 0;
					}

					.text-value {
						font-size: $font-size-h5;
						color: $Siemens_Petrol;
						font-weight: 700;
					}
				}

				.p-dropdown {

					.p-dropdown-label,
					label {
						padding: 0.5rem;
						font-size: $font-size-h5;
					}

					&:not(.p-disabled) {
						&:hover {
							border-color: $Siemens_Petrol;
						}

						&.p-focus {
							border-color: $Siemens_Petrol;
							box-shadow: 0 0 0 0.1rem $Siemens_Petrol;
						}
					}
				}

				.p-calendar,
				.p-dropdown,
				.p-inputnumber {

					&.p-inputwrapper-focus+label,
					&.p-inputwrapper-filled+label {
						color: $Siemens_Petrol;
						top: 0;
						background: $white;
						padding-left: 2px;
					}

					&.p-inputwrapper-filled+label {
						color: $Siemens_Petrol;
					}
				}

				.p-calendar,
				.p-dropdown,
				.p-inputnumber {

					&.p-inputwrapper-focus+label,
					&.p-inputwrapper-filled+label {
						color: $Siemens_Petrol;
						top: 0;
						background: $white;
						padding-left: 2px;
					}

					&.p-inputwrapper-filled+label {
						color: $Siemens_Petrol;
					}
				}

				.p-inputtext {
					&.error {
						border-color: $Red__Light;
						box-shadow: none;
					}

					&:enabled {
						&:hover {
							border-color: $Siemens_Petrol;
						}

						&:focus {
							border-color: $Siemens_Petrol;
							box-shadow: 0 0 0 0.1rem $Siemens_Petrol;
						}
					}
				}

				.p-calendar,
				.p-inputtext {
					padding: 0.5rem;
					font-size: $font-size-h5;
					width: 100%;

					&:focus+label,
					&.p-filled+label {
						color: $Siemens_Petrol;
						top: 0;
						background: $white;
						padding-left: 2px;
						padding-right: 2px;
					}
				}

				label {
					font-size: $font-size-h5;
					font-weight: 400;
					// color: $Siemens_Petrol;

					&.mandatory {
						&::after {
							content: " *";
							color: #fe0808;
						}
					}
				}

				.p-dropdown {
					&.error {
						border-color: $Red__Light;
						box-shadow: none;
					}
				}

				.p-autocomplete {
					width: 100%;

					.p-autocomplete-input {
						width: 100%;
					}

					&.p-inputwrapper-focus+label,
					&.p-inputwrapper-filled+label {
						color: $Siemens_Petrol;
						top: 0 !important;
						background: $white;
						padding-left: 2px;
					}
				}
			}

			.left-sider-label {
				width: 140px;
			}

			.right-side-field {
				width: calc(100% - 0px);

				.filepond--wrapper {
					.filepond--hopper {
						margin-bottom: 0px;
					}
				}
			}
		}
	}

	.p-dialog-footer {
		text-align: left;
		padding: 0.5rem 1rem 0.5rem 1rem;

		.btn {

			background: $Siemens_Petrol;
			border-color: $Siemens_Petrol;
			color: $white;

			&.cancel {
				border-color: $Siemens_Petrol;
				background: $white;
				color: $Grey__Dark;
				margin: 0 20px 0 0;
			}

			&.save {
				background: $Siemens_Petrol;
				border-color: $Siemens_Petrol;
				color: $white;
			}

			&:focus {
				outline: none;
				box-shadow: none;
				text-decoration: none;
			}
		}
	}
}

/* form element.tsx page modal style */
.form-element-modal {
	.p-dialog-header {
		padding: 0.5rem 1rem;

		.p-dialog-title {
			font-size: $font-size-h4;
			color: $Siemens_Petrol;
			font-weight: 600;
		}

		.p-dialog-header-icons {
			.p-dialog-header-close {
				color: $Siemens_Petrol;

				&:focus {
					box-shadow: none;
				}

				.taskscompletion-table {
					.p-datatable-header {
						display: none;
					}
				}

				.filter-field {
					.left-field {
						width: 350px;

						.field-left {
							.dropdown-field {
								width: 200px;
							}

							.value-field {
								width: calc(100% - 170px);
							}
						}
					}
				}
			}
		}
	}

	.p-dialog-content {
		padding: 0 0 0 1rem;

		.formElement-modal-spinner {

			height: calc(100% - 48px);
			width: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			position: absolute;
			z-index: 9999;
			background-color: var(--maskbg);
			margin-left: -16px;
			border-radius: 0 0 6px 6px;

			@keyframes p-progress-spinner-color {

				100%,
				0% {
					stroke: $Siemens_Petrol;
				}

				40% {
					stroke: $Siemens_Petrol;
				}

				66% {
					stroke: $Siemens_Petrol;
				}

				80%,
				90% {
					stroke: $Siemens_Petrol;
				}
			}

		}

		.p-datatable {
			/** table global **/
			width: 100% !important;
			height: 100%;
			margin-left: -8px;

			/** table body **/
			.p-datatable-wrapper {
				height: calc(100% - 72px);
				background-color: #fff;

				table.p-datatable-table {

					thead.p-datatable-thead {
						tr {
							th {
								background: #D9EEEE;
								color: $Grey__Dark;
								font-weight: 600;
								font-size: $font-size-h5;
								padding: 0.2rem 0.5rem;

								&:focus {
									box-shadow: inset 0 0 0 0.1rem $Siemens_Petrol;
								}
							}
						}
					}

					tbody {

						color: $Grey__Dark;
						font-size: $font-size-h5;

						tr {

							&:focus {
								outline: none;
							}

							td {

								padding: 8px;

								.custom-float-field {

									margin-bottom: 0;

									.p-float-label {

										.p-component {

											height: 37.5px;

										}

									}

								}

							}
						}
					}
				}
			}

			/** table paginator **/
			.p-paginator {
				color: $Grey__Dark;
				padding: 0;
				@include flex-justifyContents(right);

				.p-paginator-prev,
				.p-paginator-next {
					color: $Siemens_Petrol;
					font-size: $font-size-h5;
					height: 2rem;

					.action-btn {
						padding: 0 1rem !important;
					}

					&:disabled {
						color: $Siemens_Stone__Dark;
					}
				}

				.p-paginator-last {
					color: $Siemens_Petrol;
					font-size: $font-size-h5;
					min-width: 2rem;
					height: 2rem;
				}

				.p-paginator-first {
					color: $Siemens_Petrol;
					font-size: $font-size-h5;
					min-width: 2rem;
					height: 2rem;
				}

				.p-paginator-pages {
					.p-paginator-page {
						color: $Siemens_Petrol;
						font-size: $font-size-h5;
						min-width: 2rem;
						height: 2rem;
					}
				}

				.p-dropdown {
					background: $Grey_Medium;
					height: auto;

					.p-dropdown-label {
						font-size: $font-size-h5;
						padding: 2px 0 2px 2px;
					}

					.p-dropdown-trigger {
						width: 2rem;

						.p-dropdown-trigger-icon {
							font-size: 12px;
						}
					}

					&:not(.p-disabled) {

						&:hover {
							border-color: $Siemens_Petrol;
						}

						&.p-focus {
							border-color: $Siemens_Petrol;
							box-shadow: 0 0 0 0.1rem $Siemens_Petrol;
						}
					}
				}

				.current-page {
					font-size: $font-size-h5;

					.p-inputtext {
						font-size: $font-size-h5;
						padding: 2px;
					}
				}
			}
		}

		.field {
			padding-right: 8px;
			padding-left: 4px;
			margin-bottom: 0px;

			.pop-subhead {
				b {
					font-size: $font-size-h5;
				}
			}

			.condition-builder {
				display: flex;
				padding: 8px;

				.fields {
					display: flex;
					width: 85%;
					border: 1.5px solid $Siemens_Petrol;
					padding: 10px 5px 10px 5px;

					.field {
						margin-right: 10px;
						margin-bottom: 0px;

						&.value {
							width: 200px;
						}

						&.field-name {
							width: 200px;
						}
					}
				}

				.icon-fields {
					display: flex;
					width: 15%;
					justify-content: flex-end;
					align-items: center;

					.field {
						margin-bottom: 0px;

						.action-btns {
							@include display(flex);
							@include flex-alignItems(center);
							@include flex-justifyContents(space-between);
							@include border-radius(4px);
							cursor: pointer;
							height: 32px;
							width: 35px;
							padding: 0 10px;
							background: $Siemens_Border__Light;
							color: $Siemens_Petrol;
							margin-right: 10px;

							.button-icon {
								background: inherit;
								color: inherit;
								border: none;
								padding: 0;
								box-shadow: none;

								.icon {
									width: 16px;
									height: 16px;
								}
							}
						}
					}
				}
			}

			.p-float-label {
				&.action-btns {
					@include display(flex);
					@include flex-alignItems(center);
					@include flex-justifyContents(space-between);
					background: $Siemens_Border__Light;
					color: $Siemens_Petrol;
					@include border-radius(4px);
					cursor: pointer;
					height: 37.5px;
					width: 35px;
					padding: 0 10px;

					&.add-icons {
						padding: 0 10px;
						width: 65px;
					}

					&.edit-icons {
						padding: 0 7px;
						width: 30px;
					}

					.button-icon {
						background: inherit;
						color: inherit;
						border: none;
						padding: 0;
						box-shadow: none;

						.icon {
							width: 16px;
							height: 16px;
						}
					}
				}

				.label {
					font-size: 0.8125rem;
					font-weight: 400;
					color: $Siemens_Petrol;

					&.mandatory {
						&::after {
							content: " *";
							color: #fe0808;
						}
					}
				}

				&.text-container {
					label {
						position: relative;
						left: 0;
					}

					.text-value {
						font-size: $font-size-h5;
						color: $Siemens_Petrol;
						font-weight: 700;
					}
				}

				.p-dropdown {

					.p-dropdown-label,
					label {
						padding: 0.5rem;
						font-size: $font-size-h5;
					}

					&:not(.p-disabled) {
						&:hover {
							border-color: $Siemens_Petrol;
						}

						&.p-focus {
							border-color: $Siemens_Petrol;
							box-shadow: 0 0 0 0.1rem $Siemens_Petrol;
						}
					}
				}

				.p-calendar,
				.p-dropdown,
				.p-inputnumber {

					&.p-inputwrapper-focus+label,
					&.p-inputwrapper-filled+label {
						color: $Siemens_Petrol;
						top: 0;
						background: $white;
						padding-left: 2px;
					}

					&.p-inputwrapper-filled+label {
						color: $Siemens_Petrol;
					}
				}

				.p-calendar,
				.p-dropdown,
				.p-inputnumber {

					&.p-inputwrapper-focus+label,
					&.p-inputwrapper-filled+label {
						color: $Siemens_Petrol;
						top: 0;
						background: $white;
						padding-left: 2px;
					}

					&.p-inputwrapper-filled+label {
						color: $Siemens_Petrol;
					}
				}

				.p-inputtext {
					&.error {
						border-color: $Red__Light;
						box-shadow: none;
					}

					&:enabled {
						&:hover {
							border-color: $Siemens_Petrol;
						}

						&:focus {
							border-color: $Siemens_Petrol;
							box-shadow: 0 0 0 0.1rem $Siemens_Petrol;
						}
					}
				}

				.p-calendar {
					font-size: $font-size-h5;
					width: 100%;

					&:focus+label,
					&.p-filled+label {
						color: $Siemens_Petrol;
						top: 0;
						background: $white;
						padding-left: 2px;
						padding-right: 2px;
					}
				}

				.p-inputtext {
					font-size: $font-size-h5;
					width: 100%;
					padding: 8px;

					&:focus+label,
					&.p-filled+label {
						color: $Siemens_Petrol;
						top: 0;
						background: $white;
						padding-left: 2px;
						padding-right: 2px;
					}
				}

				label {
					font-size: $font-size-h5;
					font-weight: 400;

					&.mandatory {
						&::after {
							content: " *";
							color: #fe0808;
						}
					}
				}

				.p-dropdown {
					&.error {
						border-color: $Red__Light;
						box-shadow: none;
					}
				}

				.p-autocomplete {
					width: 100%;

					.p-autocomplete-input {
						width: 100%;
					}

					&.p-inputwrapper-focus+label,
					&.p-inputwrapper-filled+label {
						color: $Siemens_Petrol;
						top: 0 !important;
						background: $white;
						padding-left: 2px;
					}
				}
			}

			.left-sider-label {
				width: 140px;
			}

			.right-side-field {
				width: calc(100% - 0px);

				.filepond--wrapper {
					.filepond--hopper {
						margin-bottom: 0px;
					}
				}
			}
		}
	}

	.p-dialog-footer {
		text-align: left;
		padding: 0.5rem 1rem 0.5rem 1rem;

		.btn {

			background: $Siemens_Petrol;
			border-color: $Siemens_Petrol;
			color: $white;

			&.cancel {
				border-color: $Siemens_Petrol;
				background: $white;
				color: $Grey__Dark;
				margin: 0 20px 0 0;
			}

			&.save {
				background: $Siemens_Petrol;
				border-color: $Siemens_Petrol;
				color: $white;
			}

			&:focus {
				outline: none;
				box-shadow: none;
				text-decoration: none;
			}
		}
	}
}

/* icon commen style */
.table-config {
	@include display(flex);
	@include flex-alignItems(center);

	.action-btns {
		@include display(flex);
		@include flex-alignItems(center);
		@include flex-justifyContents(space-evenly);
		background: $Siemens_Border__Light;
		color: $Siemens_Petrol;
		@include border-radius(4px);
		padding: 3px 7px 3px 15px;
		cursor: pointer;

		.button-icon {
			background: inherit;
			color: inherit;
			border: none;
			padding: 0;
			box-shadow: none;
			margin-right: 20px;

			.icon {
				width: 16px;
				height: 16px;
			}

			span {
				height: 10px;
				width: 10px;
				margin-top: -21px;
			}

			.caret-icon {
				width: 15px;
				height: 12px;
				position: relative;
				left: 2px;
				color: $Blue__Light;
				padding: 0;

				&:focus {
					box-shadow: none;
				}
			}
		}
	}
}

//common Progress bar 2px full width line type
.common-progressbar {
	.p-progressbar {
		height: 5px;
		border-radius: 0;

		.p-progressbar-value {
			background-color: $Siemens_Petrol;
		}
	}
}

.drawing-tooltip {

	background-color: #505050;
	display: flex;
	align-items: center;
	--bs-popover-bg: #505050;
	position: sticky;

	.popover-arrow {

		position: absolute;
		display: none;

	}

	.tooltip-area {

		padding: 12px;

		h6 {

			font-size: 12px;
			color: #fff;
			line-height: 12px;
			font-weight: 500;
			padding-bottom: 4px;

			span {

				font-weight: 400;

			}

		}

	}
}


//button
.custom-btn {

	button {

		margin: 0 16px 0 0;
		height: 35px;

		&:focus {

			box-shadow: none;

		}

		&:last-child {

			margin: 0;

		}

		&.btn-white{

			background: #FFFFFF;
			box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
			border-radius: 8px;
			height: 35px;
			font-weight: 400;
			font-size: $font-size-h5;
			border: 0;
			color: $Siemens-Dark_Green;
			margin-bottom: 16px;
			max-width: 240px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
	
			&:hover{
	
				background-color: $Siemens_Petrol;
				color: #fff;
				
			}
			
		}

		&.cancel-btn {

			border-color: $Siemens_Petrol;
			background: $white;
			color: $Grey__Dark;

		}

		&.submit-btn {

			background: $Siemens_Petrol !important;
			border-color: $Siemens_Petrol !important;
			color: $white !important;
			padding: 0 35px;

		}

		.p-button-label {

			font-size: $font-size-h4;
			font-weight: 500;

		}

	}

}
.save-btn{ 
	@include border-radius(8px);
	background: $Siemens_Petrol !important;
	border-color: $Siemens_Petrol !important;
	color: $white !important;
	height: 10px;
	margin-left: 90px;
	cursor: pointer;
	padding: 4px 18px 3px 16px;
	
}
.confirm-btn {
background: $Siemens_Petrol !important;
border-color: $Siemens_Petrol !important;
color: $white !important;
height: 10px;
width: 80px;
margin-left: 90px;
}
.p-tooltip-custom {
	.p-tooltip-text {
		background-color: $Red__Light;
		color: $white;
		padding: 0.2rem 1rem;
		font-size: $font-size-h5;
	}
}