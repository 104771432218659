.wizard-modal {

    position: absolute;
    height: calc(100% + 62px);
    width: auto;
    background: $white;
    z-index: 1040 !important;
    top: -33px;
    right: 0px;
    border-radius: 8px;
    box-shadow: -3px 0 23px 4px #00000026;
    overflow: hidden;

    .wizard-header {

        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: none;
        border-top-left-radius: unset;
        border-top-right-radius: 6px;
        padding: 0;
        height: 48px;

        h5 {

            line-height: 16px;
            padding: 8px 0 8px 16px;
            color: $Siemens_Petrol;
            font-weight: 600;
            font-size: $font-size-h4;

        }

        .actions {

            padding-right: 8px;

            span {

                line-height: 32px;
                height: 32px;
                width: 32px;
                color: $body-font-color;
                margin: 0;
                padding: 0;
                opacity: 1;
                display: inline-block;
                float: none;
                vertical-align: bottom;
                cursor: pointer;
                text-align: center;
                background: light-shade($body-font-color, 100%);

                &:hover {

                    background: light-shade($body-font-color, 90%);

                }

                &:last-child {

                    //border-top-right-radius: 6px;

                }

                svg {

                    height: 16px;
                    width: 16px;

                }

                &.max {

                    display: none;

                }

            }

        }

    }

    .wizard-dialog {

        margin: 0;
        max-width: unset !important;
        pointer-events: all;
        height: calc(100% - 64px);

    }

    &.min {

        height: auto;
        top: auto;
        right: 8px;
        bottom: 8px;
        max-width: 360px;
        @include box-shadow(-4px 0px 12px 2px rgba(0, 0, 0, 0.15));

        .wizard-dialog {

            display: none;
            margin: 0px;

        }

        .wizard-header {

            max-width: 360px;

            .actions {

                span {

                    &.max {

                        display: inline-block;

                    }

                    &.min {

                        display: none;

                    }

                }

            }

        }

    }

    &.max {

        //height: calc(100% - 68px);
        //height: calc(100vh - 70px);
        // bottom: 1px;
        // right: 1px;

    }

    &.close {

        display: none;
        color: $Siemens_Petrol;

    }

    .wizard-form {

        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        height: 100%;
        padding: 8px;

        .step-name {

            width: 200px;
            height: 100%;
            position: relative;
            padding: 8px 0;
            border: 1px solid $Siemens_Border__Light;
            background: $body-bg;
            border-radius: 4px;

            .list-group {

                padding: 16px 12px;
                display: inline-block;
                vertical-align: middle;
                width: 100%;

                .list-group-item {

                    height: 32px;
                    color: #ccc;
                    text-transform: capitalize;
                    display: flex;
                    margin-bottom: 8px;
                    border-radius: 4px;

                    .icon {

                        width: 20px;
                        margin: 0 4px;
                        position: relative;

                        svg {

                            height: 20px;
                            width: 20px;
                            position: absolute;
                            top: 0;
                            bottom: 0;
                            margin: auto;
                            color: #ccc;
                        }

                    }

                    span {

                        height: 32px;
                        line-height: 32px;
                        font-size: $font-size-h5;
                        font-weight: 600;
                        padding-left: 4px;
                        color: light-shade($Siemens_Petrol, 50%);
                        padding-left: 8px;

                    }


                    &.active {

                        background: $Siemens_Border__Light;

                        span {

                            color: $Siemens_Petrol;

                        }

                        .icon {

                            svg {

                                color: $Siemens_Petrol;

                            }

                        }

                    }

                    &.completed {

                        .icon {

                            svg {

                                color: #ccc;

                            }

                        }

                        span {

                            color: #ccc;
                        }

                    }

                }

            }

            &.tab-control {

                .list-group {

                    .list-group-item {

                        &:hover {

                            cursor: pointer;
                            background: $Siemens_Border__Light;

                            span {

                                color: $Siemens_Petrol;

                            }

                            .icon {

                                svg {

                                    color: $Siemens_Petrol;

                                }

                            }

                        }

                    }

                }

            }

        }

        .step-wrapper {

            width: calc(100% - 200px);
            height: 100%;
            padding: 0 0 0 16px;
            position: relative;

            .wizard-control {

                width: 100%;
                margin: 0 auto;
                padding-top: 8px;
                text-align: right;
                position: absolute;
                top: 0;
                right: 0;

                .control-btn {
                    color: $Siemens_Petrol;
                    cursor: pointer;
                    margin-right: 8px;
                    background: $white;
                    @include border-radius(50%);
                    box-shadow: 0px 3px 6px rgb(0 0 0 / 25%);
                    padding: 4px;

                }

            }

            .steps {

                height: calc(100% - 51px);
                width: 100%;

                .step-item {

                    height: 100%;
                    width: 100%;

                    .title {

                        //color: $Siemens_Petrol;
                        line-height: 36px;
                        height: 36px;
                        font-weight: 600;

                    }

                    .content-area {

                        height: calc(100% - 36px);
                        margin-top: 16px;

                        //Scroll
                        .p-scrollpanel {

                            height: 100%;

                            .p-scrollpanel-content {

                                padding: 1px 16px 1px 2px;

                                .custom-float-field {

                                    .field {
                                        padding-bottom: 0;

                                        &.part-col {

                                            display: flex;

                                            .left {

                                                margin-right: 8px;
                                                width: 50%;

                                            }

                                            .right {

                                                width: 50%;

                                            }

                                        }

                                        .border-box {

                                            background: #ffffff;
                                            border: 1px solid #ced4da;
                                            transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
                                            border-radius: 6px;
                                            position: relative;
                                            padding: 12px 0px 12px 12px;
                                            height: 220px;
    
                                            .box-title {
    
                                                position: absolute;
                                                top: 0;
                                                background: #FFFFFF;
                                                padding-left: 2px;
                                                font-size: $font-size-h5;
                                                margin-top: -0.7rem;
                                                left: 0.75rem;
    
                                            }

                                            .item {

                                                //background: $Siemens_Border__Light;
                                                display: flex;
                                                align-items: center;
                                                justify-content: space-between;

                                                &:last-child {

                                                    margin-bottom: 0;

                                                }

                                                .field-checkbox {

                                                    margin-left: 8px;
                                                    overflow: hidden;
                                                    width: 50%;

                                                    label {
    
                                                        color: #101010;
                                                        font-size: 13px;
                                                        font-weight: 400;
                                                        line-height: 17px;
                                                        white-space: nowrap;
                                                        text-overflow: ellipsis;
                                                        overflow: hidden;
    
                                                    }
    
                                                    .no-branch-office {
    
                                                        color: $Siemens_Petrol;
    
                                                        cursor: pointer;
    
                                                        &:hover {
    
                                                            text-decoration: underline;
    
                                                        }
    
                                                    }
    
                                                    .p-checkbox-disabled {
                                                        cursor: not-allowed !important;
                                                    }
    
                                                }

                                                .field-select {

                                                    padding: 8px;
                                                    width: 50%;

                                                    .p-dropdown {

                                                        &.custom-icon {
                                                            .p-dropdown-trigger {
                                                                position: relative;
                                                                left: -25px;
                                                            }
                                            
                                                            ~.setting-icon {
                                                                position: absolute;
                                                                right: 4px;
                                                                top: 4px;
                                                                width: 30px;
                                                                height: 30px;
                                                                background: $Siemens_Border__Light;
                                                                @include border-radius(4px);
                                                                @include display(flex);
                                                                @include flex-alignItems(center);
                                                                @include flex-justifyContents(center);
                                                                font-size: $font-size-h2;
                                                                color: $Siemens_Petrol;
                                                                cursor: pointer;
                                                            }
                                                        }
                                            
                                                        .p-dropdown-label {
                                                            padding: 0.3rem;
                                                            font-size: $font-size-h5;
                                                        }
                                            
                                                        &:not(.p-disabled) {
                                                            &:hover {
                                                                border-color: $Siemens_Petrol;
                                                            }
                                            
                                                            &.p-focus {
                                                                border-color: $Siemens_Petrol;
                                                                box-shadow: 0 0 0 0.1rem $Siemens_Petrol;
                                                            }
                                                        }
                                                    }

                                                }

                                            }

                                            .branch-office-validation {
                                                color: $Red__Light;
                                                font-size: $font-size-h5;
                                                margin: 4px 0 0 26px;

                                                span {
                                                    font-size: $font-size-h4;
                                                    font-weight: 600;
                                                }
                                            }

                                            .cal-title {

                                                font-size: $font-size-h4;
                                                color: $Siemens_Petrol;
                                                font-weight: 600;

                                            }
    
                                        }

                                    }

                                }

                            }

                        }

                    }

                }

            }

            .wizard-footer {

                padding: 16px 8px 0 0;
                text-align: right;

                button {

                    margin: 0 16px 0 0;
                    height: 35px;

                    &:focus {

                        box-shadow: none;

                    }

                    &:last-child {

                        margin: 0;

                    }

                    &.cancel-btn {

                        border-color: $Siemens_Petrol;
                        background: $white;
                        color: $Grey__Dark;

                    }

                    &.submit-btn {

                        background: $Siemens_Petrol;
                        border-color: $Siemens_Petrol;
                        color: $white;
                        padding: 0 35px;

                    }

                    .p-button-label {

                        font-size: $font-size-h4;
                        font-weight: 500;

                    }

                }

            }

        }

    }

    &.xl {

        .wizard-header {

            width: 50vw;

        }

        .wizard-dialog {

            width: 50vw;

        }

    }

    &.lg {

        .wizard-header {

            width: 640px;

        }

        .wizard-dialog {

            width: 640px;

        }

    }

    &.md {

        .wizard-header {

            width: 480px;

        }

        .wizard-dialog {

            width: 480px;

        }

    }

    &.sm {

        .wizard-header {

            width: 320px;

        }

        .wizard-dialog {

            width: 320px;

        }

    }

}