.inspection-summary {
    height: 100%;

    &.grid-max {
            .tasks-datagrid {
                height: 100% !important;
                padding-top: 0 !important;
                .tab-content {
                    height: calc(100% - 55px);
                }
            }
        }
    
    &.reportsGenerated-max {
        .charts-area {   
            height: 100%;     
            .reports-generated {
                height: 100%;
                width: 100%;
                padding-top: 0;
                border-right:none;
            }
            .equipment-summary {
                display: none;
            }
        }
        .bt-line{
            display: none;
        }
        .tasks-datagrid {
            display: none;
            .tab-content {
                display: none;
            }
        }
    }
    
    &.equipmentSummary-max {
        .charts-area {
            height:100%;
            .reports-generated {
                display: none;
            }
            .equipment-summary {
                height: 100%;
                width: 100%;
                padding-top: 0;           
            }
        }
        .bt-line{
            display: none;
        }
        .tasks-datagrid {
            display: none;
        }
    }

    .charts-area {
        height: 50%;
        width: 100%;
        display: flex;

        .reports-generated,
        .equipment-summary {
            padding: 8px 32px;
            position: relative;
            .title {
                height: 40px;

                h5 {
                    text-align: center;
                    color: $Siemens_Petrol;
                    line-height: 24px;
                    margin-bottom: 16px;
                }
            }

            .recharts-responsive-container {
                height: calc(100% - 40px) !important;

                .recharts-wrapper {
                    .recharts-cartesian-axis {
                        &.xAxis {
                            .recharts-cartesian-axis-ticks {
                                .recharts-layer {
                                    font-size: 12px;
                                }
                            }
                        }
                    }

                    .recharts-legend-wrapper {
                        .recharts-default-legend {
                            .recharts-legend-item {
                                .recharts-legend-item-text {
                                    font-size: 12px;
                                }
                            }
                        }
                    }
                }
            }
            span{
                &.icon{
                    position: absolute;
                    top: 0;
                    right: 0;
                    color: $Siemens_Petrol;
                    cursor: pointer;
                }
            }
        }

        .reports-generated {
            width: 50%;
            border-right: 1px solid $Siemens_Petrol;
            .icon{
                margin-right: 0.5rem;
            }
        }

        .equipment-summary {
            width: 60%;
        }

    }

    .bt-line {
        border-top: 1px solid $Siemens_Petrol;
    }

    .tasks-datagrid {
        height: 50%;
        padding-top: 16px;

        .dashboard-expand-icon {
            position: relative;
            height: 0;

            .icon {
                position: absolute;
                right: 10px;
                color: #009999;
                cursor: pointer;
                top: 10px;
            }
        }

        .nav-tabs {
            border-bottom: 1px solid #D9EEEE;
            margin-bottom: 0;
            margin-left: 0;
            margin-right: 0;
            background-color: $white;

            .nav-link {
                font-size: $font-size-h5;
                padding: 10px 16px;
                margin: 0 4px 0px 0;
                border-bottom: none;
                color: light-shade($Siemens_Petrol, 10%);
                transition: none;

                &:after {
                    font-size: 32px;
                }

                &:hover {
                    color: $Siemens_Petrol;
                    background: light-shade($Siemens_Petrol, 90%);
                }

                &.active {
                    background-color: $Siemens_Petrol;
                    font-size: $font-size-h5;
                    color: $white;

                    &::before {
                        display: none;
                    }

                }
            }
        }

        .tab-content {
            height: calc(100% - 55px);

            .active-task-radiobutton {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .field-checkbox {
                    margin: 8px 0 8px 0;
                }

                .button-icon {
                    background-color: #009999;
                    color: #fff;
                    border: none;
                    box-shadow: none;
                    height: 26px;
                    padding: 0 8px;
                    font-size: $font-size-h5;

                    &:focus {
                        box-shadow: none;
                    }
                }
            }
            .faild_equipment{

                .dash_download{

                    padding: 4px 0;

                }

                .custom-table{

                    height: calc(100% - 34px) !important;

                }

            }
            .dashboard-button-icon-download {
                display: flex;
                justify-content: end;
                align-items: center;

                .button-icon {
                    background-color: #009999;
                    color: #fff;
                    border: none;
                    box-shadow: none;
                    height: 26px;
                    padding: 0 8px;
                    font-size: $font-size-h5;

                    &:focus {
                        box-shadow: none;
                    }
                    
                    svg {
                          height: 16px;
                          width: 16px;
                        }

                    .icon-download {
                        background-color: #009999;
                        color: #fff;
                        border: none;
                        box-shadow: none;
                        height: 100px;
                        padding: 0 8px;
                        font-size: $font-size-h5;

                }
            }
        }
        

            .sel-all {
                margin-bottom: 8px;

                label {
                    font-size: 11px;
                    color: #6c757d;
                }
            }

            .tab-pane {
                height: 100%;

                &.active-inspection {
                    .dashboard-table {
                        height: calc(100% - 60px);
                    }
                }

                .dashboard-table {
                    height: 100%;

                    .custom-table {
                        height: 100%;

                        .heading {
                            display: none;
                        }

                        .p-datatable {
                            height: 100%;

                            .p-datatable-header {
                                display: none;
                            }

                            .p-datatable-wrapper {
                                height: calc(100% - 16px);
                            }
                        }
                    }
                }

                .custom-table {
                    height: 100%;

                    .heading {
                        display: none;
                    }

                    .p-datatable {
                        height: 100%;

                        .p-datatable-header {
                            display: none;
                        }

                        .p-datatable-wrapper {
                            height: calc(100% - 10px);
                        }
                    }
                }
            }
        }

        .link-column {
            cursor: pointer;

            &:hover {
                color: $Siemens_Petrol;
                text-decoration: underline;
            }
        }

        .cursor-disabled {
            cursor: not-allowed;
        }
    }

}