.inspection-filter-container {
    .site-search {
        @include display(flex);

        svg {
            color: $Siemens_Petrol;
            cursor: pointer;
            margin-right: 6px
        }
    }

    .selected-site {
        @include display(flex);
        @include flex-alignItems(center);
        border-bottom: 1px solid $Siemens_Border__Light;
        margin-bottom: 16px;
        padding: 16px 0;

        svg {
            color: $Siemens_Petrol;
        }

        span {
            color: $Siemens_Petrol;
            padding-left: 6px;
            word-break: break-all;
        }
    }

    .customer-search {
        margin-bottom: 16px;

        .p-input-icon-right {
            width: 100%;

            .switch-left-icon {
                right: 15px;
                width: 1.25rem;
                height: 1.25rem;
                color: white;
                margin-top: -10px;
                z-index: 999;
            }

            .switch-right-icon {
                right: 38px;
                width: 1.25rem;
                height: 1.25rem;
                color: white;
                margin-top: -10px;
                z-index: 999;
            }

            .p-inputswitch {
                position: absolute;
                top: 50%;
                margin-top: -0.75rem;
                right: 0.5rem;
                width: 55px;
                height: 24px;
                z-index: 99;

                &.p-focus {
                    box-shadow: none;

                    .p-inputswitch-slider {
                        box-shadow: none;
                    }
                }

                &.p-inputswitch-checked {
                    .p-inputswitch-slider {
                        &::before {
                            transform: translateX(1.5rem);
                        }
                    }
                }

                .p-inputswitch-slider {
                    background: $Siemens_Petrol;
                }
            }

            .pi.pi-search {
                color: $Siemens_Petrol;
                font-weight: bold;
                cursor: pointer;
            }

            .p-inputtext {
                width: 100%;
                height: 31px;
                background: $Grey_Theme_Medium;
                border: 1px solid $Grey_Theme_Border;
                font-size: $font-size-h5;
                padding: 0.5rem;

                &:focus {
                    box-shadow: none;
                }
            }
        }
    }

    .customer-list-container {
        .add-section {
            .title {
                font-size: $font-size-h5;
                color: $Siemens_Petrol;
                cursor: pointer;
                text-decoration: underline;
            }
            .filterLabel{
                font-size: $font-size-h5;
                color: $Siemens_Petrol;
            }

            .field-checkbox {
                position: relative;
                left: -2rem;

            }
            .filterCheckBox{
                left: 0rem;
            }
            .add-btn {
                background: $Siemens_Border__Light;
                color: $Siemens_Petrol;
                padding: 7px;
                box-shadow: none;
                border: none;

                .icon {
                    width: 16px;
                    height: 16px;
                }
            }
        }

        .list-section {
            .customer-list {
                .customer-item {
                    background: $Siemens_Petrol;
                    color: $white;
                    font-size: $font-size-h5;
                    font-weight: 200;
                    padding: 5px 7px;
                    margin-bottom: 2px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    &.disabled {
                        pointer-events: none;
                        opacity: 0.7;
                    }

                    .icon-group {
                        display: flex;
                        flex-direction: column;

                        i {
                            font-size: 12px;
                        }
                    }
                }

                .p-card.contract-list-container {
                    background: $white;
                    border: 1px solid rgba(204, 204, 204, 0.2);
                    box-shadow: 0px 10px 13px rgba(17, 38, 146, 0.05);
                    border-radius: 4px;

                    .p-card-body {
                        padding: 10px;

                        .p-card-content {
                            padding: 0;
                        }
                    }

                    .contract-list {
                        max-height: 15rem;
                        overflow-y: auto;

                        .contract-item {
                            font-size: $font-size-h5;
                            font-weight: 400;
                            padding: 5px 7px;
                            word-wrap: break-word;

                            &.active,
                            &:hover {
                                background: $Siemens_Border__Light;
                            }

                            &.disabled {
                                pointer-events: none;
                                opacity: 0.7;
                            }
                        }

                        .contract-item-closed {
                            font-size: $font-size-h5;
                            font-weight: 400;
                            padding: 5px 7px;
                            color: $Red__Light;
                            position: relative;
                            cursor: pointer;
                            word-wrap: break-word;
                            &.active,
                            &:hover {
                                background: $Siemens_Border__Light;
                            }
                            &:hover{

                                &::after{
                                    content: "Closed Contract";
                                    position: absolute;
                                    top: 2px;
                                    left: 2px;
                                    padding: 2px 4px;
                                    background-color: $Grey_Theme_Medium;
                                    color: $Red__Light;
                                    font-weight: bold;
                                    border-radius: 5px;
                                    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
                                    z-index: 999;
                                }
                               
                            }
                            
                            &.disabled {
                                pointer-events: none;
                                opacity: 0.7;
                            }
                        }   
                    }
                }
            }
        }
    }
}