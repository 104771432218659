.system-radiobuttonlist{
    height:200px;
overflow-y:auto;
overflow-x:auto;

}

.error-show{
    color: $Red__Light;
    position: sticky;
    font-size: $font-size-h5;
    display: flex;
    align-items: center;
    top: 8px;

    svg {
        width: 15px;
        height: 15px;
        margin-right: 5px;
      }
}

.checkbox-error {
    top: 0px !important;
}

.border-box{

    background: #fff;
    border: 1px solid #ced4da;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s;
    border-radius: 6px;
    position: relative;
    padding: 15px;

    .title {
        position: absolute;
        top: 0;
        background: #fff;
        padding-left: 2px;
        font-size: .8125rem;
        margin-top: -.7rem;
        left: .75rem;
        color:$Siemens_Petrol;
    }

}