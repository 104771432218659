.equipment-list {
    width: 92vw;
    top: 36px;
    left: 20px;
    height: 100%;
    margin-bottom: 30px;

    .p-dialog-content {
        padding: 0;
        overflow-y: unset;
        background: transparent;
        height: 100%;

        .custom-header {
            background: $Siemens_Petrol;
            border-radius: 8px 8px 0px 0px;
            padding: 0px 24px;

            .box {
                @include display(flex);
                @include flex-direction(column);
                @include flex-justifyContents(center);
            }

            .title {
                color: $white;
                font-size: $font-size-h5;
            }

            .sub-title {
                color: $white;
                font-size: $font-size-h5;
                font-weight: 600;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            .pi.pi-times {
                color: $white;
                cursor: pointer;
                font-weight: 700;
            }
        }

        .custom-table-header {
            flex: 1 0 auto;
            background-color: #fff;

            .tab-col {
                display: flex;
                justify-content: space-between;

                ul.list {
                    @include display(flex);
                    @include flex-alignItems(center);
                    margin-left: 5px;

                    li.item {
                        cursor: pointer;
                        background: $white;
                        color: $Grey__Dark;
                        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
                        @include border-radius(8px);
                        font-size: $font-size-h5;
                        padding: 5px 20px;
                        font-weight: 500;
                        margin-right: 24px;

                        &.selected {
                            background: $Siemens_Petrol;
                            color: $white;
                        }

                        &:last-child {
                            margin-right: 0;
                        }
                    }
                }

                .report-progress {
                    display: flex;
                    align-items: center;
                    margin-right: 8px;

                    .progressBar-msg {

                        font-size: $font-size-h5;
                        font-weight: 500;
                        margin-right: 16px;

                    }

                    .progressBar {

                        width: 150px;
                        margin-right: 16px;

                        .p-progressbar {

                            height: 24px;

                            .p-progressbar-value {

                                background-color: $Siemens_Petrol;

                            }

                            .p-progressbar-label {

                                line-height: 24px;
                                font-size: 12px;

                            }

                        }

                    }

                    .report-section {
                        .link {
                            font-size: $font-size-h5;
                            font-weight: 500;
                            color: $Siemens_Petrol;
                            //text-decoration: underline;
                            cursor: pointer;
                        }

                        .button-icon {
                            background: inherit;
                            color: inherit;
                            border: none;
                            padding: 0;
                            box-shadow: none;

                            .icon {
                                width: 18px;
                                height: 18px;

                                &.report-icon {
                                    color: $Siemens_Petrol;
                                    height: 22px;
                                    position: relative;
                                    top: 2px;
                                }
                            }
                        }
                    }
                }
            }
        }

        .grid-wrapper {

            height: calc(100% - 92.5px);
            background-color: #fff;
            position: relative;

            @import "Systems/Systems.scss";

            @import "Contracts/Contracts.scss";

            @import "Questions/Questions.scss";

            @import "Equipment/EquipmentList.scss";

            @import "Summary/Summary.scss";


        }
    }
}

.equipment-group-modal {
    .p-dialog-content {
        overflow-x: hidden;
    }
}

.summery-wrapper {
    height: 100%;
    background: #fff;
}

//data-import-tooltip style EquipmentListDataImport.tsx

.data-import-tooltip {

    z-index: 9999;
    // --bs-tooltip-bg: #009999;
    --bs-tooltip-bg: #505050;
    min-width: 0;

    .tooltip-inner {

        font-size: 12px;

    }

}