.inspection-fields{

    padding: 0px 10px 0px 15px;
    margin: 0px 10px;
    height: calc(100% - 97px);

    .breadcrumb-sub-page-title{

        color: $Siemens_Petrol;
        font-size: $font-size-h4;
        font-weight: 600;
        
    }

    .header-wrapper{
                
        display: flex;
        justify-content: space-between;
        background-color:#fff ;
        padding: 0 16px;
        border-radius: 5px;

        .btn-wrapper-left{

            .box-btn{

                background: #FFFFFF;
                box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
                border-radius: 8px;
                height: 35px;
                font-weight: 500;
                font-size: $font-size-h5;
                border: 0;
                color: $Siemens-Dark_Green;
                margin: 16px 32px 18px 0px;

                &:hover{

                    background-color: $Siemens_Petrol;
                    color: #fff;
                    
                }

                &.active{

                    background-color: $Siemens_Petrol;
                    color: #fff;
                    
                }
                
            }
            
        }

        .btn-wrapper-right{

            .box-btn{

                background: #FFFFFF;
                border: 1px solid $Siemens_Petrol;
                border-radius: 8px;
                height: 35px;
                font-weight: 500;
                font-size: $font-size-h5;
                color: $Siemens-Dark_Green;
                margin: 16px 0px 18px 0px;

                &:hover{

                    background-color: $Siemens_Petrol;
                    color: #fff;
                    
                }
                
            }

        }

    }

    .question{

        margin-top: 8px;
        height: calc(100% - 121px);  
        display: flex;

        .tree-wrapper{

            width: 18%;
            padding: 0;
            height: 100%;
            border: 1px solid #dee2e6;
            background: #ffffff;
            color: #495057;
            border-radius: 6px;
            margin-right: 16px;

            .header{

                display: flex;
                padding: 12px 8px 8px 8px;
                justify-content: end;

                svg{
                   color: $Siemens_Petrol;
                   cursor: pointer;
                }

                .action-btns {
                    @include display(flex);
                    @include flex-alignItems(center);
                    @include flex-justifyContents(space-evenly);
                    background: $Siemens_Border__Light;
                    color: $Siemens_Petrol;
                    @include border-radius(4px);
                    padding: 3px 0px 3px 12px;
                    cursor: pointer;

                    .button-icon {
                        background: inherit;
                        color: inherit;
                        border: none;
                        padding: 0;
                        box-shadow: none;
                        margin-right: 12px;

                        .icon {
                            width: 16px;
                            height: 16px;
                        }

                        .caret-icon {
                            width: 15px;
                            height: 12px;
                            position: relative;
                            left: 2px;
                            color: $Blue__Light;
                            padding: 0;

                            &:focus {
                                box-shadow: none;
                            }
                        }
                    }
                }

            }

            .treeview-scroll{

                height: calc(100% - 70px);
                margin-right: 8px;
                margin-left: 8px;

                .p-tree{

                    height: 100%;
                    border: none;
                    padding: 0;
                    
                    .p-tree-container{ 
    
                        .p-treenode{
    
                            .p-treenode-content{
    
                                padding: 4px;

                                &.p-highlight{
                                    background-color: #CCEBEB;
                                    color: #495057;
                                }
                                
                                &:hover{
            
                                    background-color: #CCEBEB;
            
                                }
            
                                &:focus{
            
                                    box-shadow: unset;
            
                                }
            
                                .p-tree-toggler{
            
                                    height: 1rem;
                                    width: 1rem;
                                    margin-right: 16px;
                                    color: #495057;
            
                                    &:hover{
            
                                        background-color: unset;
                                        color:$Siemens_Petrol;
                                        
                                    }
            
                                    &:focus{
            
                                        box-shadow: unset;
                
                                    }
            
                                    .pi {
            
                                        font-size: 12px;
            
                                    }
            
                                }
            
                                .p-treenode-label{
            
                                    font-size: 14px;
                                    font-weight: 300;  
                                    max-width: 75%;   
                                    word-wrap: break-word;
                
                                }
    
                            }
    
                        }
    
                    }
                    
                }

            }

        }
        
        .expa{
            color: #009999;
            border: 1px solid #BECDD7;
            cursor: pointer;
            height: 26px;
        }

        input[type="number"] {
            -moz-appearance: textfield;
            
            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
        }
    }
}