.users-container {
    margin: 0 25px;
    height: calc(100% - 120px);

    .user-filter {
        flex: 1;
        max-width: 2%;
        margin-right: 7px;

        &.expanded {
            max-width: 18%;
        }
    }

    .user-table {
        flex: 1;
        max-width: 98%;

        &.expanded {
            max-width: 82%;
        }
    }
}

// Task Details style quickTask.tsx

.task-details-wrp{
     
    .text-container{

        width: 100%;
        display: flex;
        padding-bottom: 16px;
        font-size: $font-size-h5;
            font-weight: 500;

        .name{

            width: 200px;

        }

    }
    
}

.selectedUser {
    font-weight: 600;
    font-size: 14px;
}