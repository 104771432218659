.systems-container {
    @include spacing();
    padding: 0;
    margin: 0px 24px;
    height: calc(100% - 92px);

    .p-datatable-wrapper{
        height: calc(100% - 72px) !important;
    }
}

.upload-file-section {
    .p-fileupload-advanced {
        width: 100%;
        display: flex;
        flex-direction: column;
        border-top-right-radius: 0;
        border-top-left-radius: 0;
        border: none;
        padding: 0;
        border: 0.5px dashed $black;
        background-color: $Siemens_Border__Light;
        border-radius: 4px;
        padding: 20px 10px;
        text-align: center;
        margin-bottom: 20px;

        align-items: center;
        justify-content: center;

        .p-fileupload-buttonbar {
            order: 2;
            background-color: transparent;

            .custom-upload-btn,
            .custom-cancel-btn {
                display: none;
            }

            .p-fileupload-choose {
                background: $Siemens_Petrol;
                border-color: $Siemens_Petrol;
                color: $white;
                border: none;
                padding: 10px 26px;
                font-size: $font-size-h4;
                font-weight: 500;
                margin-right: 0;

                &:hover {
                    background: $Siemens_Petrol;
                    border-color: $Siemens_Petrol;
                    color: $white;
                    border: none;
                    padding: 10px 26px;
                    font-size: $font-size-h4;
                    font-weight: 500;
                }
                .pi-plus {
                    display: none;
                }
                .p-button-label {
                    font-size: $font-size-h4;
                    font-weight: 500;
                }
            }

            button {
                display: none;
            }
        }
        .p-fileupload-content {
            padding: 0;
            border: none;
            background-color: transparent;
            height: 60px;
            display: flex;
            align-items: flex-end;
            width: 100%;
            justify-content: center;

            .p-progressbar {
                display: none;
            }

            .p-fileupload-files {
                width: 100%;
                .p-fileupload-row {
                    width: 100%;
                    img {
                        display: none;
                    }
                    div {
                        padding: 0;

                        &:nth-child(2) {
                            display: none;
                        }
                        &:nth-child(3) {
                            text-align: left;
                            margin-left: 15px;
                            width: auto;
                        }
                    }

                    .p-fileupload-filename {
                        width: fit-content;
                        text-align: right;
                        font-size: $font-size-h5;
                    }
                    .p-button-icon-only {
                        color: $Siemens_Petrol;
                        background: transparent;
                        border: 1px solid $Siemens_Petrol;
                        border-radius: 50%;
                        width: 30px;
                        padding: 0;
                        height: 30px;
                    }
                }
            }
        }
    }
    .uploaded-file {
        width: 100%;
        margin-bottom: 15px;

        p {
            font-size: $font-size-h5;
            color: $black;
        }
        .uploaded-file-details {
            background-color: $Siemens_Border__Light;
            padding: 10px 10px;
            border-radius: 4px;
            align-items: center;

            svg.icon {
                color: $Siemens_Petrol;
            }

            .filename {
                flex: 0 0 200px;
                font-size: $font-size-h5;
                color: $black;
            }
            .date-icon-upload-details {
                width: 49%;
                justify-content: flex-end;
                align-items: center;

                small {
                    font-size: $font-size-h5;
                    color: $black;
                    margin-right: 15px;
                }

                svg.icon {
                    background-color: $white;
                    padding: 7px;
                    border-radius: 50%;
                    width: 30px;
                    height: 30px;
                    color: $Siemens_Petrol;
                }
            }
        }
    }
}

.master-data{
    width: 92vw;
    top: 36px;
    left: 20px;
    height: 100%;

    .p-dialog-content {
        padding-left: 0;
        padding-right: 0;
        overflow-y: unset;
        background: transparent;
        height: 100%;

        .custom-header {
            background: $Siemens_Petrol;
            border-radius: 8px 8px 0px 0px;
            padding: 0px 24px;

            .box {
                @include display(flex);
                @include flex-direction(column);
                @include flex-justifyContents(center);
            }

            .title {
                color: $white;
                font-size: $font-size-h5;
            }

            .sub-title {
                color: $white;
                font-size: $font-size-h5;
                font-weight: 600;
            }

            .pi.pi-times {
                color: $white;
                cursor: pointer;
                font-weight: 700;
            }
        }

        .second-row {
            display: flex;
            background-color: #fff;

            .select-box {
                .action-section {
                    width: 100%;

                    .field {
                        .p-dropdown {
                            .p-dropdown-label {
                                padding: 0.5rem;
                                font-size: $font-size-h5;
                            }

                            &:not(.p-disabled) {
                                &:hover {
                                    border-color: $Siemens_Petrol;
                                }

                                &.p-focus {
                                    border-color: $Siemens_Petrol;
                                    box-shadow: 0 0 0 0.1rem $Siemens_Petrol;
                                }
                            }
                        }

                        .p-calendar,
                        .p-dropdown,
                        .p-inputnumber {
                            &.p-inputwrapper-focus + label,
                            &.p-inputwrapper-filled + label {
                                color: $Siemens_Petrol;
                                top: 0;
                                background: $white;
                                padding-left: 2px;
                            }

                            &.p-inputwrapper-filled + label {
                                color: $Siemens_Petrol;
                            }
                        }

                        .p-dropdown {
                            &.error {
                                border-color: $Red__Light;
                                box-shadow: none;
                            }
                        }

                        .p-float-label {
                            font-size: $font-size-h5;

                            .p-inputtext {
                                &.error {
                                    border-color: $Red__Light;
                                    box-shadow: none;
                                }

                                &:enabled {
                                    &:hover {
                                        border-color: $Siemens_Petrol;
                                    }

                                    &:focus {
                                        border-color: $Siemens_Petrol;
                                        box-shadow: 0 0 0 0.1rem $Siemens_Petrol;
                                    }
                                }
                            }
                        }

                        .title {
                            color: #101010;
                            font-size: $font-size-h5;
                            font-weight: 400;
                            margin-bottom: 0;
                        }

                        .border-box {
                            background: #ffffff;
                            border: 1px solid #ced4da;
                            transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
                            border-radius: 6px;
                            position: relative;
                            padding: 15px;

                            &.report-min-h {
                                min-height: 292px;
                            }

                            .title {
                                position: absolute;
                                top: 0;
                                background: #ffffff;
                                padding-left: 2px;
                                font-size: $font-size-h5;
                                margin-top: -0.7rem;
                                left: 0.75rem;
                            }
                        }

                        .field-checkbox {
                            label {
                                color: #101010;
                                font-size: 13.5px;
                                font-weight: 400;
                                line-height: 17px;
                            }
                        }

                        .cal-title {
                            font-size: $font-size-h4;
                            color: $Siemens_Petrol;
                            font-weight: 600;
                        }
                    }

                    .p-float-label {
                        font-size: $font-size-h5;

                        .p-inputtext {
                            &.error {
                                border-color: $Red__Light;
                                box-shadow: none;
                            }

                            &:enabled {
                                &:hover {
                                    border-color: $Siemens_Petrol;
                                }

                                &:focus {
                                    border-color: $Siemens_Petrol;
                                    box-shadow: 0 0 0 0.1rem $Siemens_Petrol;
                                }
                            }
                        }
                    }
                }
            }

            .icon-wrapper {
                background-color: #fff;
                align-items: flex-end;
                padding-bottom: 8px;
                display: flex;
                justify-content: flex-end;

                .table-config {
                    @include display(flex);
                    @include flex-alignItems(center);

                    .action-btns {
                        @include display(flex);
                        @include flex-alignItems(center);
                        @include flex-justifyContents(space-evenly);
                        background: $Siemens_Border__Light;
                        color: $Siemens_Petrol;
                        @include border-radius(4px);
                        padding: 3px 7px 3px 15px;

                        .button-icon {
                            background: inherit;
                            color: inherit;
                            border: none;
                            padding: 0;
                            box-shadow: none;
                            margin-right: 20px;

                            .icon {
                                width: 16px;
                                height: 16px;
                            }
                            span {
                                height: 10px;
                                width: 10px;
                                margin-top: -21px;
                            }
                            .caret-icon {
                                width: 15px;
                                height: 12px;
                                position: relative;
                                left: 2px;
                                color: $Blue__Light;
                                padding: 0;

                                &:focus {
                                    box-shadow: none;
                                }
                            }
                        }
                    }
                }
            }
        }

        .custom-table{

            height: calc(100% - 81px);
        }
       
    }
}

.Proven-Outcomes {
    .footer-col {
        button {
            padding: 0 40px;
            height: 35px;
            margin: 0 1rem 0 0;

            &:focus {
                box-shadow: none;
            }

            &:last-child {
                margin: 0;
            }

            &.cancel-btn {
                border-color: $Siemens_Petrol;
                background: $white;
                color: $Grey__Dark;
            }

            &.submit-btn {
                background: $Siemens_Petrol;
                border-color: $Siemens_Petrol;
                color: $white;
            }

            .p-button-label {
                font-size: $font-size-h4;
                font-weight: 500;
            }
        }
    }
}

// .uploaded-file-details{
//     font-size: $font-size-h5;
//     span{
//         font-size: $font-size-h5;
//         color: $black;
//         width: 100%;
//         margin-left: 7px !important;
//     }
//     svg.icon{
//         width: 30px;
//         height: 30px
//     }
// }
// .date-icon-upload-details{
//     small{
//         font-size: $font-size-h5;
//         color: $black;
//     }
//     svg.icon{
//         background-color: $white;
//         padding: 7px;
//         border-radius: 50%;
//         width: 30px;
//         height: 30px;
//     }
//     .check-icon{
//         background-color: $white;
//         padding: 4px;
//         border-radius: 50%;
//         width: 30px;
//         height: 30px;
//         display: flex;
//         align-items: center;
//         justify-content: center;

//         .pi-check-circle{
//             color: $Siemens_Petrol;
//         }
//     }
// }
