.report-modal {
    padding-bottom: 1rem;

    .p-dialog-header {
        padding: 0rem 1rem;
        position: relative;
        top: 0.5px;

        .p-dialog-title {
            font-size: $font-size-h4;
            color: $Siemens_Petrol;
            font-weight: 600;
        }

        .p-dialog-header-icons {
            .p-dialog-header-close {
                color: $Siemens_Petrol;

                &:focus {
                    box-shadow: none;
                }
            }
        }
    }

    .p-dialog-content {
        padding: 0 1rem 1rem 1rem;
        overflow-y: auto;

        .content-box {
            border: 1px solid $Siemens_Border__Light;
            @include border-radius(6px);
            margin-top: 10px;

            .report-wrapper {
                height: 60vh;
                overflow-y: auto;
                border: 1px solid $Siemens_Border__Light;
                @include border-radius(6px);
            }
        }
    }

    .footer {
        padding-top: 1rem;
        padding-bottom: 0.2rem;

        button {
            margin: 0 1rem 0 0;
            height: 35px;

            &:focus {
                box-shadow: none;
            }

            &:last-child {
                margin: 0;
            }

            &.cancel-btn {
                border-color: $Siemens_Petrol;
                background: $white;
                color: $Grey__Dark;
            }

            &.submit-btn {
                background: $Siemens_Petrol;
                border-color: $Siemens_Petrol;
                color: $white;
            }

            .p-button-label {
                font-size: $font-size-h4;
                font-weight: 500;
            }
        }
    }

}

.gcv-main-view {
    #main_toolbar_Item_6 {
        display: none;
    }

    #main_toolbar_Item_7 {
        display: none;
    }
}

.gcv-main-view--fullscreen {
    .gcv-header-container--fullscreen {
        #main_toolbar_Item_8 {
            display: none;
        }

        #main_toolbar_Item_9 {
            display: none;
        }
    }
}