.form-data-wrapper {
    width: 100%;
    padding-top: 8px;

    .field {

        padding: 8px 0px 8px 8px;

        .field-checkbox {

            padding-bottom: 13px;

            &.field-checkbox:last-child {
                padding-bottom: 0;
            }

        }

        .radio-section {

            .field-radiobutton {

                margin-bottom: 13px;

            }

            .field-radiobutton:last-child {

                margin-bottom: 0px;

            }
        }

        .p-float-label {

            .p-inputtext {

                width: 100%;
            }

            .p-inputtextarea {

                height: 152px;
                width: 100%;
            }

                        label {
                            &.mandatory {
                                &::after {
            
                                    content: " *";
                                    color: #fe0808;
            
                                }
                            }
                        }
        }
    }
}