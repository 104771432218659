.connectivity-modal {
    .success-validation {
        padding: .5rem;
        color: $white;
        background-color: $Siemens_Petrol;
        // background-color: $Green__Light;
        @include border-radius(10px);
        font-size: .85rem;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .check-icon {
            width: 20px;
            height: 20px;
            margin-right: 6px;
        }
    }

    .p-label,
    .p-value {
        font-size: .85rem;
        font-weight: 600;
    }
}