
.report-mapping-grid {

    height: 100%;
    
    .p-treetable{

        height: 100%;

        .p-treetable-wrapper{

            height: 100%;

            table{

                //height: 100%;

                .p-treetable-thead{

                    position: sticky;
                    top: 0;
                    z-index: 999;
    
                    tr{
    
                        th{
    
                            justify-content: center;
                            background-color: #ccebeb;
                            padding: 0;
                            height: 40px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
    
                            .p-column-title {
    
                                font-size: 13px;
                                font-weight: 500;
                                padding: 0 16px;
                                text-align: center;
                                text-transform: capitalize;
    
                            }
    
                        }
    
                    }
    
                }
    
                .p-treetable-tbody{
    
                    height: calc(100% - 40px);
    
                    tr{
    
                        //border: 1px solid #cbcbcb;
    
                        &:focus {
    
                            outline: none;
    
                        }
    
                        td{
    
                            padding-top: 8px;
                            padding-bottom: 8px;
                            background: #7ccfcf;
                            font-size: 13px;
                            font-weight: 500;
    
                            &.col-expand{
    
                                // display: flex;
                                // align-items: center;
                                // background: #7ccfcf;
    
                                button{
    
                                    background: #7ccfcf;
                                    z-index: 99;
                                    height:16px;
                                    width: 16px;
                                    float:left;
    
                                    &:focus {
                                        
                                        box-shadow: none;
    
                                    }
    
                                    i{
    
                                        font-size: 10px;
                                        font-weight: 600;
    
                                    }
    
                                }
                                
                                :hover {
                                
                                    background:none;
                                    color:#495057;
    
                                }
    
                                .field-name{
    
                                    //width: calc(100% - 24px);
    
    
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    white-space: nowrap;
    
                                }
    
                            }
    
                        }
    
    
    
                    }
    
                    .has-no-child{
    
                        td{
    
                            background-color: white;
    
                            &:first-child{
    
                                background-color: #ccebeb;
                                border-bottom-color: #bdbdbd80;
                                left: 0;
                                position: sticky;
                                z-index: 999;
    
                            }
    
                        }
    
                    }
    
                }

            }

        }

    }

}

// .report-mapping-grid {

//     height:100%;

//     .p-datatable {

//         height: 100%;
//         table-layout: auto;
//         width: 100%;

//         .p-datatable-wrapper {

//             .p-datatable-table {

//                 .p-datatable-thead {

//                     z-index: 999;

//                     tr {

//                         th {

//                             justify-content: center;
//                             background-color: #CCEBEB;
//                             padding: 0;
                            

//                             &:first-child {

//                                 width: 160px;

//                             }

//                             .p-column-header-content {

//                                 .p-column-title {

//                                     font-size: 12px;
//                                     font-weight: 500;
//                                     padding: 4px 16px 4px 16px;
//                                     text-align: center;

//                                 }

//                             }

//                         }

//                     }

//                 }

//                 .p-datatable-tbody {

//                     //height: calc(100% - 61.5px);

//                     tr {

//                         &.wrapper-row {

                            

//                             td{

//                                 padding-top: 8px;
//                                 padding-bottom: 8px;
//                                 background: #7ccfcf;

//                                 &:first-child {

//                                     background: #7ccfcf;
//                                     z-index: 99;

//                                 }

//                                 .accordion-name{

//                                     display: flex;
//                                     justify-content: space-between;
//                                     align-items: center;
//                                     padding-right: 8px;
//                                     width: 100%;
//                                     padding-left: 16px;

//                                     h6{

//                                         font-size: 13px;
//                                         font-weight: 500;

//                                     }

//                                 }

//                             }

//                         }

//                         &.active {

//                             background: #009999;
//                         }

//                         &.show{
                            
//                             td{

//                                 padding-top: 4px;
//                                 padding-bottom: 4px;

//                             }

//                         }

//                         td {

//                             padding: 0;

//                             &.expand-col-title {

//                                 background: #CCEBEB;

//                             }

//                             .checkbox-wrapper {

//                                 width: 100%;
//                                 padding-left: 8px;
//                                 justify-content: center;

//                                 .p-checkbox {

//                                     width: 14px;
//                                     height: 14px;

//                                     .p-checkbox-box{

//                                         width: 14px;
//                                         height: 14px;

//                                     }

//                                 }

//                             }

//                         }

//                         &.p-datatable-row-expansion {

//                             td {


//                                 .p-datatable{

//                                     .p-datatable-wrapper {

//                                         .p-datatable-table {
    
//                                             .p-datatable-thead{
    
//                                                 display: none;
                                                
//                                             }
    
//                                             .p-datatable-tbody {
    
//                                                 tr {
    
//                                                     td {
    
//                                                         .sub-title {
    
//                                                             padding-left: 24px;
//                                                             font-size: 13px;
    
//                                                         }
    
//                                                     }
    
//                                                 }
    
//                                             }
    
//                                         }
    
//                                     }
                                    
//                                 }

//                             }

//                         }

//                     }

//                 }

//             }

//         }

//     }

// }


.checkbox-Header {
    .p-checkbox {
        .p-checkbox-box {
            width: 18px;
            height: 18px;
            border-radius: 2px;
            border: 1px solid $Siemens_Stone__Light;

            &.p-highlight {
                border-color: $Siemens_Petrol;
                background: $Siemens_Petrol;

                &:hover {
                    border-color: $Siemens_Petrol;
                    background: $Siemens_Petrol;
                }
            }

            &.p-focus {
                border-color: $Siemens_Petrol;
                box-shadow: none;
            }

            &:hover {
                border-color: $Siemens_Petrol;
            }
        }
    }
}
