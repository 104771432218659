.view-file-modal {

    .p-dialog-header {
        padding: 0rem 1rem;
        position: relative;
        top: 0.5px;

        .p-dialog-title {
            font-size: $font-size-h4;
            color: $Siemens_Petrol;
            font-weight: 600;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;

            span{

                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;

            }

        }

        .p-dialog-header-icons {
            .p-dialog-header-close {
                color: $Siemens_Petrol;

                &:focus {
                    box-shadow: none;
                }
            }
            .p-dialog-header-maximize {
                color: $Siemens_Petrol;

                &:focus {
                    box-shadow: none;
                }
            }
        }
    }

    .p-dialog-content {
        padding: 0 1rem 2rem 1rem;
        overflow-y: hidden;

        .content-box {
            border: 1px solid $Siemens_Border__Light;
            padding: .5rem;
            @include border-radius(10px);
            margin-top: 10px;
            height: 100%;

            .file-view-wrapper {
                max-height: 100%;
                width: 100%;
                overflow-y: auto;
                .image-file{
                    max-height: 60vh;
                }
            }
            .file-error-wrapper{
                min-width: 400px;
                min-height: 250px;
            }

        }
    }

    .footer {
        padding-top: 1rem;
        padding-bottom: 0.2rem;

        button {
            margin: 0 1rem 0 0;
            height: 35px;

            &:focus {
                box-shadow: none;
            }

            &:last-child {
                margin: 0;
            }

            &.cancel-btn {
                border-color: $Siemens_Petrol;
                background: $white;
                color: $Grey__Dark;
            }

            &.submit-btn {
                background: $Siemens_Petrol;
                border-color: $Siemens_Petrol;
                color: $white;
            }

            .p-button-label {
                font-size: $font-size-h4;
                font-weight: 500;
            }
        }
    }
}