.drawing-list {

    height: 100%;
    
    .p-treetable{

        height: 100%;

        .p-treetable-wrapper{

            height: 100%;

            table{

                .p-treetable-thead{

                    position: sticky;
                    top: 0;
                    z-index: 999;
    
                    tr{
    
                        th{
    
                            justify-content: center;
                            background-color: #ccebeb;
                            padding: 0;
                            height: 40px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
    
                            .p-column-title {
    
                                font-size: 13px;
                                font-weight: 500;
                                padding: 0 16px;
                                text-align: center;
                                text-transform: capitalize;
    
                            }
    
                        }
    
                    }
    
                }
    
                .p-treetable-tbody{
    
                    height: calc(100% - 40px);
    
                    tr{

                        &:focus {
    
                            outline: none;
    
                        }
    
                        td{
    
                            padding-top: 8px;
                            padding-bottom: 8px;
                            background: #7ccfcf;
                            font-size: 13px;
                            font-weight: 500;
    
                            &.col-expand{
    
                                button{
    
                                    background: #7ccfcf;
                                    z-index: 99;
                                    height:16px;
                                    width: 16px;
                                    float:left;
    
                                    &:focus {
                                        
                                        box-shadow: none;
    
                                    }
    
                                    i{
    
                                        font-size: 10px;
                                        font-weight: 600;
    
                                    }
    
                                }
                                
                                :hover {
                                
                                    background:none;
                                    color:#495057;
    
                                }
    
                                .field-name{
    
                                    overflow: hidden;
                                    word-break: break-word;
                                    
    
                                }
    
                            }
    
                        }
    
    
    
                    }

                    .p-highlight{

                        td{

                            color:#495057;
                            background-color: #ccebeb !important;

                        }

                    }
    
                    .has-no-child{
    
                        td{
    
                            background-color: white;
                            word-break: break-word;
    
                            &:first-child{
    
                                background-color: #ccebeb;
                                border-bottom-color: #bdbdbd80;
                                left: 0;
                                position: sticky;
                                z-index: 999;
    
                            }
    
                        }
    
                    }
    
                }

            }

        }

    }

}

// .drawing-list{

//     .search-section{

//         .table-search{

//             .p-inputtext{
//                 background: #EEEEEE;
//                 min-width: 18rem;
//                 padding: 2px 10px;
//                 font-size: 0.8125rem;
//                 border: 1px solid #D9EEEE;

//                 &:focus{
//                     box-shadow: none;
//                 }
//             }

//             .pi.pi-search{
//                 left: unset;
//                 right: 0.75rem;
//                 color: #009999;
//                 font-weight: 600;
//             }
//         }
//     }
//     .draw-table-tree{

//         .p-treetable{

//             .p-treetable-wrapper{

//                 table{

//                     .p-treetable-thead{

//                         tr{

//                             th{
//                                 background: $Grey_Theme_Border;
//                                 color: $Grey__Dark;
//                                 font-weight: 600;
//                                 font-size:$font-size-h5;
//                                 padding: 0.2rem 0.5rem;
//                             }
//                         }
//                     }

//                     .p-treetable-tbody{

//                         font-size: $font-size-h5;

//                         tr{

//                             &:focus{
//                                 outline: none;
//                             }
//                             &.p-highlight {
//                                 color: $Siemens_Petrol;
//                                 outline: none;
    
//                                 td {
//                                     background: #EEF2FF !important;
//                                 }
//                             }
//                             td{
//                                 padding: 0 0.5rem;

//                                 &:first-child{
//                                     color: $Grey__Dark;
//                                     font-weight: 600;
//                                     font-size:$font-size-h4;
//                                     display: flex;
//                                     align-items: center;
                                    

//                                     .p-treetable-toggler{
//                                         background-color: transparent;
//                                         color: $Grey__Dark;

//                                         &:focus{
//                                             outline: 0 none;
//                                             outline-offset: 0;
//                                             box-shadow: 0 0 0 0rem #c7d2fe !important;
//                                         }
//                                     }
//                                 }
//                             }
//                         }
//                     }
//                 }
//             }
//         }
//     }
// }