.equi-list-data-import {
    width: 92vw;
    top: 36px;
    left: 20px;
    height: 100%;
    z-index: -9;
    position: relative;

    .p-dialog-content {
        padding-left: 0;
        padding-right: 0;
        overflow-y: unset;
        background: transparent;
        height: 100%;

        .custom-header {
            background: $Siemens_Petrol;
            border-radius: 8px 8px 0px 0px;
            padding: 0px 24px;

            .box {
                @include display(flex);
                @include flex-direction(column);
                @include flex-justifyContents(center);
            }

            .title {
                color: $white;
                font-size: $font-size-h5;
            }

            .sub-title {
                color: $white;
                font-size: $font-size-h5;
                font-weight: 600;
            }

            .pi.pi-times {
                color: $white;
                cursor: pointer;
                font-weight: 700;
            }
        }

        .second-row {
            display: flex;
            background-color: #fff;

            .icon-wrapper {
                background-color: #fff;
                align-items: flex-end;
                padding-bottom: 8px;
                display: flex;
                justify-content: flex-end;

                .table-config {
                    @include display(flex);
                    @include flex-alignItems(center);

                    .action-btns {
                        @include display(flex);
                        @include flex-alignItems(center);
                        @include flex-justifyContents(space-evenly);
                        background: $Siemens_Border__Light;
                        color: $Siemens_Petrol;
                        @include border-radius(4px);
                        padding: 3px 7px 3px 15px;
                        cursor: pointer;

                        .button-icon {
                            background: inherit;
                            color: inherit;
                            border: none;
                            padding: 0;
                            box-shadow: none;
                            margin-right: 20px;

                            .icon {
                                width: 16px;
                                height: 16px;
                            }

                            span {
                                height: 10px;
                                width: 10px;
                                margin-top: -21px;
                            }

                            .caret-icon {
                                width: 15px;
                                height: 12px;
                                position: relative;
                                left: 2px;
                                color: $Blue__Light;
                                padding: 0;

                                &:focus {
                                    box-shadow: none;
                                }
                            }
                        }
                    }
                }
            }
        }

        .pre-loader {

            height: calc(100% - 113px);
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            z-index: 9999;
            background-color: var(--maskbg);

            h6{

                color: #fff;
                margin-top: 120px;
                position: absolute;
                text-shadow: 1px 1px #666363c2;
    
            }

            @keyframes p-progress-spinner-color {

                100%,
                0% {
                    stroke: $Siemens_Petrol;
                }

                40% {
                    stroke: $Siemens_Petrol;
                }

                66% {
                    stroke: $Siemens_Petrol;
                }

                80%,
                90% {
                    stroke: $Siemens_Petrol;
                }
            }

        }

        .custom-table {

            height: calc(100% - 81px);

            .table-col {

                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;

                .warning {

                    color: orange;

                }

                .failed {

                    color: red;

                }

            }

        }

    }
}