
.system-table{

    height: 100%;

    .custom-table{

        height: 100%;

        .p-datatable{

            height: calc(100% - 20px);

            .p-datatable-header{
    
                .search-section{
    
                    .p-dropdown{
    
                        height: 37.5px !important;
                        align-items: center;
        
                    }
    
                }
    
                .table-config{
    
                    .box-btn-wrapper{
    
                        display: flex;
    
                        .box-btn{
    
                            background: #FFFFFF;
                            box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
                            border-radius: 8px;
                            height: 35px;
                            font-weight: 400;
                            font-size: $font-size-h5;
                            border: 0;
                            color: $Siemens-Dark_Green;
                            margin: 16px 32px 18px 0px;
    
                            &:hover{
    
                                background-color: $Siemens_Petrol;
                                color: #fff;
                                
                            }

                            @include cmw(1475) {

                                margin: 12px 13px 12px 0px;
                                padding: 5px 8px;
                    
                            }
                            
                        }
    
                    }
    
                    .text-wrapper{
    
                        display: flex;
    
                        span{
    
                            font-size: $font-size-h5;
                            padding-right: 8px;
                            font-weight: 400;
    
                        }
    
                        span:last-child{
    
                            color: #443DF6;
                            content: "";
                            display: flex;
                            margin: 0 auto;
                            border-left: 2px solid $Siemens-Dark_Green;
                            margin-right: 32px;
                            padding-left: 8px;

                            @include cmw(1475) {

                                margin-right: 0px;
                    
                            }
    
                        }
    
                    }
                    
                }
    
            }

            .p-datatable-wrapper{

                height: calc(100vh - 109px); 

                .p-datatable-table{

                    min-width: 100% !important;

                    .date-time{
    
                        color: #443DF6 !important;
            
                    }

                }
    
            }

        }

    }

}
    

