.equipment-list-pre{

    &.pre-loader {

        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        z-index: 9999;
        background-color: var(--maskbg);

        h6{

            color: #fff;
            margin-top: 120px;
            position: absolute;
            text-shadow: 2px 2px #666363c2;

        }

        @keyframes p-progress-spinner-color {

            100%,
            0% {
                stroke: $Siemens_Petrol;
            }

            40% {
                stroke: $Siemens_Petrol;
            }

            66% {
                stroke: $Siemens_Petrol;
            }

            80%,
            90% {
                stroke: $Siemens_Petrol;
            }
        }

    }

}

.container-fluid {

    .second-row {
        display: flex;
        background-color: #fff;

        .select-box {
            .action-section {
                width: 100%;

                .field {
                    .p-dropdown {
                        .p-dropdown-label {
                            padding: 0.5rem;
                            font-size: $font-size-h5;
                        }

                        &:not(.p-disabled) {
                            &:hover {
                                border-color: $Siemens_Petrol;
                            }

                            &.p-focus {
                                border-color: $Siemens_Petrol;
                                box-shadow: 0 0 0 0.1rem $Siemens_Petrol;
                            }
                        }
                    }

                    .p-calendar,
                    .p-dropdown,
                    .p-inputnumber {
                        &.p-inputwrapper-focus + label,
                        &.p-inputwrapper-filled + label {
                            color: $Siemens_Petrol;
                            top: 0;
                            background: $white;
                            padding-left: 2px;
                        }

                        &.p-inputwrapper-filled + label {
                            color: $Siemens_Petrol;
                        }
                    }

                    .p-dropdown {
                        &.error {
                            border-color: $Red__Light;
                            box-shadow: none;
                        }
                    }

                    .p-float-label {
                        font-size: $font-size-h5;

                        .p-inputtext {
                            &.error {
                                border-color: $Red__Light;
                                box-shadow: none;
                            }

                            &:enabled {
                                &:hover {
                                    border-color: $Siemens_Petrol;
                                }

                                &:focus {
                                    border-color: $Siemens_Petrol;
                                    box-shadow: 0 0 0 0.1rem $Siemens_Petrol;
                                }
                            }
                        }
                    }

                    .icon-fields {
                        justify-content: flex-end;
                        align-items: center;
                        margin-left: -16px;
                        
                        .field {
                            margin-bottom: 0px;
                            .action-btns {
                                @include display(flex);
                                @include flex-alignItems(center);
                                @include flex-justifyContents(space-between);
                                @include border-radius(4px);
                                cursor: pointer;
                                height: 16px;
                                width: 32px;
                                padding: 0 10px;
                                background: $Siemens_Border__Light;
                                color: $Siemens_Petrol;
                                margin-right: 10px;

                                .button-icon {
                                    background: inherit;
                                    color: inherit;
                                    border: none;
                                    padding: 0;
                                    box-shadow: none;

                                    .icon {
                                        width: 16px;
                                        height: 16px;
                                    }
                                }
                            }
                        }
                    }

                    .title {
                        color: #101010;
                        font-size: $font-size-h5;
                        font-weight: 400;
                        margin-bottom: 0;
                    }

                    .border-box {
                        background: #ffffff;
                        border: 1px solid #ced4da;
                        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
                        border-radius: 6px;
                        position: relative;
                        padding: 15px;

                        &.report-min-h {
                            min-height: 292px;
                        }

                        .title {
                            position: absolute;
                            top: 0;
                            background: #ffffff;
                            padding-left: 2px;
                            font-size: $font-size-h5;
                            margin-top: -0.7rem;
                            left: 0.75rem;
                        }
                    }

                    .field-checkbox {
                        label {
                            color: #101010;
                            font-size: 13.5px;
                            font-weight: 400;
                            line-height: 17px;
                        }
                    }

                    .cal-title {
                        font-size: $font-size-h4;
                        color: $Siemens_Petrol;
                        font-weight: 600;
                    }
                }

                // .p-float-label {
                //     font-size: $font-size-h5;

                //     .p-inputtext {
                //         &.error {
                //             border-color: $Red__Light;
                //             box-shadow: none;
                //         }

                //         &:enabled {
                //             &:hover {
                //                 border-color: $Siemens_Petrol;
                //             }

                //             &:focus {
                //                 border-color: $Siemens_Petrol;
                //                 box-shadow: 0 0 0 0.1rem $Siemens_Petrol;
                //             }
                //         }
                //     }
                // }

                .icon-fields {
                    justify-content: flex-end;
                    align-items: center;
                    margin-left: -16px;
                    
                    .field {
                        margin-bottom: 0px;
                        .action-btns {
                            @include display(flex);
                            @include flex-alignItems(center);
                            @include flex-justifyContents(space-between);
                            @include border-radius(4px);
                            cursor: pointer;
                            height: 16px;
                            width: 32px;
                            padding: 0 10px;
                            background: $Siemens_Border__Light;
                            color: $Siemens_Petrol;
                            margin-right: 10px;

                            .button-icon {
                                background: inherit;
                                color: inherit;
                                border: none;
                                padding: 0;
                                box-shadow: none;

                                .icon {
                                    width: 16px;
                                    height: 16px;
                                }
                            }
                        }
                    }
                }
            }
        }

        .icon-wrapper {
            background-color: #fff;
            align-items: flex-end;
            padding-bottom: 8px;
            display: flex;
            justify-content: flex-end;

            .table-config {
                @include display(flex);
                @include flex-alignItems(center);

                .action-btns {
                    @include display(flex);
                    @include flex-alignItems(center);
                    @include flex-justifyContents(space-evenly);
                    background: $Siemens_Border__Light;
                    color: $Siemens_Petrol;
                    @include border-radius(4px);
                    padding: 3px 7px 3px 15px;
                    cursor: pointer;

                    .button-icon {
                        background: inherit;
                        color: inherit;
                        border: none;
                        padding: 0;
                        box-shadow: none;
                        margin-right: 20px;

                        .icon {
                            width: 16px;
                            height: 16px;
                        }
                        span {
                            height: 10px;
                            width: 10px;
                            margin-top: -21px;
                        }
                        .caret-icon {
                            width: 15px;
                            height: 12px;
                            position: relative;
                            left: 2px;
                            color: $Blue__Light;
                            padding: 0;

                            &:focus {
                                box-shadow: none;
                            }
                        }
                    }
                }
            }
        }
    }
}

.custom-table{

    height: calc(100% - 56px);

    .p-datatable {
        
        height: calc(100% - 20px);

        .p-datatable-header{

            display: none;

        }

        .p-datatable-wrapper{

            height: calc(100% - 37px);

        }

    }

}

// .equi-list-data{
//     width: 92vw;
//     top: 36px;
//     left: 20px;
//     height: 100%;

//     .p-dialog-content {
//         padding-left: 0;
//         padding-right: 0;
//         overflow-y: unset;
//         background: transparent;
//         height: 100%;

//         .custom-header {
//             background: $Siemens_Petrol;
//             border-radius: 8px 8px 0px 0px;
//             padding: 0px 24px;

//             .box {
//                 @include display(flex);
//                 @include flex-direction(column);
//                 @include flex-justifyContents(center);
//             }

//             .title {
//                 color: $white;
//                 font-size: $font-size-h5;
//             }

//             .sub-title {
//                 color: $white;
//                 font-size: $font-size-h5;
//                 font-weight: 600;
//             }

//             .pi.pi-times {
//                 color: $white;
//                 cursor: pointer;
//                 font-weight: 700;
//             }
//         }

//         .second-row {
//             display: flex;
//             background-color: #fff;

//             .icon-wrapper {
//                 background-color: #fff;
//                 align-items: flex-end;
//                 padding-bottom: 8px;
//                 display: flex;
//                 justify-content: flex-end;

//                 .table-config {
//                     @include display(flex);
//                     @include flex-alignItems(center);

//                     .action-btns {
//                         @include display(flex);
//                         @include flex-alignItems(center);
//                         @include flex-justifyContents(space-evenly);
//                         background: $Siemens_Border__Light;
//                         color: $Siemens_Petrol;
//                         @include border-radius(4px);
//                         padding: 3px 7px 3px 15px;
//                         cursor: pointer;

//                         .button-icon {
//                             background: inherit;
//                             color: inherit;
//                             border: none;
//                             padding: 0;
//                             box-shadow: none;
//                             margin-right: 20px;

//                             .icon {
//                                 width: 16px;
//                                 height: 16px;
//                             }
//                             span {
//                                 height: 10px;
//                                 width: 10px;
//                                 margin-top: -21px;
//                             }
//                             .caret-icon {
//                                 width: 15px;
//                                 height: 12px;
//                                 position: relative;
//                                 left: 2px;
//                                 color: $Blue__Light;
//                                 padding: 0;

//                                 &:focus {
//                                     box-shadow: none;
//                                 }
//                             }
//                         }
//                     }
//                 }
//             }
//         }

//         .custom-table{

//             height: calc(100% - 81px);

//             .test{

//                 display: none;

//                 .warning{
//                     color: orange;
//                 }

//                 .failed{

//                     color: green;
//                 }

//             }
//         }
       
//     }
// }
