.site-modal {

    .p-dialog-header {
        padding: 0rem 1rem;
        position: relative;
        top: 0.5px;

        .p-dialog-title {
            font-size: $font-size-h4;
            color: $Siemens_Petrol;
            font-weight: 600;
        }

        .p-dialog-header-icons {
            .p-dialog-header-close {
                color: $Siemens_Petrol;

                &:focus {
                    box-shadow: none;
                }
            }
        }
    }

    .p-dialog-content {
        padding: 0 1rem 0.5rem 1rem;
        overflow-y: hidden;

        .content-box {
            border: 1px solid $Siemens_Border__Light;
            padding: 0.2rem 1rem;
            @include border-radius(10px);

            .header {
                .title {
                    color: $Grey__Dark;
                    font-size: $font-size-h4;
                    font-weight: 600;
                }

                .value {
                    padding-left: 5px;
                    color: $Siemens_Petrol;
                    font-size: $font-size-h4;
                    font-weight: 600;
                }
            }

            .sub-header {
                font-size: $font-size-h5;
                color: $badge-red-count;
                margin-bottom: 0.2rem;
            }

            .search-bar {
                margin-bottom: 0.5rem;

                .inputgroup {
                    width: 85%;

                    .pi-search {
                        color: $Siemens_Petrol;
                        font-size: $font-size-h3;
                        cursor: pointer;
                    }

                    .p-inputtext {
                        width: 100%;
                        background: $Grey_Medium;
                        border: 1px solid $Siemens_Border__Light;
                        border-radius: 2rem;
                        color: $Grey__Light;
                        font-size: $font-size-h5;
                        padding: 0.5rem 2.5rem 0.5rem 0.5rem;

                        &:focus {
                            box-shadow: none;
                        }
                    }
                }
            }
        }
    }

    .footer {
        padding-top: 1rem;
        padding-bottom: 0.2rem;

        button {
            margin: 0 1rem 0 0;
            height: 35px;

            &:focus {
                box-shadow: none;
            }

            &:last-child {
                margin: 0;
            }

            &.cancel-btn {
                border-color: $Siemens_Petrol;
                background: $white;
                color: $Grey__Dark;
            }

            &.submit-btn {
                background: $Siemens_Petrol;
                border-color: $Siemens_Petrol;
                color: $white;
            }

            .p-button-label {
                font-size: $font-size-h4;
                font-weight: 500;
            }
        }
    }
}