.manage_display_setting{
    
    padding: 0px 10px 0px 15px;
    margin: 0px 10px;
    height: calc(100% - 97px);


    .breadcrumb-sub-page-title{

        color: $Siemens_Petrol;
        font-size: $font-size-h4;
        font-weight: 600;

    }

    .manage-display-table {

        background-color: #fff;
        height: calc(100% - 44px);
    
        .custom-table {

            //overflow: hidden;
            height: calc(100% - 67px);
    
            .search-section {

                padding: 0.2rem;
                justify-content: space-between;
    
                .country-item {
                    border: 1px solid $Border_Light;
                    cursor: pointer;
    
                    img {
                        width: 22px;
                    }
    
                    span {
                        font-size: $font-size-h5;
                        font-weight: 500;
                        color: inherit;
                    }
    
                    &.active {
                        border: 3px solid $Siemens_Petrol;
    
                        span {
                            color: $Siemens_Petrol;
                        }
                    }
                }
                .attribute-dropdown {
                    .p-float-label {
                        font-size: $font-size-h5;
                        width: 15rem;
    
                        .p-dropdown {
                            height: 26px;
    
                            .p-dropdown-label.p-inputtext {
                                padding: 0.2rem 0.5rem;
                                font-size: $font-size-h5;
                            }
    
                            &.p-inputwrapper-focus + label,
                            &.p-inputwrapper-filled + label {
                                color: $Siemens_Petrol;
                                top: 0;
                                background: $white;
                                padding-left: 2px;
                            }
    
                            &.p-inputwrapper-filled + label {
                                color: $Siemens_Petrol;
                            }
    
                            &.error {
                                border-color: $Red__Light;
                                box-shadow: none;
                            }
    
                            &:hover {
                                border-color: $Siemens_Petrol;
                            }
    
                            &:not(.p-disabled).p-focus {
                                border-color: $Siemens_Petrol;
                                box-shadow: 0 0 0 0.1px $Siemens_Petrol;
                            }
                        }
                    }
                }
            }
    
            .p-datatable {

                height: calc(100% - 62px);
                overflow-x: hidden;
                
                .p-datatable-wrapper{

                    height: 100%;

                    .p-datatable-table{

                        height: 100%;

                        .p-datatable-thead{

                            position: sticky;
                            top: 0;
                            z-index: 999;

                            tr {
        
                                th {
        
                                    padding: 0.5rem 0.2rem !important;
        
                                }
                
                                td {
        
                                    padding: 0.5rem 0.2rem;
        
                                }
        
                            }
                        }
            
                        .p-datatable-tbody{
        
                            height: calc(100% - 36px);
        
                            tr {
                                th {
                                    padding: 0.5rem 0.2rem !important;
                                }
                
                                td {
                                    padding: 0.5rem 0.2rem;
                
                                    .color-select__wrapper {
                                        position: relative;
                
                                        .color-select {
                                            height: 18px;
                                            width: 32px;
                                            border: 1px solid #dee2e6;
                                            cursor: pointer;
                                            position: relative;
                                            border-radius: 4px;
                                        }
                
                                        #color-picker {
                                            display: block;
                                            z-index: 99;
                                            position: absolute;
                                            left: 38px;
                                        }
                                    }
                
                                    input {
                                        width: 30px;
                                        height: 19px;
                                        padding: 0;
                                        text-align: center;
                                        font-size: 12px;
                                        border-radius: 4px;
                                    }
                                }
                            }
        
                        }

                    }

                }
                
            }
        }
    
        .p-dialog-footer {
            
            text-align: left;
            margin: 16px 0 16px 8px;
    
            button {
                height: 35px;
    
                .p-button-label {
                    font-size: $font-size-h4;
                    font-weight: 500;
                }
    
                &:focus {
                    box-shadow: 0 0 0 0.1rem $Siemens_Petrol;
                }
    
                &.cancel-btn {
                    border-color: $Siemens_Petrol;
                    background: $white;
                    color: $Grey__Dark;
                    margin: 0 16px 0 0;
                }
    
                &.save-btn {
                    background: $Siemens_Petrol;
                    border-color: $Siemens_Petrol;
                    color: $white;
                }
            }
        }
    }
    
}
/* field-order style start */
// .field-order-model {
//     .p-dialog-content {
//         height: 300px;
//         .p-datatable-thead {
//             th {
//                 padding: 0;
//                 background: none;
//                 transition: none;
//             }
//         }

//         .p-datatable-tbody {

//             tr {
//                 &:focus.p-selectable-row{
//                     outline: none;
//                 }

//                 td {
//                     padding: 4px;
//                     font-size: 14px;

//                     i {
//                         color: $Siemens_Petrol;
//                     }
//                 }
//             }
//         }
//     }
// }
