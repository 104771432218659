.security-settings {
  margin: 0 24px;
  height: calc(100% - 92px);

  .breadcrumb-sub-page-title{
      color: $Siemens_Petrol;
      font-size: $font-size-h4;
          font-weight: 600;
  }
}






.securitysettings-container {
  @include spacing();
  margin: 0 25px 0 20px;
  padding: 0px 0px 0px 0px;
  background: transparent;
  height: calc(100% - 60px);

  .securitysettings-leftsidebar {
    max-width: 18%;
    flex: 1 1;
    margin-right: 7px;

    .securitysettings-listname {
      background: $white;
      width: 100%;
      color: $Grey__Dark;
      border-radius: 8px;
      padding: 12px;

      .main {
        ul.list-items {
          li {
            font-size: $font-size-h5;
            margin-top: 0.5rem;
            cursor: pointer;

            &.active,
            &:hover {
              background: $Siemens_Border__Light;
              color: $Siemens_Petrol;
            }
          }
        }
      }
    }
  }

  .securitysettings-expanded {
    height: 100%;

    .main-maildomains {
      height: 100%;

      .main-maildomain {
        height: 100%;

        .domain-list-add {

          .p-scrollpanel{
            .p-scrollpanel-bar-x{
              display: none;
            }
            .p-scrollpanel-wrapper{
              .p-scrollpanel-content{
                padding: 0;
              }
            }
          }
          .add-main-maildomain {
            margin: 0 auto;
            .add-domain {
              width: 70%;
              .add-domain-field {
                position: relative;
                input {
                  width: 100%;
                  border-color: $Siemens_Petrol;
                  &:hover {
                    border-color: $Siemens_Petrol;
                  }
                  &:focus {
                    border-color: $Siemens_Petrol;
                    box-shadow: 0 0 0 0.1rem $Siemens_Petrol;
                  }
                }
              }
            }
          }
          .domain-add {
            .new-mail-domain {
              cursor: pointer;
              background: $Siemens_Border__Light;
              color: $Siemens_Petrol;
              padding: 3px 6px;
              border-radius: 3px;
              display: flex;
              align-items: center;

              .pi {
                font-size: 0.7rem;
              }
            }
          }
          .mail-domain-list {
            .p-card {
              .p-card-body {
                .p-card-content {
                  padding: 0px;
                  display: flex;
                  align-items: center;
                  .icon {
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    border: 1px solid $Siemens_Petrol;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    span {
                      i {
                        font-size: 20px;
                        line-height: 40px;
                        color: $Siemens_Petrol;
                      }
                    }
                  }
                  .domain-name {
                    width: 65%;
                    font-size: 14px;
                  }
                  .option-icon {
                    i {
                      color: $Siemens_Petrol;
                      position: relative;
                      top: -20px;
                      font-size: 13px;
                      cursor: pointer;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    width: 82%;
    background: $white;
    border-radius: 8px;
    .save-btn {
      border-color: $Siemens_Petrol;
      background: $Siemens_Petrol;
      color: $white;
      height: 35px;

      &:focus {
        outline: none;
        box-shadow: none;
        text-decoration: none;
      }
      .p-button-label {
        font-size: 0.9375rem;
        font-weight: 500;
      }
    }
    .cancel-btn {
      border-color: $Siemens_Petrol;
      background: $white;
      color: $Grey__Dark;
      margin: 0 20px 0 0;
      height: 35px;
      &:focus {
        outline: none;
        box-shadow: none;
        text-decoration: none;
      }
      .p-button-label {
        font-size: 0.9375rem;
        font-weight: 500;
      }
    }

    .Pagetitle {
      color: $Siemens_Petrol;
    }
    p.subtitle-passwordpolicy {
      color: $Siemens_Petrol;
    }
    hr {
      margin: 0;
      color: $Siemens_Petrol;
      height: 2px;
      width: 85%;
    }
    .p-inputnumber{
width: 30px !important;
    
&.error {


  .p-inputnumber-input{
    border-color: $Red__Light;
    box-shadow: none;
  }
}

    .p-inputnumber-input{
      width: 30px;
      height: 19px;
      padding: 0;
      text-align: center;
      font-size: 12px;
      border-radius: 4px;

     

      &:enabled {
        &:hover {
          border-color: $Siemens_Petrol;
        }

        &:focus {
          border-color: $Siemens_Petrol;
          box-shadow: none;
        }
      }
    }
  }
    input#text {
      width: 30px;
      height: 19px;
      padding: 0;
      text-align: center;
      font-size: 12px;
      border-color: $Siemens_Petrol;
      border-radius: 4px;

      &:hover {
        border-color: $Siemens_Petrol;
      }
      &:focus {
        border-color: $Siemens_Petrol;
        box-shadow: 0 0 0 0.1rem $Siemens_Petrol;
      }
    }
    .password-requirements {
      .checklist {
        .field-checkbox{
          .p-checkbox-box{
            border: 1px solid $Siemens_Petrol;
          }
        }
        label {
          color: $body-font-color;
        }
      }
    }

    .account-checklist {
      width: 100%;
      .check-list {
        width: 65%;
      }
    }
    .p-inputswitch {
      width: 45px;
      height: 20px;

      .p-inputswitch-slider {
        box-shadow: none;

      
        &::before {
          width: 1.15rem;
          height: 1.15rem;
          top: 52%;
          left: 2px;
        }
      }
    }
    .p-inputswitch-checked {
      .p-inputswitch-slider {
        background-color: $Siemens_Petrol !important;
        &::before {
          transform: translateX(1.2rem);
          width: 1.1rem;
          height: 1.1rem;
          top: 54%;
          left: 4px;
        }
      }
    }
  }
}


