.manage-inspection-reports {
    margin: 0 24px;
 
    .breadcrumb-sub-page-title{
        color: $Siemens_Petrol;
        font-size: $font-size-h4;
            font-weight: 600;
    }

    .table-container {
      height: calc(100% - 120px);
        .inspection-report-filter {
            flex: 1;
            max-width: 2%;
            margin-right: 7px;

            &.expanded {
                max-width: 18%;
            }

            .filters.expanded {
                height: 100%;
            }
        }

        .inspection-report-table {
            flex: 1;
            max-width: 98%;

            &.expanded {
                max-width: 82%;

                .main-report-groups {
                    background-color: $white;
                    height: 80vh;
                    border-radius: 8px;
                    .main-report-group {
                        .Pagetitle{
                            color: $Siemens_Petrol;
                        }
                      .report-group-list-add {
                        .add-main-report-group {
                          .add-report-group {
                            width: 70%;
                            p{
                              font-size: $font-size-h5;
                              font-weight: 400;
                              &.mandatory {
                                &::after {
                                  content: " *";
                                  color: #fe0808;
                                }
                              }
                            }
                            .add-report-group-field {
                              input#adddomain {
                                width: 100%;
                                &:hover {
                                  border-color: $Siemens_Petrol;
                                }
                                &:focus {
                                  border-color: $Siemens_Petrol;
                                  box-shadow: 0 0 0 0.1rem $Siemens_Petrol;
                                }
                                
                                &.error {
                                  border-color: $Red__Light;
                                  box-shadow: none;
                                }
                              }
                            }
                            .save-btn {
                              border-color: $Siemens_Petrol;
                              background: $Siemens_Petrol;
                              color: $white;
                              height: 35px;
                        
                              &:focus {
                                outline: none;
                                box-shadow: none;
                                text-decoration: none;
                              }
                              .p-button-label {
                                font-size: 0.9375rem;
                                font-weight: 500;
                              }
                            }
                            .cancel-btn {
                              border-color: $Siemens_Petrol;
                              background: $white;
                              color: $Grey__Dark;
                              margin: 0 20px 0 0;
                              height: 35px;
                              &:focus {
                                outline: none;
                                box-shadow: none;
                                text-decoration: none;
                              }
                              .p-button-label {
                                font-size: 0.9375rem;
                                font-weight: 500;
                              }
                            }
                          }
                        }
                        .report-group-add {
                          .new-report-group{
                            cursor: pointer;
                            background: $Siemens_Border__Light;
                            color: $Siemens_Petrol;
                            padding: 3px 6px;
                            border-radius: 3px;
                            display: flex;
                            align-items: center;
              
                            .pi{
                              font-size: 0.7rem;
                            }
                          }
                        }
                        .report-group-list{
                          .p-scrollpanel{
                            .p-scrollpanel-bar-x{
                              display: none;
                            }
                            .p-scrollpanel-wrapper{
                              .p-scrollpanel-content{
                                padding: 0;
                              }
                            }
                          }
                          .p-card{
                            .p-card-body{
                              .p-card-content{
                                padding: 0px;
                  display: flex;
                  align-items: center;
                  .icon{
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    border: 1px solid $Siemens_Petrol;
                    display: flex;
                  align-items: center;
                  justify-content: center;
                  span{
                    i{
                      font-size: 20px;
                      line-height: 40px;
                      color: $Siemens_Petrol;
                    }
                  }
                  }
                  .report-group-name{
                    width: 65%;   
                     font-size: 14px;
                  }
                  .option-icon{
                    i{
                      color: $Siemens_Petrol;
                      position: relative;
                  top: -20px;
                  font-size: 13px;
                  cursor: pointer;
                    }
                  }
                              }
                            }
                          }
                        }
                      }
                    }
                  } 

                .table-header {
                    .title {
                        color: $Siemens_Petrol;
                        font-size: $font-size-h4;
                    }
                }
                .report-template-table{

                    .p-datatable-header{

                        .caret-icon{
                            display: none;
                            
                        }
                    }
                }
                .report-configs-table{
                    .p-datatable-header{

                        .caret-icon{
                            display: none;
                            
                        }
                    }
                }
            }
            .custom-table{
              .p-datatable{
                height: calc(100% - 20px);
                .p-datatable-wrapper {
                    height: calc(100vh - 230px);
                }
            }
            }
           
        }
    }
}