.copy-move-wrapper {

    margin: 0px 24px;
    height: calc(100% - 95px);
    padding: 0;

    .breadcrumb-sub-page-title {
        color: $Siemens_Petrol;
        font-size: $font-size-h4;
        font-weight: 600;
    }

    .heading {
        background-color: $Siemens_Petrol;
        color: $white;
        font-size: $font-size-h5;
        @include border-radi(4px, 4px, 0, 0);
        padding: 0px 16px;
    }

    .form-wrapper{
                
        display: flex;
        background-color: #fff;
        padding: 8px 8px 8px 0;
        align-items: center;
        justify-content: space-between;
    
        .col-one{
    
            display: flex;
            align-items: center;
    
            .field {
    
                margin-left: 8px;

                &:nth-child(2){

                    margin-right: 16px;

                }
    
                .p-float-label {
    
                    .p-inputtext {
                        background-color: #fff;
                        min-width: 10rem;
                        padding: 2px 10px;
    
                        &:focus + label,
                        &.p-filled + label {
                            color: $Siemens_Petrol;
                            top: 0;
                            background: $white;
                            padding-left: 2px;
                        }
                    }
    
                    label {
                    font-size: $font-size-h5;
                    font-weight: 400;
                    top: 0;
                    background: #FFFFFF;
                    padding-left: 2px;
                    padding-top: 2px;
    
                    &.mandatory {
                        &::after {
                        content: " *";
                        color: #fe0808;
                        }
                    }
                    }
    
                    .p-dropdown {
                    .p-dropdown-label {
                        font-size: $font-size-h5;
                    }
    
                    &:not(.p-disabled) {
                        &:hover {
                        border-color: $Siemens_Petrol;
                        }
    
                        &.p-focus {
                        border-color: $Siemens_Petrol;
                        box-shadow: 0 0 0 0.1rem $Siemens_Petrol;
                        }
                    }
                    }
                    
                    .p-dropdown {
                    &.error {
                        border-color: $Red__Light;
                        box-shadow: none;
                    }
                    }
    
                    .p-inputtext {
                    &.error {
                        border-color: $Red__Light;
                        box-shadow: none;
                    }
    
                    &:enabled {
                        &:hover {
                        border-color: $Siemens_Petrol;
                        }
    
                        &:focus {
                        border-color: $Siemens_Petrol;
                        box-shadow: 0 0 0 0.1rem $Siemens_Petrol;
                        }
                    }
                    }
                }

            }

            .btn-col {

                display: flex;
                margin-left: 8px;
        
                .btn {

                    margin-right: 8px;
                    padding: 0px 8px;
                    height: 26px;
                    font-size: 13px;

                    &.cancel {

                        border-color: $Siemens_Petrol;
                        background: $white;
                        color: $Grey__Dark;
                    }

                    &.pre {

                        border-color: $Siemens_Petrol;
                        background: $white;
                        color: $Siemens_Petrol;

                    }

                    background: $Siemens_Petrol;
                    border-color: $Siemens_Petrol;
                    color: $white;
                    
        
                    &:focus {

                        outline: none;
                        box-shadow: none;
                        text-decoration: none;

                    }

                }

            }

        }
    
        .action-btns {
            @include display(flex);
            @include flex-alignItems(center);
            @include flex-justifyContents(space-evenly);
            background: $Siemens_Border__Light;
            color: $Siemens_Petrol;
            @include border-radius(4px);
            padding: 3px 7px 3px 15px;
            cursor: pointer;

            .button-icon {
                background: inherit;
                color: inherit;
                border: none;
                padding: 0;
                box-shadow: none;
                margin-right: 20px;
                display: flex;
                align-items: center;

                .icon {
                    width: 16px;
                    height: 16px;
                }

                .caret-icon {
                    width: 15px;
                    height: 12px;
                    position: relative;
                    left: 2px;
                    color: $Blue__Light;
                    padding: 0;

                    &:focus {
                        box-shadow: none;
                    }
                }
            }
        }
    
    }

    .custom-table{

        height: calc(100% - 105.5px);

        .p-datatable{

            .p-datatable-header{

                display: none;

            }

            .p-datatable-wrapper{

                height: calc(100% - 38px);

                .p-datatable-table{

                    .p-datatable-thead{

                        z-index: 9;

                    }

                    .p-datatable-tbody{

                        tr{

                            td{

                                button{
    
                                    z-index: 99;
                                    height:16px;
                                    width: 16px;
                                    float:left;
    
                                    &:focus {
                                        
                                        box-shadow: none;
    
                                    }

                                    &:hover{

                                        background-color: unset;

                                    }
    
                                    .pi{
    
                                        font-size: 10px;
                                        font-weight: 600;
    
                                    }
    
                                }

                            }

                            &.p-datatable-row-expansion{

                                height:auto;

                                td{

                                    padding: 0;

                                    .copy-move-expand{

                                        width: 100%;
                                        height: 100%;
    
                                        .header{
    
                                            border: 1px solid #dee2e6;
                                            padding:4px 10px;
                                            background-color:$Siemens_Border__Light;
                                            position: sticky;
    
                                        }

                                        .copy-move-scroll{

                                            height: 310px;

                                            .data-list-wrapper{

                                                width: 100%;
                                                display: flex;
    
                                                .type-list{
    
                                                    padding:8px 10px;
                                                    width: 50%;
                                                    
                                                    .item{
        
                                                        min-width: 0;
                                                        display: flex;
                                                        margin-top: 4px;
                                
                                                        h6{
                                
                                                            line-height: 20px;
                                                            font-size: $font-size-h5;
                                                            width: 150px;
                                                            overflow: hidden;
                                                            white-space: nowrap;
                                                            text-overflow: ellipsis;
                                                            margin-right: 16px;
                                                        
                                                        }
                                
                                                        span {
                                
                                                            display: block;
                                                            line-height: 20px;
                                                            overflow: hidden;
                                                            white-space: nowrap;
                                                            text-overflow: ellipsis;
                                                            font-size: $font-size-h5;
                                                            font-weight: 400;
                                                            margin-right: 8px;
                                
                                                        }
                                
                                                    }
                                
                                                }
    
                                            }
    
                                            .source-destination{
    
                                                border-top: 1px solid #dee2e6;
                                                border-left: 1px solid #dee2e6;
                                                border-bottom: 1px solid #dee2e6;
                                                margin: 16px 8px 0 8px;
                                                display: flex;
    
                                                .border-col{
    
                                                    width: 40%;
                                                    border-right: 1px solid #dee2e6;
    
                                                    .title{
    
                                                        padding:8px 12px;
                                                        border-bottom: 1px solid #dee2e6;
                                                        width: 100%;
                                                        height: 34px;
                                                        display: block;

                                                        h6{

                                                            overflow: hidden;
                                                            text-overflow: ellipsis;
                                                            white-space: nowrap;

                                                        }
    
                                                    }

                                                    .title-height{

                                                        display: block;
                                                        border-bottom: 1px solid  #dee2e6;
                                                        height: 34px;

                                                    }

                                                    &:first-child{

                                                        width: 20% !important;

                                                    }

                                                    &:nth-child(2){

                                                        .row-col{

                                                            span{

                                                                background-color: #ffffcc !important;

                                                            }

                                                        }

                                                    }
                                                    
                                                    .row-col{
        
                                                        min-width: 0;
                                                        display: flex;
                                                        padding: 4px 12px;
                                                        align-items: center;
                                
                                                        h6{
                                
                                                            line-height: 20px;
                                                            font-size: $font-size-h5;
                                                            min-width: 150px;
                                                            overflow: hidden;
                                                            white-space: nowrap;
                                                            text-overflow: ellipsis;
                                                            padding: 4px 0;
                                                        
                                                        }
                                
                                                        span {
                                
                                                            display: block;
                                                            line-height: 20px;
                                                            overflow: hidden;
                                                            white-space: nowrap;
                                                            text-overflow: ellipsis;
                                                            font-size: $font-size-h5;
                                                            font-weight: 400;
                                                            background: #ccffcc;
                                                            padding: 4px 8px;
                                
                                                        }
                                
                                                    }
                                
                                                }
    
                                            }

                                        }
    
                                    }
    
                                }

                            }

                        }

                    }

                }
                
            }

        }

    }
}