#root {
    height: 100%;
    width: 100%;

    .app-container {
        height: 100%;
        width: 100%;
        max-height: 100vh;

        .main-container {
            height: 100%;
            background: $body-bg;
            padding-left: 65px;
            max-height: 100vh;
        }
    }
    .pre-loader{

        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        z-index: 99999;
        background-color: var(--maskbg);

        
        @keyframes p-progress-spinner-color {
            100%,
            0% {
                stroke:$Siemens_Petrol;
            }
            40% {
                stroke:$Siemens_Petrol;
            }
            66% {
                stroke:$Siemens_Petrol;
            }
            80%,
            90% {
                stroke:$Siemens_Petrol;
            }
        }

    }
}