.display-settings {
  .p-dialog-header {
    padding: 0.5rem 1rem;

    .p-dialog-title {
      font-size: $font-size-h4;
      color: $Siemens_Petrol;
      font-weight: 600;
    }

    .p-dialog-header-icons {
      .p-dialog-header-close {
        color: $Siemens_Petrol;

        &:focus {
          box-shadow: none;
        }
        .taskscompletion-table{
            .p-datatable-header{
                display: none;
            }
        }

        .filter-field{
            .left-field{
                width: 350px;

                .field-left{
                    .dropdown-field{
                        width: 200px;
                    }
                    .value-field{
                        width: calc(100% - 170px);
                    }
                }
            }
        }
      }
    }
  }

  .p-dialog-content {
    padding: 0 1rem 0.5rem 1rem;

    .filter-field{

      .left-field{

        .field-left{

          .dropdown-field{
            width: 50%;

          }
        }
        
      }
    }

    .content-box {
      .table-columns {
        .p-datatable {
          .p-datatable-wrapper {
            .p-datatable-table {
              thead.p-datatable-thead {
                tr {
                  th {
                    padding: 0.3rem 0.5rem;
                    background: $Siemens_Border__Light;
                    border: none;
                    font-size: $font-size-h5;
                    font-weight: 500;

                    .p-column-header-content {
                      .p-checkbox {
                        .p-checkbox-box {
                          width: 20px;
                          height: 20px;
                          border-radius: 2px;
                          border-color: $Siemens_Petrol;

                          &.p-focus {
                            box-shadow: none;
                          }

                          &.p-highlight {
                            background: $Siemens_Petrol;
                          }

                          &:hover {
                            border-color: $Siemens_Petrol;
                          }
                        }
                      }
                    }
                  }
                }
              }

              tbody.p-datatable-tbody {
                tr {
                  &.p-highlight {
                    color: $Siemens_Petrol;
                  }

                  td {
                    padding: 0.3rem 0.5rem;
                    background: light-shade($Grey_Medium, 75%);
                    border-color: $Siemens_Petrol;
                    font-size: $font-size-h5;

                    .p-checkbox {
                      .p-checkbox-box {
                        width: 20px;
                        height: 20px;
                        border-radius: 2px;
                        border-color: $Siemens_Petrol;

                        &.p-focus {
                          box-shadow: none;
                        }

                        &.p-highlight {
                          border-color: transparent;
                          background: $Siemens_Petrol;
                        }

                        &:hover {
                          border-color: $Siemens_Petrol;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .ar-report-viewer {
    height: 85% !important;
  }
  .report-export-main {
    justify-content: space-between;

    .report-field {
      width: 300px;
    }
    .report-buttons-main {
      justify-content: flex-end;
      align-items: center;

      .report-btn {
        
        cursor: pointer;
        background: $Siemens_Petrol;
        color: $white;
        box-shadow: 0px 4px 6px rgb(0 0 0 / 20%);
        border-radius: 8px;
        font-size: $font-size-h5;
        padding: 4px 16px;
        font-weight: 500;
        border-color: transparent;

        &:hover {
          background: $Siemens_Petrol;
          color: white;
        }
      }
      .export-report-buttons {
        padding: 8px 10px;
        border: 2px solid $Siemens_Petrol;
        border-radius: 5px;

        .list-group {
          align-items: center;
        }
      }
    }
  }

  .footer {
    padding-top: 1rem;

    button {
      margin: 0 1rem 0 0;
      height: 35px;

      &:focus {
        box-shadow: none;
      }

      &:last-child {
        margin: 0;
      }

      &.cancel-btn {
        border-color: $Siemens_Petrol;
        background: $white;
        color: $Grey__Dark;
      }

      &.submit-btn {
        background: $Siemens_Petrol;
        border-color: $Siemens_Petrol;
        color: $white;
        padding: 0 35px;
      }

      .p-button-label {
        font-size: $font-size-h4;
        font-weight: 500;
      }
    }
  }
}

.common_display_settings{

    height: 100%;

    .p-dialog-content{

        height: calc(100% - 50px);

        .content-box{

            height: 100%;

            .table-columns{

                height: calc(100% - 51px);
                overflow: auto;

            }

        }

    }

}