.inspection-questions{

    &.pre-loader {

        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        z-index: 9999;
        background-color: var(--maskbg);
    
    
        @keyframes p-progress-spinner-color {
    
            100%,
            0% {
                stroke: $Siemens_Petrol;
            }
    
            40% {
                stroke: $Siemens_Petrol;
            }
    
            66% {
                stroke: $Siemens_Petrol;
            }
    
            80%,
            90% {
                stroke: $Siemens_Petrol;
            }
        }
    
    }

}

.inspection-questions {
    height:100%;  
    display: flex;
    padding: 0 4px 0 16px;

    .tree-wrapper {
        width: 18%;
        padding: 0;
        height: 100%;
        background: #ffffff;
        color: #495057;
        border-radius: 6px;

        .select-box {

            margin-top: 8px;

            .action-section {
                width: 100%;
                display: flex;
                justify-content: space-between;

                .field {
                    width: 80%;
                    
                    .p-dropdown {
                        .p-dropdown-label {
                            padding: 0.5rem;
                            font-size: $font-size-h5;
                        }

                        &:not(.p-disabled) {
                            &:hover {
                                border-color: $Siemens_Petrol;
                            }

                            &.p-focus {
                                border-color: $Siemens_Petrol;
                                box-shadow: 0 0 0 0.1rem $Siemens_Petrol;
                            }
                        }
                    }

                    .p-calendar,
                    .p-dropdown,
                    .p-inputnumber {
                        &.p-inputwrapper-focus + label,
                        &.p-inputwrapper-filled + label {
                            color: $Siemens_Petrol;
                            top: 0;
                            background: $white;
                            padding-left: 2px;
                        }

                        &.p-inputwrapper-filled + label {
                            color: $Siemens_Petrol;
                        }
                    }

                    .p-dropdown {
                        &.error {
                            border-color: $Red__Light;
                            box-shadow: none;
                        }
                    }

                    .p-float-label {
                        font-size: $font-size-h5;

                        .p-inputtext {
                            &.error {
                                border-color: $Red__Light;
                                box-shadow: none;
                            }

                            &:enabled {
                                &:hover {
                                    border-color: $Siemens_Petrol;
                                }

                                &:focus {
                                    border-color: $Siemens_Petrol;
                                    box-shadow: 0 0 0 0.1rem $Siemens_Petrol;
                                }
                            }
                        }
                    }
                }

                .icon-fields {
                    justify-content: flex-end;
                    align-items: center;
                    // margin-left: -16px;

                    .field {
                        margin-bottom: 0px;
                        .action-btns {
                            @include display(flex);
                            @include flex-alignItems(center);
                            @include flex-justifyContents(space-between);
                            @include border-radius(4px);
                            cursor: pointer;
                            height: 16px;
                            width: 32px;
                            padding: 0 10px;
                            background: $Siemens_Border__Light;
                            color: $Siemens_Petrol;

                            .button-icon {
                                background: inherit;
                                color: inherit;
                                border: none;
                                padding: 0;
                                box-shadow: none;

                                .icon {
                                    width: 16px;
                                    height: 16px;
                                }
                            }
                        }
                    }
                }
            }
        }

        .if-border{
            height: calc(100% - 74px);
            border: 1px solid #dee2e6;
            background: #ffffff;
            color: #495057;
            border-radius: 6px;
            margin-top: 16px;

            .header {
                display: flex;
                padding: 12px 8px 8px 8px;
                justify-content: space-between;
    
                svg {
                    color: $Siemens_Petrol;
                    cursor: pointer;
                }
            }
    
            .treeview-scroll {
                height: calc(100% - 44px);
                margin-right: 8px;
                margin-left: 8px;
    
                .p-tree {
                    height: 100%;
                    border: none;
                    padding: 0;
    
                    .p-tree-container {
                        .p-treenode {
                            .p-treenode-content {
                                padding: 4px;
    
                                &.p-highlight {
                                    background-color: #ccebeb;
                                    color: #495057;
                                }
    
                                &:hover {
                                    background-color: #ccebeb;
                                }
    
                                &:focus {
                                    box-shadow: unset;
                                }
    
                                .p-tree-toggler {
                                    height: 1rem;
                                    width: 1rem;
                                    margin-right: 16px;
                                    color: #495057;
    
                                    &:hover {
                                        background-color: unset;
                                        color: $Siemens_Petrol;
                                    }
    
                                    &:focus {
                                        box-shadow: unset;
                                    }
    
                                    .pi {
                                        font-size: 12px;
                                    }
                                }
    
                                .p-treenode-label {
                                    font-size: 14px;
                                    font-weight: 300;
                                    max-width: 75%;   
                                    word-wrap: break-word;
                                }
                            }
                        }
                    }
                }
            }
        }
        
    }

    .questions-wrap{

        background-color: #fff;
        border-radius: 5px;
        width: 82%;
        height: 100%;

        &.expand{

            width: 100%;
    
        }

        .inspection-forms-wrapper{

            margin-left: 0 !important;
            height: calc(100% - 90px);

        }

        .footer-btn{

            padding-left: 10px;
    
            .field-checkbox{

                margin-bottom: 16px;
    
                label{
    
                    color: #495057;
                    font-size: $font-size-h5;
                    font-weight: 600;
    
                }
    
            }
    
            button {

                height: 40px;

                &:focus {

                    box-shadow: 0 0 0 0.1rem $Siemens_Petrol;

                }

                &.save-btn {

                    background: $Siemens_Petrol;
                    border-color: $Siemens_Petrol;
                    color: $white;
                    
                    span{

                        font-size: $font-size-h4;
                        font-weight: 500;

                    }

                }

            }

        }
        input[type="number"] {
            -moz-appearance: textfield;
            
            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
        }
    }
    
}


