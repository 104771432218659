.dashboard-container {
    height: calc(100% - 92px);
    width: calc(100% - 45px);
    margin: 0 25px 0 20px;
    padding-bottom: 8px;

    .recharts-wrapper {
        svg {

            height: auto;
            width: auto;
        }
    }

    .p-tabview {
        height: 100%;

        .p-tabview-nav-container {
            .p-tabview-nav {
                li {
                    .p-tabview-nav-link {
                        padding: 12px 16px;

                        &:focus {
                            box-shadow: none;
                        }
                    }

                    &.p-tabview-selected {
                        .p-tabview-nav-link {
                            border-color: $Siemens_Petrol;
                            color: $Siemens_Petrol;
                        }
                    }

                    &.p-tabview-ink-bar {
                        background-color: $Siemens_Petrol;
                    }
                }
            }
        }

        .p-tabview-panels {
            height: calc(100% - 42px);
            padding:0 8px;

            .p-tabview-panel {
                height: 100%;

                &.inspection-summary-tab {
                    @import "./TabList/InspectionSummary/InspectionSummary.scss";
                }

                &.performance-indicator-tab {
                    @import "./TabList/PerformanceIndicator/PerformanceIndicator.scss";
                }

                .recharts-tooltip-wrapper {
                    .custom-tooltip {                                               
                        background-color: $white;   
                        padding: 10px;   
                        box-shadow: 0 3px 3px rgba(0,0,0,0.2);
                        margin-bottom: 0px;
                        font-size: 12px;
                        .tooltip-title {
                            font-weight: 600;
                            color: $Grey__Dark;
                            margin: 0px;
                            margin-bottom: 10px;
                            padding-top: -5px;
                        }
                    }
                }
            }
        }
    }
}