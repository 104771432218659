.custom-table {

    height: 100%;


    .heading {
        background-color: $Siemens_Petrol;
        color: $white;
        font-size: $font-size-h5;
        @include border-radi(4px, 4px, 0, 0);
        padding: 0px 16px;
    }

    .p-datatable {
        /** table global **/
        width: 100% !important;
        height: 100%;

        .p-inputtext:enabled:focus {
            border-color: $Siemens_Petrol;
            box-shadow: 0 0 0 0.1px $Siemens_Petrol;
        }

        .p-inputtext:enabled:hover {
            border-color: $Siemens_Petrol;
        }

    // selection checkbox
    .p-checkbox {
        width: 19px;
        height: 18px;
        .p-checkbox-box {
            border: 1px solid $Siemens_Petrol;
            width: 19px;
            height: 18px;
            border-radius: 0;
            box-shadow: none;

            &.p-highlight {
                background-color: $Siemens_Petrol;
                border: none;
            }

            .p-checkbox-icon {
                color: $white;
            }
        }

        &:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover,
        .p-checkbox-box.p-highlight:focus
        {
            background-color: $Siemens_Petrol;
            box-shadow: none;
        }
    }

        /** table header **/
        .p-datatable-header {
            padding: 0.5rem;

            .search-section {
                .table-search {
                    .p-inputtext {
                        background: $Grey_Medium;
                        min-width: 18rem;
                        padding: 2px 10px;
                        font-size: $font-size-h5;
                        border: 1px solid #D9EEEE;

                        &:focus {
                            border-color: $Siemens_Petrol;
                            box-shadow: 0 0 0 0.1px $Siemens_Petrol;
                        }
                    }

                    .pi.pi-search {
                        left: unset;
                        right: 0.75rem;
                        color: $Siemens_Petrol;
                        font-weight: 600;
                    }
                }

                .country-item {
                    border: 1px solid $Border_Light;
                    cursor: pointer;

                    img {
                        width: 22px;
                    }

                    span {
                        font-size: $font-size-h5;
                        font-weight: 500;
                        color: inherit;
                    }

                    &.active {
                        border: 3px solid $Siemens_Petrol;

                        span {
                            color: $Siemens_Petrol;
                        }
                    }
                }

                .attributes-dropdowns {
                    .p-float-label {
                        font-size: $font-size-h5;
                        width: 15rem;

                        .p-dropdown {
                            height: 26px;

                            .p-dropdown-label.p-inputtext {
                                padding: 0.2rem 0.5rem;
                                font-size: $font-size-h5;
                            }

                            &.p-inputwrapper-focus+label,
                            &.p-inputwrapper-filled+label {
                                color: $Siemens_Petrol;
                                top: 0;
                                background: $white;
                                padding-left: 2px;
                            }

                            &.p-inputwrapper-filled+label {
                                color: $Siemens_Petrol;
                            }

                            &.error {
                                border-color: $Red__Light;
                                box-shadow: none;
                            }

                            &:hover {
                                border-color: $Siemens_Petrol;
                            }

                            &:not(.p-disabled).p-focus {
                                border-color: $Siemens_Petrol;
                                box-shadow: 0 0 0 0.1px $Siemens_Petrol;
                            }
                        }
                    }
                }
            }

            .table-config {
                @include display(flex);
                @include flex-alignItems(center);

                &.custom-align {
                    position: relative;
                    top: 32px;
                    right: 12px;
                }

                .font-adjust-section {
                    background: $Siemens_Border__Light;
                    @include border-radius(20px);
                    padding: 0px 15px;
                    margin-right: 30px;

                    .text {
                        margin-right: 12px;
                        cursor: pointer;

                        &:last-child {
                            margin-right: 0;
                        }

                        &.small {
                            color: $Grey__Dark;
                            font-size: $font-size-h6;
                        }

                        &.medium {
                            color: $Grey__Dark;
                            font-size: $font-size-h5;
                        }

                        &.large {
                            color: $Grey__Dark;
                            font-size: $font-size-h4;
                        }
                        &.active{
                            color: $Siemens_Petrol;
                          }
                    }
                }

                .action-btns {
                    @include display(flex);
                    @include flex-alignItems(center);
                    @include flex-justifyContents(space-evenly);
                    background: $Siemens_Border__Light;
                    color: $Siemens_Petrol;
                    @include border-radius(4px);
                    padding: 3px 7px 3px 15px;
                    cursor: pointer;

                    .button-icon {
                        background: inherit;
                        color: inherit;
                        border: none;
                        padding: 0;
                        box-shadow: none;
                        margin-right: 20px;

                        .icon {
                            width: 16px;
                            height: 16px;
                        }

                        .caret-icon {
                            width: 15px;
                            height: 12px;
                            position: relative;
                            left: 2px;
                            color: $Blue__Light;
                            padding: 0;

                            &:focus {
                                box-shadow: none;
                            }
                        }
                    }
                }
            }
        }

        /** table body **/
        .p-datatable-wrapper {
            height: calc(100% - 72px);
            background-color: #fff;

            table.p-datatable-table {
                min-width: 100% !important;

                tr {
                    &:hover {
                        td {
                            &.p-frozen-column {
                                button.p-row-editor-init {
                                    visibility: visible;
                                    word-break: break-all;
                                }
                            }
                        }
                    }
                }

                thead.p-datatable-thead {
                    tr {
                        th {
                            background: #D9EEEE;
                            color: $Grey__Dark;
                            font-weight: 600;
                            font-size: $font-size-h5;
                            padding: 0.2rem 0.5rem;

                            &.p-frozen-column {
                                display: flex;
                                flex: 0 0 60px;
                            }

                            &:focus {
                                box-shadow: inset 0 0 0 0.1rem $Siemens_Petrol;
                            }

                            .p-column-header-content {
                                .p-sortable-column-icon {
                                    color: $Siemens_Petrol;
                                    font-size: 12px;
                                }
                            }
                        }
                    }
                }

                tbody {
                    color: $Grey__Dark;
                    font-size: $font-size-h5;
                  
                    tr {
                        height: auto;
                    

                        &.p-highlight {
                            color: $Siemens_Petrol;
                            outline: none;

                            td {
                                background: #EEF2FF !important;
                                word-break: break-all;
                            }
                        }

                        &.p-datatable-row-expansion {
                            width: 100%;
                            height: 220px;

                            td {
                                width: 100%;
                                @include flex-alignItems(flex-start);
                                padding: 10px;

                                .expanded-row-container {
                                    width: 100%;
                                    @include display(flex);
                                    @include flex-direction(column);
                                    @include border-radius(8px);
                                    background: $white;
                                    box-shadow: 0px 4px 6px rgb(204 235 235 / 50%);

                                    .header-section {
                                        @include display(flex);
                                        @include flex-alignItems(center);
                                        @include flex-justifyContents(space-between);

                                        background: $Siemens_Petrol;
                                        border-radius: 8px 8px 0px 0px;
                                        padding: 5px 16px;

                                        .info {
                                            @include display(flex);
                                            @include flex-alignItems(center);

                                            .box {
                                                margin-right: 30px;

                                                .title {
                                                    font-weight: 400;
                                                    font-size: $font-size-h5;
                                                    color: $white;
                                                }

                                                .value {
                                                    font-weight: 700;
                                                    font-size: $font-size-h5;
                                                    color: $white;
                                                }
                                            }
                                        }

                                        .close-btn {
                                            .pi.pi-times {
                                                color: $white;
                                                cursor: pointer;
                                            }
                                        }
                                    }

                                    .main-section {
                                        @include display(flex);
                                        @include flex-alignItems(center);
                                        @include flex-justifyContents(space-between);
                                        padding: 5px 24px;

                                        .knob-wrapper {
                                            width: 128px;
                                            height: 128px;
                                            background: #EBF0F5;
                                            border-radius: 50%;
                                            border: 1px solid #EBF0F5;

                                            .knob-value-wrapper {
                                                width: 100px;
                                                height: 100px;
                                                border-radius: 50%;
                                                background: $white;
                                                margin-top: 14px;
                                                margin-left: 14px;
                                                @include display(flex);
                                                @include flex-alignItems(center);
                                                @include flex-justifyContents(center);
                                                position: absolute;
                                                z-index: 99;

                                                .knob-value {
                                                    width: 80px;
                                                    height: 80px;
                                                    border-radius: 50%;
                                                    background: linear-gradient(128.22deg, #009999 -24.39%, #24F1C1 115.46%);
                                                    color: $white;
                                                    font-weight: 700;
                                                    font-size: $font-size-h5;
                                                    @include display(flex);
                                                    @include flex-alignItems(center);
                                                    @include flex-justifyContents(center);
                                                    @include flex-direction(column);
                                                }
                                            }

                                            .knob {

                                                .mask,
                                                .fill {
                                                    width: 128px;
                                                    height: 128px;
                                                    position: absolute;
                                                    border-radius: 50%;
                                                }

                                                .mask {
                                                    clip: rect(0px, 150px, 150px, 75px);

                                                    &.full,
                                                    .fill {
                                                        animation: fill ease-in-out 3s;
                                                        transform: rotate(135deg);
                                                    }

                                                    .fill {
                                                        clip: rect(0px, 75px, 150px, 0px);
                                                        background: linear-gradient(128.22deg, #009999 -24.39%, #24F1C1 115.46%);
                                                    }
                                                }
                                            }

                                            @keyframes fill {
                                                0% {
                                                    transform: rotate(0deg);
                                                }

                                                100% {
                                                    transform: rotate(135deg);
                                                }
                                            }
                                        }

                                        .table-card {
                                            height: 120px;
                                            border: 1px solid $Siemens_Border__Light;
                                            box-shadow: 0px 4px 6px rgb(204 235 235 / 50%);
                                            border-radius: 8px;
                                            @include display(flex);

                                            &.tests-equipments {
                                                width: 296px;
                                                margin-left: 51px;

                                                .content {
                                                    padding: 6px 12px;
                                                }
                                            }

                                            &.tech-plan {
                                                width: 355px;
                                                margin-left: 101px;

                                                .content {
                                                    padding: 6px 12px;
                                                }
                                            }

                                            &.user-actions {
                                                width: 270px;
                                                background: $Slate_Grey;
                                                margin-left: 151px;

                                                .content {
                                                    padding: 0px 12px;
                                                    width: 100%;
                                                }
                                            }

                                            .border {
                                                border-radius: 8px 0px;
                                                width: 20px;
                                                height: 120px;

                                                &.yellow-border {
                                                    background: $Yellow_Medium;
                                                }

                                                &.green-border {
                                                    background: $Green_Medium;
                                                }
                                            }

                                            .content {
                                                .title {
                                                    font-weight: 400;
                                                    font-size: $font-size-h5;
                                                    color: $Grey__Dark;
                                                }

                                                .value {
                                                    font-weight: 700;
                                                    font-size: $font-size-h5;

                                                    &.success {
                                                        color: $Siemens_Petrol;
                                                    }

                                                    &.error {
                                                        color: $Red_Medium;
                                                    }

                                                    &.warning {
                                                        color: $Yellow_Medium;
                                                    }
                                                }

                                                svg {
                                                    width: 24px;
                                                    height: 24px;
                                                    color: $Siemens_Petrol;
                                                    cursor: pointer;
                                                }

                                                .icon-title {
                                                    font-weight: 400;
                                                    font-size: $font-size-h5;
                                                    color: $Grey__Dark;
                                                    margin-left: 10px;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        &:focus {
                            outline: none;
                        }

                        td {
                            background: $white;
                            word-break: break-all;
                            height: auto;

                            &.p-editable-column {
                                background: $white;

                                &.p-cell-editing {
                                    //overflow: visible;
                                    position: relative;

                                    .p-inputtext {
                                        padding: 0 0.2rem;
                                        font-size: $font-size-h5;
                                        width: 100%;

                                        &.error {
                                            border-color: $Red__Light;
                                            box-shadow: none;
                                        }
                                    }

                                

                                    .p-dropdown {
                                        width: 100%;

                                        .p-dropdown-label.p-inputtext {
                                            padding: 0 0.2rem;
                                            font-size: $font-size-h5;
                                        }

                                        .p-dropdown-trigger{

                                            width: 2rem;

                                            .pi{

                                               font-size: 12px;

                                            }

                                        }

                                        &:hover {
                                            border-color: $Siemens_Petrol;
                                        }

                                        &:not(.p-disabled).p-focus {
                                            border-color: $Siemens_Petrol;
                                            box-shadow: 0 0 0 0.1px $Siemens_Petrol;
                                        }
                                    }
                                }
                            }

                            &.p-frozen-column {
                                display: flex;
                                justify-content: center;
                                flex: 0 0 60px;
                                padding: 0.5rem 0.2rem;
                                z-index: 999;

                                button {
                                    width: 1rem;

                                    &:hover {
                                        background: unset;
                                    }

                                    &:focus {
                                        box-shadow: none;
                                    }

                                    &.p-row-editor-init {
                                        visibility: hidden;
                                    }

                                    &.p-row-editor-save {
                                        margin-right: 0.7rem;

                                        .p-row-editor-save-icon.pi-check {
                                            &::before {
                                                content: "\e95b";
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        /** table paginator **/
        .p-paginator {
            color: $Grey__Dark;
            padding: 0;
            @include flex-justifyContents(right);

            .p-paginator-prev,
            .p-paginator-next {
                color: $Siemens_Petrol;
                font-size: $font-size-h5;
                height: 2rem;

                .action-btn {
                    padding: 0 1rem !important;
                }

                &:disabled {
                    color: $Siemens_Stone__Dark;
                }
            }

            .p-paginator-last {
                color: $Siemens_Petrol;
                font-size: $font-size-h5;
                min-width: 2rem;
                height: 2rem;
            }

            .p-paginator-first {
                color: $Siemens_Petrol;
                font-size: $font-size-h5;
                min-width: 2rem;
                height: 2rem;
            }

            .p-paginator-pages {
                .p-paginator-page {
                    color: $Siemens_Petrol;
                    font-size: $font-size-h5;
                    min-width: 2rem;
                    height: 2rem;
                }
            }

            .p-dropdown {
                background: $Grey_Medium;
                height: auto;

                .p-dropdown-label {
                    font-size: $font-size-h5;
                    padding: 2px 0 2px 2px;
                }

                .p-dropdown-trigger {
                    width: 2rem;

                    .p-dropdown-trigger-icon {
                        font-size: 12px;
                    }
                }

                &:not(.p-disabled) {

                    &:hover {
                        border-color: $Siemens_Petrol;
                    }

                    &.p-focus {
                        border-color: $Siemens_Petrol;
                        box-shadow: 0 0 0 0.1rem $Siemens_Petrol;
                    }
                }
            }

            .current-page {
                font-size: $font-size-h5;

                .p-inputtext {
                    font-size: $font-size-h5;
                    padding: 2px;
                }
            }
        }
    }
}