.react-document-viewer {

    div,
    p {
        color: $Siemens_Petrol;
    }

    #header-bar {
        #doc-nav {
            .GHlHp {
                width: 26px;
                height: 26px;

                svg {
                    width: 15px;
                    height: 15px;
                }

                &:not(:disabled) {
                    background-color: $Siemens_Petrol;
                }

                &:disabled {
                    background-color: $Grey_Disabled;
                }
            }
        }
    }

    #proxy-renderer {
        #pdf-controls {

            button,
            a {
                svg {
                    width: 20px;
                    height: 20px;

                    path {
                        fill: $Siemens_Petrol;
                    }
                }

                &:not(:disabled) {
                    svg {

                        path {
                            fill: $Siemens_Petrol;
                        }
                    }
                }

                &:disabled {
                    svg {

                        path {
                            fill: $Grey_Disabled;
                        }
                    }
                }
            }

            #pdf-download {
                display: none;
            }

            #pdf-pagination {
                button {
                    svg {
                        width: 15px;
                        height: 15px;
                    }
                }
            }
        }
    }
}

.file-content {
    height: 100%;
    width:100%;
}

.nav-button-container{
    text-align: center;
}

.nav-btn {
    margin-right: 5px;
    width: 40px;
    background: #099;
    border-color: #099;
    color: #fff;
}

.nav-btn:disabled {
    margin-right: 5px;
    width: 40px;
    background: rgb(203, 205, 205);
    border-color: #099;
    color: #fff;
    cursor: not-allowed;
}