.field-header{

    display: flex;
    padding: 8px 0px 0px 8px;

    span{

        .p-radiobutton{

            margin-right: 8px;

        }

        label{

            font-size: 13px;
            font-weight: 400;
            margin-right: 24px;

        }

    }

}

.checkbox-section{

    span{

        .p-checkbox, .p-radiobutton{

            margin-right: 8px;

        }

        label{

            font-size: 13px;
            font-weight: 400;
            margin-right: 16px;
            color: #495057;

        }

    }
    
}

.section-col{

    height: 100%;
    margin-top: 8px;

    .sub-page-title{
        color: $Siemens_Petrol;
        font-size: $font-size-h5;
        font-weight: 600;
        color: #495057;
    }

    .field {
        padding: 0 8px;
    }

    .field-Value-List{

        //max-height: 300px;
        background-color: #fff;
        border-radius: 5px;
        padding: 0px 10px 10px 10px;

        .main-wrapper{

            .box{

                border: 1px solid $Siemens_Petrol;
                border-radius: 5px;
                overflow-y: auto;
                min-height: 37px;
                max-height: 200px;

                .data-col{
    
                    display: flex;
                    padding-top: 8px;
                    align-items: center;
                    justify-content: space-between;
        
                    .name{
    
                        display: flex;
                        align-items:center;
                        width: 100%;
    
                        span{
    
                            margin-left: 8px;
                            font-size: 14px;
    
                        }
    
                    }
        
                    .btns{
        
                        align-items: center;
                        padding-left: 32px;
        
                        .action-btns {
                            
                            @include display(flex);
                            @include flex-alignItems(center);
                            @include flex-justifyContents(space-evenly);
                            background: $Siemens_Border__Light;
                            color: $Siemens_Petrol;
                            @include border-radius(4px);
                            padding: 9px 7px 9px 15px;
                            cursor: pointer;
            
                            .button-icon {
                                background: inherit;
                                color: inherit;
                                border: none;
                                padding: 0;
                                box-shadow: none;
                                margin-right: 20px;
            
                                .icon {
                                    width: 16px;
                                    height: 16px;
                                }
                            
                            }
        
                        }
        
                    }
                
                }

            }

            .box{

                border: 1px solid $Siemens_Petrol;
                border-radius: 5px;
                overflow-y: auto;
                min-height: 37px;
                max-height: 200px;

                .p-datatable{
                    
                    .p-datatable-thead{

                        tr{

                            th{

                                color: $body-font-color;
                                font-weight: 500;
                                font-size:$font-size-h5;
                                background-color: #CCEBEB;
                                padding:4px 8px;
                                position: sticky;
                                top: 0;

                            }

                        }

                    }

                    .p-datatable-tbody{

                        height: calc(100% - 50px);
                        overflow: scroll;

                        tr{

                            td{

                                padding: 0 8px;
                                font-size: 13px;

                                .p-inputtext {
                                    padding: 4px 4px 4px 8px;
                                }

                                .btn-wrap{
                                
                                    @include display(flex);
                                    @include flex-alignItems(center);
                                    @include flex-justifyContents(space-evenly);
                                    color: $Siemens_Petrol;
                                    @include border-radius(4px);
                                    cursor: pointer;
                                    padding: 8px 0px;
                    
                                    .button-icon {
                                        background: $Siemens_Border__Light;
                                        color: inherit;
                                        border: none;
                                        box-shadow: none;
                                        margin-left: 10px;
                                        justify-content: center;
                                        padding: 4px 4px;
                    
                                        .icon {
                                            width: 16px;
                                            height: 16px;
                                        }
                                    
                                    }
                                }

                            }

                        }

                    }

                }

            }
    
        }

    }

}