.report-view{

    padding: 0px 24px 16px 24px;
    height: calc(100% - 84px);

    .report-view-wrapper{

        background: #fff;
        height: 100%;
        padding: 16px 24px 24px 24px;
        overflow: auto;

        .main-title{

            background: #fff;
            font-size: 16px;
            font-weight: 600;
            margin: 8px;
            color: $Siemens_Petrol;
    
        }

        .reports-tile{

            min-width: 290px;
            height: 90px;
            background: #FFFFFF;
            box-shadow: 0px 7px 16px #ccebeb;
            border-radius: 8px;
            margin: 8px 32px 32px 8px;
            float: left;
            cursor: pointer;

            .report-view-data{

                padding: 16px;
                display: flex;
                align-items: center;

                .col-img{

                    margin-right: 16px;
                    height: 60px;
                    width: 60px;
                    background: #CCEBEB;
                    border: 2px solid #009999;
                    border-radius: 50%;
                    align-items: center;
                    justify-content: center;
                    display: flex;

                    img{

                        width: 38px;
                        height: 38px;

                    }

                }
            }

        }
    }

}