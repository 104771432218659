.search-container {
    @include spacing();
    margin: 0px 10px 0px 10px;
    background: transparent;
    height: calc(100% - 81px);

    .administration-search-container {

        height: 100%;

        .administration-search-leftsidebar {

            max-width: 18%;
            flex: 1 1;
            margin-right: 7px;

            .administration-search-listname {

                background: #FFFFFF;
                padding:12px;
                width: 100%;
                color: $Grey__Dark;
                border-radius: 8px;

                .main {

                    ul.list-items {
                        padding: 0 0.5rem;

                        li{

                            font-size: $font-size-h5;
                             margin-top: 0.5rem;
                            cursor: pointer;

                            &.active,
                            &:hover {
                                background: $Siemens_Border__Light;
                                color: $Siemens_Petrol;
                                    }

                        }
                    }
                }
            }
        }
    }

   .administration-search-expanded {

        max-width: 81%;
        flex: 1 1;
        background-color: #fff;
        border-radius: 8px;
        padding-top: 21px;
        height: 100%;

        .search-administration-field {

            display: flex;
            height: 100%;
            padding-left: 18px;

            .main-search {
                position: relative;
                width: 100%;

                .p-autocomplete-panel {
                    left: 0 !important;
                    top: 50px !important;
                    max-height: calc(100% - 62px) !important;
                
                    ul.p-autocomplete-items {
                        width: 100%;
                        padding: 10px 14px;
                
                        li.p-autocomplete-item {
                            padding: 0px 0px 0px 0px !important;
                
                            .data-item {
                                height: 100%;
                                border-bottom: 1px solid rgba(78, 181, 183, 0.4784313725);
                                padding: 4px 4px 8px 8px !important;
                            }
                        }
                
                    }
                
                }

                .p-autocomplete {
                    height: 40px;
                    width: 100%;

                    &:after {

                        position: absolute;
                        content: "";
                        top: 6px;
                        left: 20px;
                        border-right: 2px solid $Siemens_Border__Light;
                        height: 70%;
                    }

                    input.p-inputtext {
                        background: #f0f4f7;
                        padding: 4px 25px;
                        border: 0.5px solid #4eb5b726;
                        font-weight: 400;
                        font-size: $font-size-base;
                        color: $Blue__Light;
                        min-width: 35rem;

                        &:focus {
                            box-shadow: none;
                        }
                    }

                    button.p-button {
                        left: unset;
                        background: #f0f4f7;
                        right: 0.75rem;
                        color: $Blue__Dark;
                        font-weight: 600;
                        border-left: 0px solid !important;
                        border: 0.5px solid rgba(78, 181, 183, 0.1490196078);
                    }
                }
            }
        }


    }
}

