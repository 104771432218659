.inspection-tasks {
  margin: 0 25px 0 20px;
  height: calc(100% - 97px);

  .inspection-tasks-filter {
    flex: 1;
    max-width: 2%;
    margin-right: 7px;
    height: 100%;

    &.expanded {
      max-width: 25%;
      height: 100%;
    }

    .filters.expanded {
      height: 100%;

      .inspection-filter-container{

        height: 100%;

        .customer-list-container{

          height: calc(100% - 146px);
          overflow: auto;
          overflow-x: hidden;

        }

      }
    }
  }

  .inspection-tasks-table {
    flex: 1;
    max-width: 98%;
    height: 100%;

    &.expanded {
      max-width: 75%;
    }
    &.f-small {

      .custom-table{

        .p-datatable{

          .p-datatable-wrapper{

            .p-datatable-table{
              .p-datatable-tbody{
                  font-size: 0.7325rem;
              }

            }
          }
        }
      }
      
      }
      &.f-medium {
     
      .custom-table{

        .p-datatable{

          .p-datatable-wrapper{

            .p-datatable-table{
              .p-datatable-tbody{
                font-size: 0.8125rem;
              }

            }
          }
        }
      }
      
      }
      &.f-large {
     
      .custom-table{

        .p-datatable{

          .p-datatable-wrapper{

            .p-datatable-table{
              .p-datatable-tbody{
                font-size: 1.000rem;
              }

            }
          }
        }
      }
      
      }
      
      .system-icon {
        display: inline-block;
        width: 13px;
        height: 13px;
        border-radius: 50%;
        background-color: $Yellow__Light;
        text-align: center;
        line-height: 30px;
        margin-right: 5px;
        }
       

    .custom-table{

       height: 100%;

      .custom-header {
        background: $Siemens_Petrol;
        border-radius: 8px 8px 0px 0px;
        padding: 0px 16px;
  
        .box {
          @include display(flex);
          @include flex-direction(column);
          @include flex-justifyContents(center);
  
          &.w-20 {
            width: 20%;
          }
  
          &.w-22 {
            width: 22%;
          }
  
          &.w-30 {
            width: 30%;
          }
  
          &.w-28 {
            width: 28%;
          }
        }
  
        .title {
          color: $white;
          font-size: $font-size-h5;
        }
  
        .sub-title {
          color: $white;
          font-size: $font-size-h5;
          font-weight: bold;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
  
        .actions-section {
          background: $Siemens_Border__Light;
          @include border-radius(4px);
          padding: 5px;
          width: 250px;
  
          .status {
            @include display(flex);
            @include flex-alignItems(center);
            @include flex-justifyContents(space-between);
  
            .field-checkbox {
              label {
                margin-left: 10px;
                color: $Grey__Dark;
                font-size: $font-size-h5;
              }
            }
          }
  
          .months-list {
            .p-dropdown {
              width: 100%;
              border: 1px solid $Siemens_Petrol;
              border-radius: 4px;
              background: $Siemens_Border__Light;
              margin-top: 10px;
  
              .p-dropdown-label {
                padding: 0 0.5rem;
                font-size: $font-size-h5;
              }
  
              &:not(.p-disabled) {
                &:hover {
                  border-color: $Siemens_Petrol;
                }
  
                &.p-focus {
                  border-color: $Siemens_Petrol;
                  box-shadow: 0 0 0 0.1rem $Siemens_Petrol;
                }
              }
            }
          }
        }
      }
  
      .p-datatable {
        height: calc(100% - 68px);

        .p-datatable-wrapper{

          height: calc(100% - 81px);
          background-color: #fff;
        }
      }

    }

  }
}

.p-component-overlay {
  .report-pending {
    .p-dialog-content {
      form {
        .field {
          .reports-pending-title {
            padding: 3px 5px;
            background: $Siemens_Border__Light;
            span {
              color: $Grey__Dark;
              font-weight: 500;
              font-size: $font-size-h5;
            }
          }
          .report-pending-list {
            .list-group {
              .list-group-item {
                color: $Grey__Light;
                font-weight: 400;
                font-size: $font-size-h5;
                padding: 0.4rem 0.5rem;
                background: $white;
                border-color: $Siemens_Border__Light;
                border-bottom: 1px solid $Siemens_Border__Light;
              }
            }
          }
        }
      }
    }
    .p-dialog-footer{
        text-align: left;
        padding:0.5rem 1rem 0.5rem 1.5rem;
    
        .btn{
          &.cancel{
            border-color:$Siemens_Petrol;
        background: $white;
        color: $Grey__Dark;
        margin: 0 20px 0 0;
          }
          background: $Siemens_Petrol;
          border-color: $Siemens_Petrol;
          color: $white;
    
          &:focus {
            outline: none;
        box-shadow: none;
        text-decoration: none;
          }
        }
      }
  }
}
.red-link {
  color: red;
  text-decoration: underline; 
  word-break: break-all;
}

.full-view {
  word-break: break-all;
}

@import "CompareInspectionTasks/CompareInspectionTasks.scss";