.filters {
    &.expanded {
        background: $white;
        padding: 10px;
        width: 100%;
        color: $Grey__Dark;
        @include border-radius(8px);

        .heading {
            svg {
                cursor: pointer;
                color: $Siemens_Petrol;
            }

            span {
                padding-left: 10px;
                font-weight: 500;
                font-size: $font-size-h4;
            }
        }


        .p-autocomplete {
            margin-top: 10px;
            width: 100%;

            &.user-table-filter {
                +.selected-item {
                    max-width: 12rem;
                }

                .p-inputtext {
                    padding: 0 0 0 3px;
                }
            }

            i.pi-spin {
                color: $Siemens_Petrol;
            }

            .p-inputtext {
                background: $Grey_Medium;
                padding: 0 15px;
                font-size: $font-size-h5;
                border: 1px solid #D9EEEE;
                width:100%;
                &:focus {
                    box-shadow: none;
                }
            }

            .p-autocomplete-dropdown {
                background: $Grey_Medium;
                border: 1px solid #D9EEEE;
                padding: 0;

                .pi.pi-search {
                    left: unset;
                    right: 0.75rem;
                    color: $Siemens_Petrol;
                    font-weight: 600;
                }
            }
        }

        ul.list-items {
            padding: 0 0.5rem;

            li {
                font-size: $font-size-h5;
                margin-top: 0.5rem;
                cursor: pointer;

                &.active,
                &:hover {
                    background: $Siemens_Border__Light;
                    color: $Siemens_Petrol;
                }
            }
        }

        .selected-item {
            background: #CCEBEB;
            border-radius: 23px;
            max-width: 8.5rem;
            padding: 2px 10px;
            margin-top: 10px;

            .item-detail {
                .code {
                    background: #F0F4F7;
                    border-radius: 50%;
                    padding: 2px 5px;
                    color: #043736;
                    font-size: $font-size-h5;
                }

                .name {
                    padding-left: 10px;
                    color: #043736;
                    font-size: $font-size-h5;
                }
            }

            .pi-times {
                color: $Siemens_Petrol;
                font-weight: 700;
                font-size: $font-size-h5;
                cursor: pointer;
            }
        }

        .main {
            padding-top: 10px;

            ul {
                li.item {
                    font-size: $font-size-h5;
                    font-weight: 500;
                    padding-bottom: 12px;

                    span {
                        font-size: $font-size-h5;

                        &.selected-data {
                            color: $Siemens_Petrol;
                        }
                    }

                    svg {
                        width: 16px;
                        height: 16px;
                    }

                    &:last-child {
                        padding-bottom: 0;
                    }
                }
            }
        }
    }

    .ham-closed {
        color: $Siemens_Petrol;
        border: 1px solid $Siemens_Stone__Light;
        cursor: pointer;
    }
}