.common-attributes-list {
    margin: 0 24px;

    .breadcrumb-sub-page-title{
        color: $Siemens_Petrol;
        font-size: $font-size-h4;
            font-weight: 600;
    }

    .table-container {
        .common-attributes-filter {
            flex: 1;
            max-width: 2%;
            margin-right: 7px;

            &.expanded {
                max-width: 18%;
            }

            .filters.expanded {
                height: auto;
            }
        }

        .common-attributes-table {
            flex: 1;
            max-width: 98%;

            &.expanded {
                max-width: 81%;

                .table-header {
                    .title {
                        color: $Siemens_Petrol;
                        font-size: $font-size-h4;
                    }
               
                }
                

          
                
                .report-configs-table{
                    .p-datatable-header{

                        .caret-icon{
                            display: none;
                            
                        }
                    }
                }
            }

            .p-datatable-wrapper {
                height: calc(100vh - 230px);

                
               
            }
        }
        .common-attributes-value-table{
            .custom-table{

                .heading{
                    margin-bottom: 5px;
                }
                .p-datatable{

                    .p-datatable-header{
     border-top: none;
                        .search-section{
    
                            .attributes-dropdowns{
                                width: 100%;
                                justify-content: flex-end;
                            }
                        }
                    }
                }
            }
          
        }
        .Equipment-attributes-value-table{

            .custom-table{

                .p-datatable{

                    .p-datatable-header{

                        .search-section {
                            padding-top: 5px;
                            width: 100%;
                            
                            .table-search{
                                margin-right: 5px !important;

                                .p-inputtext{
                                    min-width: 13rem;
                                }

                            }

                            .attributes-dropdowns {
                                width: 100%;
                                justify-content: center;

                                .p-float-label{
                                    width: 12rem;
                                }
                                
                            }
                        }
                    }
                }

            }
        }
        .equipment-type-table{
            .custom-table{

                .heading{
                    margin-bottom: 5px;
                }
                .p-datatable{

                    .p-datatable-header{
     border-top: none;
                        .search-section{
    
                            .attributes-dropdowns{
                                width: 100%;
                                justify-content: flex-end;
                            }
                        }
                    }
                }
            }
          
        }
    }
}