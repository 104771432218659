.equipment-groups {
    .content-box {
        border: 1px solid $Siemens_Border__Light;
        padding: 0.5rem 1rem;
        @include border-radius(10px);
        margin-bottom: 8px;

        .header-text {
            margin-bottom: 10px;

            .value {
                font-weight: bold;
            }
        }

        .table-wrap {
            max-height: 50vh;
            overflow-y: auto;
            .p-datatable-header{
                display: none;
            }
        }

        .footer {
            padding-top: 1rem;
            padding-bottom: 0.2rem;

            button {
                margin: 0 1rem 0 0;
                height: 35px;

                &:focus {
                    box-shadow: none;
                }

                &:last-child {
                    margin: 0;
                }

                &.cancel-btn {
                    border-color: $Siemens_Petrol;
                    background: $white;
                    color: $Grey__Dark;
                }

                &.submit-btn {
                    background: $Siemens_Petrol;
                    border-color: $Siemens_Petrol;
                    color: $white;
                }

                .p-button-label {
                    font-size: $font-size-h4;
                    font-weight: 500;
                }
            }
        }
    }
}