.p-dialog{

    &.help-modal {

        position: relative;

        .header-minimize{

            margin-right: 8px;
        
            .btn-size{
                color: $Siemens_Petrol !important;
                background-color: transparent;
                border-radius: 50%;
                border: none;
                padding: 4px;
                height: 2rem;
                width: 2rem;
                display: flex;
                align-items: center;
                justify-content: center;
               
                &:hover {
                    color: #343a40 !important;
                    border-color: transparent;
                    background: #e9ecef;
                }
        
                &:focus {
        
                    box-shadow: none;
        
                }
        
            }
        
        }

        .p-dialog-content{   
            
            height: 520px;
            padding: 0;
            flex-grow: unset;
            overflow: unset;

            .help-wrapper{

                height: 100%;
                padding: 0 16px;

                .title-note{

                    padding-left: 0;
                    padding-right: 0;
    
                    .title{
                        background-color: $Siemens_Border__Light;
                        padding: 8px;
                        color: $Siemens_Petrol;
                
                        a{
                            color: $Siemens_Petrol;
                            text-decoration: underline;
                        }
                
                    }
                    
                }

            
                .content-area{
            
                    display: flex;
                    padding: 16px 0;
                    height: calc(100% - 64px);
                    width: 100%;
            
                    .tree-wrapper{
            
                        padding-left: 0;
                        height: 100%;
                        background: #ffffff;
                        color: #495057;
                        width: 180px;
                
                        .treeview-scroll{
                
                            margin-right: 8px;
                            height: 100%;
                
                            .p-tree{
                
                                height: 100%;
                                border: none;
                                padding: 0;
                                
                                .p-tree-container{ 
                
                                    .p-treenode{
                
                                        .p-treenode-content{
                
                                            padding: 4px;
                
                                            &.p-highlight{
                                                background-color: #CCEBEB;
                                                color: #495057;
                                            }
                                            
                                            &:hover{                
                                                background-color: #CCEBEB;                
                                            }
                
                                            &:focus{                
                                                box-shadow: unset;                
                                            }
                
                                            .p-tree-toggler{
                
                                                height: 1rem;
                                                width: 1rem;
                                                margin-right: 16px;
                                                color: #495057;
                
                                                &:hover{
                                                    background-color: unset;
                                                    color:$Siemens_Petrol;                                                    
                                                }
                
                                                &:focus{
                                                    box-shadow: unset;
                                                }
                
                                                .pi {
                                                    font-size: 12px;
                                                }
                
                                            }
                
                                            .p-treenode-label{
                
                                                font-size: 14px;
                                                font-weight: 300;
                            
                                            }
                
                                        }
                
                                    }
                
                                }
                                
                            }
                
                        }
                
                    }
                
                    .help-notes{
                
                        position: relative;
                        padding-right: 0;
                        padding-left: 24px;
                        height: 100%;
                        width: calc(100% - 180px);
                        

                        .help-notes-scroll{

                            height: 100%;

                            &::after{
                
                                content: "";
                                height: 100%;
                                width: 1px;
                                background-color:$Siemens_Petrol;
                                position: absolute;
                                top: 0;
                                left: 0;
                    
                            }
                
                            p{
                
                                font-size: 14px;
                
                            }

                        }
                
                    }
                    .h-100 {
                        height: 100 !important;
                    }
                    .w-100 {
                        width: 100% !important;
                        .help-notes-scroll{
                            height: 100%;
                            &::after{
                                display: none !important;
                            }
                        }
                    }

                    .pl-0 {
                        padding-left: 0 !important;
                    }
                    
                }
            
            }

        }


    }

    &.min{
        position: absolute !important;
        box-shadow: none !important;
        right: 0;
        bottom: 0;
        height: auto;
        width: 300px !important;

        .p-dialog-header{

            box-shadow: 0 -2px 3px rgb(0 0 0 / 30%);

            .p-dialog-title{

                margin-right: 24px;

            }

        }

        .p-dialog-content{

            display: none !important;

        }

        .p-dialog-footer{

            display: none !important;

        }

    }

    .cursor-pointer {
        cursor: pointer !important;
    }

    .help-document {

        .doc-title {
            color: $Siemens_Petrol !important;
        }

        .doc-subtitle {
            color: $Siemens_Petrol !important;
        }

        .doc-button-icon {
            color: $Siemens_Petrol !important;
            border-radius: 4px !important;
            padding: 0 !important;
            background: $Siemens_Border__Light !important;
            cursor: auto !important;
            height: 20px !important;
            width: 20px !important;
            border: none;
            .icon {
                padding: 5px !important;
                font-size: 12px !important;
            }
        }

        .bullet-list {
            font-size: 14px !important;
            list-style: disc !important;
            padding-left: 32px !important;
        }

        .decimal-list {
            font-size: 14px !important;
            padding-left: 32px !important;
            list-style-type: decimal !important;
        }
        
        .font-14 {
            font-size: 14px !important;
        }

        .w-36 {
            width: 36px !important;
        }

        .caret-small {
            height: 8px !important;
            width: 10px !important;
            margin-left: -4px !important;
        }

        .adjust-font-help {
            background: #CCEBEB !important;
            border-radius: 20px !important;
            padding: 0px 15px !important;
            width: 76px !important;
            .text {
                font-weight: 700 !important;
                .small {
                    color: #0F1923 !important;
                    font-size: 10px !important;
                    margin-right: 10px !important;
                }
                .medium {
                    font-size: 12px !important;
                    margin-right: 12px !important;
                }
                .large {
                    color: #0F1923 !important;
                    font-size: 14px !important;
                }
                .active {
                    color: $Siemens_Petrol !important;
                }
            }
        }
    }

}