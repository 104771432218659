.Parent{

    height: calc(100% - 97px);
    padding: 0px 10px 0px 15px;
    margin: 0px 10px;

    .breadcrumb-sub-page-title{

        color: $Siemens_Petrol;
        font-size: $font-size-h4;
        font-weight: 600;
        
    }

    .table-header-wrapper{
                
        display: flex;
        background-color: #fff;
        padding: 12px 16px;
        align-items: center;
        justify-content: space-between;
    
        .section-wrapper{
    
            display: flex;
            align-items: center;
    
            .tab-col{

                ul{

                    display: flex;

                    li{
    
                        background: #FFFFFF;
                        box-shadow: 0px 2px 6px rgb(0 0 0 / 15%);
                        border-radius: 8px;
                        font-weight: 500;
                        font-size:13px;
                        border: 0;
                        //color: $Siemens-Dark_Green;
                        margin: 0px 16px 0px 0px;
                        padding: 8px 16px;
                        color: #000;
                
                        &:hover{
                
                            background-color: $Siemens_Petrol;
                            color: #fff;
                            
                        }
                
                        
                        
                    }

                    .selected{
                        background-color: $Siemens_Petrol;
                        color: #fff;
                    }

                }
    
            }
    
            .country-item {
                border: 1px solid $Border_Light;
                cursor: pointer;
    
                img {
                    width: 22px;
                }
    
                span {
                    font-size: $font-size-h5;
                    font-weight: 500;
                    color: inherit;
                }
    
                &.active {
                    border: 1px solid $Siemens_Petrol;
    
                    span {
                        color: $Siemens_Petrol;
                    }
                }
            }
    
            .field {
    
                margin-left: 15px;
    
                .p-float-label {
    
                    .p-inputtext {
                        //background: $Grey_Medium;
                        background-color: #fff;
                        min-width: 18rem;
                        padding: 2px 10px;
    
                        &:focus + label,
                        &.p-filled + label {
                            color: $Siemens_Petrol;
                            top: 0;
                            background: $white;
                            padding-left: 2px;
                        }
                    }
    
                    label {
                    font-size: 12px;
                    font-weight: 400;
                    top: 0;
                    background: #FFFFFF;
                    padding-left: 2px;
                    padding-top: 2px;
    
                    &.mandatory {
                        &::after {
                        content: " *";
                        color: #fe0808;
                        }
                    }
                    }
    
                    .p-dropdown {
                    .p-dropdown-label {
                        font-size: $font-size-h5;
                    }
    
                    &:not(.p-disabled) {
                        &:hover {
                        border-color: $Siemens_Petrol;
                        }
    
                        &.p-focus {
                        border-color: $Siemens_Petrol;
                        box-shadow: 0 0 0 0.1rem $Siemens_Petrol;
                        }
                    }
                    }
                    
                    .p-dropdown {
                    &.error {
                        border-color: $Red__Light;
                        box-shadow: none;
                    }
                    }
    
                    .p-inputtext {
                    &.error {
                        border-color: $Red__Light;
                        box-shadow: none;
                    }
    
                    &:enabled {
                        &:hover {
                        border-color: $Siemens_Petrol;
                        }
    
                        &:focus {
                        border-color: $Siemens_Petrol;
                        box-shadow: 0 0 0 0.1rem $Siemens_Petrol;
                        }
                    }
                    }
                }

            }
        }
    
        .action-btns {
            @include display(flex);
            @include flex-alignItems(center);
            @include flex-justifyContents(space-evenly);
            background: $Siemens_Border__Light;
            color: $Siemens_Petrol;
            @include border-radius(4px);
            padding: 3px 7px 3px 15px;
            cursor: pointer;

            .button-icon {
                background: inherit;
                color: inherit;
                border: none;
                padding: 0;
                box-shadow: none;
                margin-right: 20px;
                display: flex;
                align-items: center;

                .icon {
                    width: 16px;
                    height: 16px;
                }

                .caret-icon {
                    width: 15px;
                    height: 12px;
                    position: relative;
                    left: 2px;
                    color: $Blue__Light;
                    padding: 0;

                    &:focus {
                        box-shadow: none;
                    }
                }
            }
        }
    
    }

    .grid-wrapper{

        height:calc(100% - 104px);
        margin-top:8px;
        overflow: auto;

        .questions{

            height: 100%;  
            display: flex;
            overflow-x: hidden;
        
            .tree-wrapper{
        
                width: 18%;
                padding: 0;
                height: 100%;
                border: 1px solid #dee2e6;
                background: #ffffff;
                color: #495057;
                border-radius: 6px;
                max-width: 18%;
                margin-right: 8px;
    
                // &.expanded {
                //     max-width: 100%;
                // } 
        
                .header{
        
                    display: flex;
                    padding: 12px 8px 8px 8px;
                    justify-content: space-between;
        
                    svg{
                       color: $Siemens_Petrol;
                       cursor: pointer;
                    }
        
                    .action-btns {
                        @include display(flex);
                        @include flex-alignItems(center);
                        @include flex-justifyContents(space-evenly);
                        background: $Siemens_Border__Light;
                        color: $Siemens_Petrol;
                        @include border-radius(4px);
                        padding: 3px 0px 3px 12px;
                        cursor: pointer;
        
                        .button-icon {
                            background: inherit;
                            color: inherit;
                            border: none;
                            padding: 0;
                            box-shadow: none;
                            margin-right: 12px;
        
                            .icon {
                                width: 16px;
                                height: 16px;
                            }
        
                            .caret-icon {
                                width: 15px;
                                height: 12px;
                                position: relative;
                                left: 2px;
                                color: $Blue__Light;
                                padding: 0;
        
                                &:focus {
                                    box-shadow: none;
                                }
                            }
                        }
                    }
        
                }
        
                .treeview-scroll{
        
                    height: calc(100% - 44px);
                    margin-right: 8px;
                    margin-left: 4px;
        
                    .p-tree{
        
                        height: 100%;
                        border: none;
                        padding: 0;
                        
                        .p-tree-container{ 
        
                            .p-treenode{
        
                                .p-treenode-content{
        
                                    padding: 4px;
        
                                    &.p-highlight{
                                        background-color: #CCEBEB;
                                        color: #495057;
                                    }
                                    
                                    &:hover{
                
                                        background-color: #CCEBEB;
                
                                    }
                
                                    &:focus{
                
                                        box-shadow: unset;
                
                                    }
                
                                    .p-tree-toggler{
                
                                        height: 1rem;
                                        width: 1rem;
                                        margin-right: 16px;
                                        color: #495057;
                
                                        &:hover{
                
                                            background-color: unset;
                                            color:$Siemens_Petrol;
                                            
                                        }
                
                                        &:focus{
                
                                            box-shadow: unset;
                    
                                        }
                
                                        .pi {
                
                                            font-size: 12px;
                
                                        }
                
                                    }
                
                                    .p-treenode-label{
                
                                        font-size: 14px;
                                        font-weight: 300;
                                        max-width: 75%;   
                                        word-wrap: break-word;
                    
                                    }
        
                                }
        
                            }
        
                        }
                        
                    }
        
                }
        
            }
        
            .expanded{
                color: #009999;
                border: 1px solid #BECDD7;
                cursor: pointer;
                height: 26px;
                margin-right: 8px;
            }
        
            .report-mapping-grid{
                max-width: 100%;
                min-width: 82%;
                overflow: auto;
    
                &.expand {
                    width: 100%;
                } 
            }
        
        }

    }

}
