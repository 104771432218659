.copy-container {
    margin: 0px 24px;
    height: calc(100% - 81px);
    padding: 0;

    .breadcrumb-sub-page-title {
        color: $Siemens_Petrol;
        font-size: $font-size-h4;
        font-weight: 600;
    }

    .field-checkbox {
        label {
            color: $Siemens_Petrol;
            font-weight: 400;
            margin-left: 12px;
        }
    }

    .btn {
        background: #009999;
        border-color: #009999;
        color: #ffffff;

        &:hover{

            background: #009999;
            border-color: #009999;
            color: #ffffff;

        }

        &:focus {
            outline: none;
            box-shadow: none;
            text-decoration: none;
        }
    }

    .copy-main {

        background-color: #fff;
        height: calc(100% - 60px);
        padding: 0px 18px 32px 18px;
        border-radius: 8px 8px 0px 0px;
       
        .system-select {
            .field-radiobutton {
                label {
                    color: $Siemens_Petrol;
                    font-weight: 600;
                }
            }
        }

        .copy-system {
            padding: 16px;
            border: 1px solid $Siemens_Petrol;
            border-radius: 4px;
            height: calc(100% - 38px);

            .title {
                color: $Siemens_Petrol;
            }

            .send-recieve-items {

                form {
                    display: flex;
                    justify-content: space-between;

                    .box {
                        box-shadow: 0px 4px 6px #00999926;
                        border-radius: 8px;
                        width: 600px;
                        padding: 10px;
                        border: 0.2px solid $Siemens_Petrol;

                        .field {
                            .action-btns {
                                @include display(flex);
                                @include flex-alignItems(center);
                                @include flex-justifyContents(space-evenly);
                                background: $Siemens_Border__Light;
                                color: $Siemens_Petrol;
                                @include border-radius(4px);
                                padding: 3px 0px 3px 12px;
                                cursor: pointer;
                                width: 35px;
                                height: 35px;

                                .button-icon {
                                    background: inherit;
                                    color: inherit;
                                    border: none;
                                    padding: 0;
                                    box-shadow: none;
                                    margin-right: 12px;

                                    .icon {
                                        width: 16px;
                                        height: 16px;
                                    }

                                    .caret-icon {
                                        width: 15px;
                                        height: 12px;
                                        position: relative;
                                        left: 2px;
                                        color: $Blue__Light;
                                        padding: 0;

                                        &:focus {
                                            box-shadow: none;
                                        }
                                    }
                                }

                                .button-icon {
                                    background: inherit;
                                    color: inherit;
                                    border: none;
                                    padding: 0;
                                    box-shadow: none;
                                    margin-right: 12px;

                                    .icon {
                                        width: 16px;
                                        height: 16px;
                                    }

                                    .caret-icon {
                                        width: 15px;
                                        height: 12px;
                                        position: relative;
                                        left: 2px;
                                        color: $Blue__Light;
                                        padding: 0;

                                        &:focus {
                                            box-shadow: none;
                                        }
                                    }
                                }
                            }

                            .p-float-label {
                                .label {
                                    font-size: 0.8125rem;
                                    font-weight: 400;
                                    color: #009999;
                                }

                                &.text-container {
                                    label {
                                        position: relative;
                                        left: 0;
                                        min-width: 70px;
                                    }

                                    .text-value {
                                        font-size: $font-size-h5;
                                        color: $Siemens_Petrol;
                                        margin-left: 30px;
                                        font-weight: 700;
                                    }
                                }

                                .p-dropdown {
                                    .p-dropdown-label,
                                    label {
                                        padding: 0.5rem;
                                        font-size: $font-size-h5;
                                    }

                                    &:not(.p-disabled) {
                                        &:hover {
                                            border-color: $Siemens_Petrol;
                                        }

                                        &.p-focus {
                                            border-color: $Siemens_Petrol;
                                            box-shadow: 0 0 0 0.1rem $Siemens_Petrol;
                                        }
                                    }
                                }

                                .p-calendar,
                                .p-dropdown,
                                .p-inputnumber {
                                    &.p-inputwrapper-focus + label,
                                    &.p-inputwrapper-filled + label {
                                        color: $Siemens_Petrol;
                                        top: 0;
                                        background: $white;
                                        padding-left: 2px;
                                    }

                                    &.p-inputwrapper-filled + label {
                                        color: $Siemens_Petrol;
                                    }
                                }

                                .p-calendar,
                                .p-dropdown,
                                .p-inputnumber {
                                    &.p-inputwrapper-focus + label,
                                    &.p-inputwrapper-filled + label {
                                        color: $Siemens_Petrol;
                                        top: 0;
                                        background: $white;
                                        padding-left: 2px;
                                    }

                                    &.p-inputwrapper-filled + label {
                                        color: $Siemens_Petrol;
                                    }
                                }

                                .p-inputtext {
                                    &.error {
                                        border-color: $Red__Light;
                                        box-shadow: none;
                                    }

                                    &:enabled {
                                        &:hover {
                                            border-color: $Siemens_Petrol;
                                        }

                                        &:focus {
                                            border-color: $Siemens_Petrol;
                                            box-shadow: 0 0 0 0.1rem $Siemens_Petrol;
                                        }
                                    }
                                }

                                .p-calendar,
                                .p-inputtext {
                                    padding: 0.5rem;
                                    font-size: $font-size-h5;
                                    width: 100%;

                                    &:focus + label,
                                    &.p-filled + label {
                                        color: $Siemens_Petrol;
                                        top: 0;
                                        background: $white;
                                        padding-left: 2px;
                                    }
                                }

                                label {
                                    font-size: $font-size-h5;
                                    font-weight: 400;
                                    color: #009999;

                                    &.mandatory {
                                        &::after {
                                            content: " *";
                                            color: #fe0808;
                                        }
                                    }
                                }

                                .p-dropdown {
                                    &.error {
                                        border-color: $Red__Light;
                                        box-shadow: none;
                                    }
                                }

                                .p-autocomplete {
                                    width: 100%;

                                    .p-autocomplete-input {
                                        width: 100%;
                                    }

                                    &.p-inputwrapper-focus + label,
                                    &.p-inputwrapper-filled + label {
                                        color: $Siemens_Petrol;
                                        top: 0 !important;
                                        background: $white;
                                        padding-left: 2px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

// SAPImportStatus.tsx page style
@import "SAPImportStatus/SapImportStatus.scss";
