.reports-modal {

    .p-dialog-header {
        padding: 0rem 1rem;
        position: relative;
        top: 0.5px;

        .p-dialog-title {
            font-size: $font-size-h4;
            color: $Siemens_Petrol;
            font-weight: 600;
        }

        .p-dialog-header-icons {
            .p-dialog-header-close {
                color: $Siemens_Petrol;

                &:focus {
                    box-shadow: none;
                }
            }
        }
    }
    .sub-header {
        font-size: $font-size-h5;
        color: $badge-red-count;
        margin-bottom: 0.2rem;
    }
    .p-dialog-content {
        padding: 0 1rem 0.5rem 1rem;
        overflow-y: hidden;

        .content-box {
            border: 1px solid $Siemens_Border__Light;
            padding: 0.5rem;
            @include border-radius(10px);

            .report-list {
                .card {
                    background: $white;
                    border: 1px solid #D9EEEE;
                    box-shadow: 0px 10px 23px rgba(204, 235, 235, 0.4);
                    @include border-radius(4px);
                    width: 100%;
                    overflow-y: auto;
                    padding: 7px 10px 0 10px;

                    &.info-section {

                        margin-bottom: 8px;
                        display: -webkit-inline-box;

                        .box {

                            display: flex;

                            .title {
                                font-size: $font-size-h5;
                                color: $Siemens_Petrol;
                                font-weight: 500;

                            }

                            .value {

                                font-size: $font-size-h5;
                                color: $Grey__Dark;
                                margin: 0 24px 8px 8px;

                            }
                        }
                    }

                    &.select-section {

                        display: grid ;
                        grid-template-columns: repeat(4, 1fr);
                        grid-column-gap: 16px;
                        grid-row-gap: 4px;
                        height: 120px;

                        &.ml-30 {
                            margin-left: 30px;
                        }

                        .field-checkbox {
                            margin-bottom: 10px;
                            min-width: 0;
                            display: flex;

                            label{

                                display: block;
                                overflow: hidden;
                                white-space: nowrap;
                                text-overflow: ellipsis;

                            }

                        }
                    }
                }
            }

            .report-table {
                .p-datatable {
                    .p-datatable-wrapper {
                        max-height: calc(100vh - 300px);
                        overflow-y: auto;

                        .p-datatable-table {
                            thead.p-datatable-thead {
                                tr {
                                    th {
                                        padding: 0.3rem 0.5rem;
                                        background: $Siemens_Border__Light;
                                        border: none;
                                        font-size: $font-size-h5;
                                        font-weight: 500;

                                        .p-column-header-content {
                                            .p-checkbox {
                                                .p-checkbox-box {
                                                    width: 20px;
                                                    height: 20px;
                                                    border-radius: 2px;
                                                    border-color: $Siemens_Petrol;

                                                    &.p-focus {
                                                        box-shadow: none;
                                                    }

                                                    &.p-highlight {
                                                        background: $Siemens_Petrol;
                                                    }

                                                    &:hover {
                                                        border-color: $Siemens_Petrol;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            tbody.p-datatable-tbody {
                                tr {

                                    &.p-highlight {
                                        color: $Siemens_Petrol;
                                    }

                                    td {
                                        padding: 0.3rem 0.5rem;
                                        background: light-shade($Grey_Medium, 75%);
                                        border-color: $Siemens_Petrol;
                                        font-size: $font-size-h5;

                                        .p-checkbox {
                                            .p-checkbox-box {
                                                width: 20px;
                                                height: 20px;
                                                border-radius: 2px;
                                                border-color: $Siemens_Petrol;

                                                &.p-focus {
                                                    box-shadow: none;
                                                }

                                                &.p-highlight {
                                                    border-color: transparent;
                                                    background: $Siemens_Petrol;
                                                }

                                                &:hover {
                                                    border-color: $Siemens_Petrol;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .footer {
        padding-top: 1rem;
        padding-bottom: 0.2rem;

        button {
            margin: 0 1rem 0 0;
            height: 35px;

            &:focus {
                box-shadow: none;
            }

            &:last-child {
                margin: 0;
            }

            &.cancel-btn {
                border-color: $Siemens_Petrol;
                background: $white;
                color: $Grey__Dark;
            }

            &.submit-btn {
                background: $Siemens_Petrol;
                border-color: $Siemens_Petrol;
                color: $white;
            }

            .p-button-label {
                font-size: $font-size-h4;
                font-weight: 500;
            }
        }
    }
}