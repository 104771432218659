.inspection-forms-wrapper {

	background-color: #fff;
	border-radius: 5px;
	padding: 10px;
	width: 82%;
	height: 100%;

	&.expand {

		width: 100%;

	}

	.main-wrapper {

		border: 1px solid $Siemens_Petrol;
		border-radius: 5px;
		padding: 0px 24px 16px;
		height: 100%;

		.title-header {

			display: flex;
			justify-content: space-between;
			align-items: center;
			border-bottom: 1px solid $Siemens_Petrol;
			padding: 8px 0px;
			h6 {

				font-size: 14px;
				color: $Siemens_Petrol;
				font-weight: 600;
				word-break: break-word;
			
		}

			.action-btns {

				@include display(flex);
				@include flex-alignItems(center);
				@include flex-justifyContents(space-evenly);
				background: $Siemens_Border__Light;
				color: $Siemens_Petrol;
				@include border-radius(4px);
				padding: 9px 7px 9px 15px;
				cursor: pointer;

				.button-icon {

					background: inherit;
					color: inherit;
					border: none;
					padding: 0;
					box-shadow: none;
					margin-right: 20px;

					.icon {

						width: 16px;
						height: 16px;

					}

				}

			}

		}

		.data-wrapper-scroll {

			height: calc(100% - 51px);

			.p-scrollpanel-content {

				padding: 1px 18px 1px 0;

				.data-wrapper {

					width: 80%;
					padding-top: 8px;

					@include cmw(1250) {

						width: 100%;

					}

					.data-col-1 {

						padding: 8px 0px 8px 0px;
						width: 100%;

						&.if-border {

							border: 1px solid #CDCDCD;
							border-radius: 5px;
							margin-top: 16px;
							padding: 24px;

						}

						.wrap {

							display: flex;
							padding: 4px 0px 8px;
							justify-content: space-between;
							align-items: center;

							.field-name {

								position: relative;
								display: flex;
								align-items: center;
								width: 25%;
								margin-right: 16px;

								&.name-100 {

									width: 100%;

								}

								.h6-offset {

									width: calc(100% - 18px);

									h6 {

										font-size: 14px;
										font-weight: 600;
										color: #646464;
										word-break: break-word;

									}

								}

								.field-icon {

									width: 18px;
									height: 18px;
									border-radius: 50%;
									background-color: $Siemens_Petrol;
									padding: 4px;
									color: #fff;
									margin-right: 16px;

								}

								.field-icon-disabled {

									background-color: #CDCDCD !important;

								}

								.offset {

									font-size: 13px;
									width: calc(100% - 18px);
									word-break: break-word;

								}

							}

							.field-div {

								width: 70%;
								margin-right: 32px;

								.field {

									width: 100%;
									margin-bottom: 5px;

									&.if-vertical {

										display: flex;
										overflow: auto;

										.field-checkbox {

											margin-right: 24px;

										}

									}

									&.if-horizontal {

										display: flex;
										flex-direction: row;
										overflow: auto;

										.field-checkbox {

											margin-right: 24px;
											white-space: nowrap;

										}

									}

									.radio-section {

										&.if-horizontal {

											display: flex;
											flex-direction: row;
											overflow: auto;
											white-space: nowrap;

											.field-radiobutton {

												margin-right: 24px;

											}

										}

									}

									.field-radiobutton {

										margin-bottom: 0;
										height: 35px;

										&:last-child {
											margin-bottom: 0px;
										}
									}

									.pop-subhead {

										b {

											font-size: $font-size-base;

										}

									}

									.field-checkbox {
										height: 35px;

										label {
											color: #37465a;
											font-weight: 400;
										}
									}

									.child-all {

										margin-top: 8px;
										display: block;
										padding-left: 27px;

									}

									.checkbox-list {

										padding: 0.5rem 0.5rem 0.7rem 0.5rem;
										font-size: 0.8125rem;
										border: 1px solid #ced4da;
										transition: background-color 0.2s, color 0.2s, border-color 0.2s,
											box-shadow 0.2s;
										appearance: none;
										border-radius: 6px;

										.checkbox-list-scrollbar {

											.p-scrollpanel-wrapper {

												border-right: 6px solid var(--surface-b);

											}

											.p-scrollpanel-bar {

												background: $Siemens_Petrol;
												border: 0 none;
												opacity: 0.5;
												width: 5px;

												&:hover {

													background-color: $Siemens_Petrol;
													opacity: 1;

												}

											}

										}

										.p-float-label {

											margin-top: -8px;

											.checkbox-list-label {

												position: relative;
												top: -10px;
												left: 5px;
												background-color: $white;

											}

										}

									}

									.p-float-label {

										&.text-container {

											label {

												position: relative;
												left: 0;
												min-width: 70px;

											}

											.text-value {

												font-size: $font-size-h5;
												color: $Siemens_Petrol;
												margin-left: 30px;

											}

										}

										.p-inputtext {

											padding: 0.5rem;
											font-size: $font-size-h5;
											height: 35px;
											width: 100%;

											&:focus+label,
											&.p-filled+label {

												color: $Siemens_Petrol;
												top: 0;
												padding-left: 2px;
											}

										}

										.p-inputtextarea {

											height: 152px;

										}

										label {

											font-size: $font-size-h5;
											font-weight: 400;
											overflow: hidden;
											white-space: nowrap;
											text-overflow: ellipsis;
											width: 97%;

											span {

												background: $white;
												padding: 0 2px;
												line-height: 16px;

											}

											&.mandatory {

												&::after {

													content: " *";
													color: #fe0808;
													background: #fff;

												}

											}

										}

										.p-dropdown {
											.p-dropdown-label {
												padding: 0.5rem;
												font-size: $font-size-h5;
											}

											&:not(.p-disabled) {
												&:hover {
													border-color: $Siemens_Petrol;
												}

												&.p-focus {
													border-color: $Siemens_Petrol;
													box-shadow: 0 0 0 0.1rem $Siemens_Petrol;
												}
											}
										}


										.p-calendar,
										.p-dropdown,
										.p-inputnumber {

											&.p-inputwrapper-focus+label,
											&.p-inputwrapper-filled+label {
												color: $Siemens_Petrol;
												top: 0;
												//background: $white;
												padding-left: 2px;
											}

											&.p-inputwrapper-filled+label {
												color: $Siemens_Petrol;
											}
										}

										.p-dropdown {
											&.error {
												border-color: $Red__Light;
												box-shadow: none;
											}
										}

										.p-calendar,
										.p-inputnumber {
											&.error {
												.p-inputtext {
													border-color: $Red__Light;
													box-shadow: none;
												}
											}
										}

										.p-inputtext {
											&.error {
												border-color: $Red__Light;
												box-shadow: none;
											}

											&:enabled {
												&:hover {
													border-color: $Siemens_Petrol;
												}

												&:focus {
													border-color: $Siemens_Petrol;
													box-shadow: 0 0 0 0.1rem $Siemens_Petrol;
												}
											}
										}

										&.action-btns {
											@include display(flex);
											@include flex-alignItems(center);
											@include flex-justifyContents(space-between);
											background: $Siemens_Border__Light;
											color: $Siemens_Petrol;
											@include border-radius(4px);
											cursor: pointer;
											height: 32px;
											width: 65px;
											padding: 0 10px;

											&.add-icons {
												padding: 0 10px;
												width: 65px;
											}

											&.edit-icons {
												padding: 0 7px;
												width: 30px;
											}

											.button-icon {
												background: inherit;
												color: inherit;
												border: none;
												padding: 0;
												box-shadow: none;

												.icon {
													width: 16px;
													height: 16px;
												}
											}
										}
									}
								}

							}

							.action-btns {

								@include display(flex);
								@include flex-alignItems(center);
								@include flex-justifyContents(space-evenly);
								background: $Siemens_Border__Light;
								color: $Siemens_Petrol;
								@include border-radius(4px);
								padding: 9px 7px 9px 15px;
								cursor: pointer;
								height: 34px;

								.button-icon {
									background: inherit;
									color: inherit;
									border: none;
									padding: 0;
									box-shadow: none;
									margin-right: 20px;

									.icon {
										width: 16px;
										height: 16px;
									}
								}

							}

						}

						.wrap-validate {

							flex-direction: column;

							.field-name {

								width: 100%;
								justify-content: space-between;

								span {

									display: flex;
								}

							}

							.field-div {

								padding-left: 24px;
								width: 100%;

							}
						}

					}

				}

			}

		}

	}

}

#section-node{
	width: 100%;
	word-break: break-word;
}
