.condition-container {
    display: flex;

    .conditions-list {
        width: 85%;
        border: 1.5px solid $Siemens_Petrol;
        padding: 5px;
        margin-bottom: 15px;
        height: 250px;

        .p-datatable {
            .p-datatable-wrapper {
                table {
                    .p-datatable-thead {
                        display: none;
                    }

                    .p-datatable-tbody {

                        .p-datatable-emptymessage {
                            display: none;
                        }

                        .p-selectable-row{

                            &.p-highlight{
                                color:$Siemens_Petrol;
                                background: #CCEBEB;
                                outline:none;
                            }

                            &:focus {
                                outline:none;
                            }

                            td{

                                padding: 0;
                                border: none;
                                width: 33.3%;
                                font-size: 14px;

                            }
                            

                        }
                        
                    }
                }
            }
        }
    }
    .conditions-button {
        display: flex;
        flex-direction: column;
        padding-left: 15px;
        .btn {
            background: $Siemens_Petrol;
            border-color: $Siemens_Petrol;
            color: $white;
            margin-bottom: 10px;
            &:focus {
                outline: none;
                box-shadow: none;
                text-decoration: none;
            }
        }
    }
}