.performance-indicator {
    display: flex;
    height: 100%;

    .left {
        width: 60%;
        height: 100%;
        padding-right: 16px;

        .top,
        .bottom {
            height: 50%;
            width: 100%;
        }

        .top {
            padding: 8px 0;

            .dashboard-charts__area {
                height: 100%;
                width: 100%;
                display: flex;            

                .task-completion__wrapper {
                    background-color: $white;
                    height: 100%;
                    width: 100%;
                    padding-right: 16px;
                    position: relative;

                    .title {
                        height: 24px;
                        padding: 0 8px;
                        text-align: center;

                        h6 {
                            height: 24px;
                            line-height: 24px;
                            color: $Siemens_Petrol;
                        }

                    }

                    .icon {
                        position: absolute;
                        right: 0;
                        color: #009999;
                        cursor: pointer;
                        top: 0;
                    }

                    .bar-chart {
                        height: calc(100% - 24px);
                    }

                    .more-btn {
                        height: 24px;
                        text-align: center;

                        span {
                            display: inline-block;
                            height: 24px;
                            line-height: 24px;
                            cursor: pointer;
                            font-size: 12px;

                            &:hover {
                                text-decoration: underline;
                            }
                        }
                    }
                }

                .performance-indicator__wrapper {
                    background-color: $white;
                    height: 100%;
                    width: 60%;
                    display: none;

                    .title {
                        height: 24px;
                        padding: 0 8px;
                        text-align: center;
                        display: block;
                        width: 100%;

                        h6 {
                            height: 24px;
                            line-height: 24px;
                            color: $Siemens_Petrol;
                        }
                    }

                    .chart-area {
                        height: calc(100% - 72px);
                        display: flex;
                        width: 100%;

                        .devices,
                        .tasks {
                            width: 50%;

                            .pie-title {
                                height: 24px;
                                padding: 0 8px;
                                text-align: center;
                                display: block;
                                width: 100%;

                                h6 {
                                    height: 24px;
                                    line-height: 24px;
                                    color: $body-font-color;
                                }
                            }

                            .pie-charts {
                                height: calc(100% - 24px);
                            }
                        }
                    }

                    .performance-label {
                        display: flex;
                        justify-content: space-around;
                        padding-bottom: 4px;
                        height: 100%;
                        height: 40px;
                        padding-top: 8px;

                        .item {
                            height: 24px;

                            span {
                                display: inline-block;
                                height: 24px;
                                line-height: 24px;
                                font-size: 12px;

                                span {
                                    font-size: 12px;

                                    &.count {
                                        height: 24px;
                                        width: 24px;
                                        background-color: $Siemens_Stone__Dark;
                                        font-weight: 600;
                                        color: $white;
                                        text-align: center;
                                        margin-right: 8px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .bottom {
            border-top: 1px solid $Siemens_Petrol;

            .contracts-percentage {
                height: 100%;
                padding: 8px 0 16px 0;

                .title {
                    height: 40px;

                    h6 {
                        text-align: center;
                        color: $Siemens_Petrol;
                        line-height: 24px;
                        margin-bottom: 16px;
                    }
                }

                .recharts-responsive-container {
                    height: calc(100% - 24px) !important;

                    .recharts-wrapper {
                        .recharts-cartesian-axis {
                            &.xAxis {
                                .recharts-cartesian-axis-ticks {
                                    .recharts-layer {
                                        font-size: 12px;
                                    }
                                }
                            }
                        }

                        .recharts-legend-wrapper {
                            .recharts-default-legend {
                                .recharts-legend-item {
                                    .recharts-legend-item-text {
                                        font-size: 12px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .right {
        width: 40%;
        height: 100%;
        padding-left: 16px;
        padding-top: 8px;
        border-left: 1px solid $Siemens_Petrol;

        .pie-graph__wrapper {
            background-color: $white;
            height: 100%;
            position: relative;

            span{

                &.icon{
    
                    position: absolute;
                    top: 0;
                    right: 0;
                    color: $Siemens_Petrol;
                    cursor: pointer;
    
                }
    
            }

            .title {
                height: 24px;
                padding: 0 8px;
                text-align: center;
                display: block;
                width: 100%;

                h6 {
                    height: 24px;
                    line-height: 24px;
                    color: $Siemens_Petrol;
                }
            }

            .tab-area {
                height: calc(100% - 24px);

                .nav-tabs {
                    border: none;
                    margin-bottom: 8px;
                    height: 32px;

                    .nav-link {
                        font-size: $font-size-h5;
                        text-align: center;
                        line-height: 32px;
                        margin-right: 32px;
                        padding: 0;
                        color: $body-font-color;
                        text-transform: capitalize;
                        background-color: transparent;
                        border: none;
                        position: relative;

                        &:hover {
                            color: light-shade($body-font-color, 40%);
                        }

                        &.active {
                            font-weight: 600;
                            color: $body-font-color;
                            background-color: transparent;
                            border: none;

                            &::before {
                                content: "";
                                display: block;
                                position: absolute;
                                bottom: 0;
                                left: 0;
                                right: 0;
                                margin: auto;
                                height: 3px;
                                width: 80%;
                                background: $Siemens_Petrol;
                                @include transition(left 0.5s);
                                @include border-radius(2px);
                            }
                        }

                        &::after {
                            content: attr(data-rb-event-key);
                            font-weight: 600;
                            font-size: $font-size-h3;
                            display: block;
                            line-height: 0;
                            height: 0;
                            visibility: hidden;
                        }
                    }
                }

                .tab-content {
                    height: calc(100% - 40px);

                    .tab-pane {
                        height: 100%;

                        .pie-chart {
                            height: 100%;
                        }

                        .p-datatable-wrapper {
                            height: calc(100% - 37px);
                        }
                    }
                }
            }
        }
    }
            
    &.inspectionactivity_expand {

        width: 100%;
        height: 100%;

        .right {

            display: none;

        }

        .left {

            width: 100%;

            .top {
                height: 100%;

            }

            .bottom {
                display: none;
            }
        }
    }

    &.project-contract_expand{

        .left{

            width: 100%;
            padding-right: 0;

            .top{

                display: none;
    
            }
    
            .bottom{
    
                width: 100%;
                height: 100%;
                border-top: 0;

                .contract-graph__wrapper{

                    .nav-tabs{

                        .nav-item{

                            &:last-child{

                                display: none;
    
                            }

                        }

                    }

                }
    
            }

        }

        .right{

            display: none;

        }

    }

    &.service-contract_expand{

        .left{

            width: 100%;
            padding-right: 0;

            .top{

                display: none;
    
            }
    
            .bottom{
    
                width: 100%;
                height: 100%;
                border-top: 0;

                .contract-graph__wrapper{

                    .nav-tabs{

                        .nav-item{

                            &:first-child{

                                display: none;

                            }

                        }

                    }

                }
    
            }

        }

        .right{

            display: none;

        }

    }

    &.pieChart_expand{

        .left{

            display: none;

        }

        .right{

            width: 100%;
            padding-left: 0;
            border-left: 0;
            
                .pie-graph__wrapper {
            
                    .nav-tabs {
            
                        .nav-item {
            
                            &:last-child{

                                display: none;
    
                            }
            
                        }
            
                    }
            
                }
        }

    }
}

    .contract-graph__wrapper {
        background-color: $white;
        height: 100%;
        position: relative;

        span{

            &.icon{

                position: absolute;
                top: 0;
                right: 0;
                color: $Siemens_Petrol;
                cursor: pointer;

            }

        }

        .title {
            height: 24px;
            padding: 0 8px;
            text-align: center;
            display: block;
            width: 100%;

            h6 {
                height: 24px;
                line-height: 24px;
                color: $Siemens_Petrol;
            }
        }

        .tab-area {
            height: calc(100% - 24px);

            .nav-tabs {
                border: none;
                margin-bottom: 8px;
                height: 32px;

                .nav-link {
                    font-size: $font-size-h5;
                    text-align: center;
                    line-height: 32px;
                    margin-right: 32px;
                    padding: 0;
                    color: $body-font-color;
                    text-transform: capitalize;
                    background-color: transparent;
                    border: none;
                    position: relative;

                    &:hover {
                        color: light-shade($body-font-color, 40%);
                    }

                    &.active {
                        font-weight: 600;
                        color: $body-font-color;
                        background-color: transparent;
                        border: none;

                        &::before {
                            content: "";
                            display: block;
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            right: 0;
                            margin: auto;
                            height: 3px;
                            width: 80%;
                            background: $Siemens_Petrol;
                            @include transition(left 0.5s);
                            @include border-radius(2px);
                        }
                    }

                    &::after {
                        content: attr(data-rb-event-key);
                        font-weight: 600;
                        font-size: $font-size-h3;
                        display: block;
                        line-height: 0;
                        height: 0;
                        visibility: hidden;
                    }
                }
            }

            .tab-content {
                height: calc(100% - 40px);

                .tab-pane {
                    height: 100%;

                    .pie-chart {
                        height: 100%;
                    }

                    .p-datatable-wrapper {
                        height: calc(100% - 37px);
                    }
                }
            }
        }
    }