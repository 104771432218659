.breadcrumb {
    margin: 10px 0;
    @include display(flex);
    @include flex-alignItems(center);

    

    .breadcrumb-icon {
        background: $white;
        @include border-radius(50%);
        color: $Siemens_Petrol;
        margin-right: 10px;
        cursor: pointer;
        box-shadow: 0px 3px 6px rgb(0 0 0 / 25%);
    }

    .title {
        font-size: $font-size-h4;
        font-weight: 600;
    }

}

