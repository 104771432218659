.inspection-drawing-parent{

    margin: 0 24px;
    height: calc(100% - 98px);

    .asBuild-wrapper{

        display: flex;
        position: relative;
        margin-top: 2px;
        height: 100%;

        .as-build-drawing__wrapper{

            height: 100%;
            width:50%;
            padding-right: 8px;

            .asBuild-drawing{

                position: relative;
                height: 100%;

                .drawing-expand-btn{

                    position: absolute;
                    right: -8px;
                    top: 0px;
                    bottom: 0;
                    margin: auto;
                    box-shadow: 1px 1px 7px #acacac;
                    cursor: pointer;
                    background: white;
                    border-radius: 4px;
                    height: 24px;
                    width: 24px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    z-index: 99;

                    .icon{

                        color:$Siemens_Petrol;
                        width: 17px;
                        height: 17px;

                    }

                }

                .type-list{

                    background-color: $Siemens_Petrol;
                    border-radius: 8px 8px 0 0;
                    display: grid;
                    grid-template-columns: repeat(4,1fr);
                    grid-column-gap: 16px;
                    grid-row-gap:4px;
                    padding-right: 16px;
                    


                    .item{

                        padding: 0 8px;
                        min-width: 0;
                        height: 54px;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;

                        h6{

                            line-height: 20px;
                            color: $white;
                            font-size: $font-size-h5;
                        
                        }

                        span {

                            display: block;
                            line-height: 20px;
                            color: $white;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            font-size: $font-size-h5;
                            font-weight: 600;

                        }

                    }


                }

                .drawing-data{

                    background-color:$white;
                    height:calc(100% - 54px);
                    position: relative;

                    .toolbar{

                        display: flex;
                        position: absolute;
                        left: 16px;
                        top: 16px;
                        z-index: 999;

                        .search-box{

                            background: $Siemens_Border__Light;
                            color: $Siemens_Petrol;
                            @include border-radius(4px);
                            cursor: pointer;
                            margin-right: 16px;

                            .button-icon {

                                background: inherit;
                                color: inherit;
                                border: none;
                                padding: 0;
                                box-shadow: none;
                                height: 32px;
                                width: 32px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                      
                                .icon {

                                    width: 16px;
                                    height: 16px;

                                }

                            }

                        }
                        
                        .tools{

                            background: $Siemens_Border__Light;
                            color: $Siemens_Petrol;
                            @include border-radius(4px);
                            cursor: pointer;
                            display: flex;
                            padding: 0 0 0 12px;

                            .button-icon {

                                background: inherit;
                                color: inherit;
                                border: none;
                                padding: 0;
                                box-shadow: none;
                                height: 22px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                margin-right: 12px;
                      
                                .icon {

                                    width: 16px;
                                    height: 16px;

                                }

                                .caret-icon {

                                    width: 15px;
                                    height: 12px;
                                    position: relative;
                                    left: 2px;
                                    color: $Blue__Light;
                                    padding: 0;
                      
                                    &:focus {

                                        box-shadow: none;

                                    }

                                }

                            }

                        }

        

                    }

                    .drawing-wrapper{

                        height:calc(100% - 5px);

                        img{
                            width: 100%;
                            height: 100%;
                        }

                    }

                }

            }

        }
               
        .as-build-table__wrapper{

            height: 100%;
            width: 50%;
            position: relative;
            padding-left: 24px;

            .table-expand-btn{
                        
                position: absolute;
                left: 16px;
                top: 0;
                bottom: 0;
                margin: auto;
                box-shadow: 1px 1px 7px #acacac;
                cursor: pointer;
                background: white;
                border-radius: 4px;
                height: 24px;
                width: 24px;
                display: flex;
                justify-content: center;
                align-items: center;
                z-index:1000;

                .icon{

                    color:$Siemens_Petrol;
                    width: 17px;
                    height: 17px;

                }

            }

            .as-build-table{

                height: 100%;

                &.inspection-reports-drawing{

                    .p-tabview{
                        height: 100%;
                
                    .p-tabview-nav-container{
                        
                        .p-tabview-nav-content{
                
                            border-top-left-radius: 8px;
                            border-top-right-radius: 8px;
                            
                            .p-tabview-nav{
                
                                .p-tabview-ink-bar{
                                    background-color: $Siemens_Petrol;
                                }
                                .p-unselectable-text{
                                    .p-tabview-nav-link{
                                        color: $Siemens_Petrol;  
                                        border-color:#dee2e6;
                
                                        &:focus{  
                                            box-shadow: none;
                                        }
                                      }    
                                }
                                .p-highlight{
                                   
                                    .p-tabview-nav-link{
                                    color: $Siemens_Petrol;  
                                    border-color: $Siemens_Petrol;
            
                                    &:focus{  
                                        box-shadow: none;
                                    }
                                    
                                    }
                                   
                                }
                    
                            }
                            
                        }
                    }
                
                    .p-tabview-panels{
                
                        height: calc(100% - 58px);
                        padding: 0;

                        .p-tabview-panel{

                            height: 100%;

                            .header-second-row{
    
                                display: flex;
                                justify-content: end;
                                background-color: #fff;
                                align-items: center;
                                padding: 8px  8px;
                                height: 41.5px;
                                
                                @include cmw(1270) {
                
                                    flex-direction: row;
                                    display: block;
                                    
                                    .left-col{

                                        margin-bottom: 8px;

                                    }
                                   
                                }
                        
                                .left-col{
                        
                                    display: flex;
                                    align-items: center;
                                    width: calc(100% - 268px);
                
                                    .select-box{
                
                                        display: flex;
                                        align-items: center;
                                        width: 100%;
                                
                                        .custom-float-field{
                                
                                            display: flex;
                                            align-items: center;
                                            margin-bottom: 0;
                                            width: 100%;
                                
                                            .field-name-col{
                                
                                                display: flex;
                                                align-items: center;
                                                width: 70px;
                                
                                                span{
                                                    color: red;
                                                }
                                
                                            }
                                
                                            .field{
                                
                                                margin-bottom: 0;
                                                padding: 0;
                                                width: 100%;
                                                max-width: 210px;
                
                                                .p-dropdown{
                
                                                    .p-dropdown-label {
            
                                                        padding: 2px 10px;
                                                        width: 100%;
            
                                                    }
            
                                                    .pi {
            
                                                        font-size: 12px;
            
                                                    }
                
                                                }
            
                                                @include cmw(1350) {
                
                                                    width: 100%;
                                                    margin-right: 16px;
                                                    max-width: 100%;
                                                   
                                                }
                                
                                            }
                                
                                            .icon-fields {
                                                
                                                margin-left: 8px;
                                                
                                                .field {
                                                    margin-bottom: 0px;
                                                    .action-btns {
                                                        @include display(flex);
                                                        @include flex-alignItems(center);
                                                        @include flex-justifyContents(space-between);
                                                        @include border-radius(4px);
                                                        cursor: pointer;
                                                        height: 25px;
                                                        width: 25px;
                                                        padding: 7px;
                                                        background: $Siemens_Border__Light;
                                                        color: $Siemens_Petrol;
                                                        margin-right: 10px;
                                
                                                        .button-icon {
                                                            background: inherit;
                                                            color: inherit;
                                                            border: none;
                                                            padding: 0;
                                                            box-shadow: none;
                
                                                        }
                                                    }
                                                }
                                            }
                                
                                        }
                                
                                    }
                        
                                }
                        
                            }

                            .custom-table{

                                padding-left: 12px;
                                height: calc(100% - 38px);

                                .p-datatable{

                                    .p-datatable-header{

                                        display: none;

                                    }

                                    .p-datatable-wrapper {

                                        height: calc(100% - 37px);

                                    }

                                }
    
                            }

                        }
                        
                    }

                }
                    
                }
    
                
    
                .table-wrapper{
    
                    height:calc(100% - 62px);
                    background-color:$white;

                    .p-datatable-wrapper {

                        height: calc(100% - 45px);

                    }
    
                }
    
            }

        }

        &.drawing-f {

            .as-build-drawing__wrapper {

                width: calc(100% - 36px);

                .asBuild-drawing{

                    .drawing-expand-btn{
                        
                        //display: none;
                        transform: rotate(180deg);

                    }

                }

            }

            .as-build-table__wrapper {

                width: auto;

                &:after {

                    content: "";
                    width: 2px;
                    height: 100%;
                    background-color: #bdc0c3;
                    position: absolute;
                    top: 0;
                    right: -5px;
                }

                .as-build-table{

                    width: auto;

                    .p-tabview{

                        display: none;
                        
                    }
                }

            }
        }

        &.table-f {

            .as-build-drawing__wrapper{

                width:auto;

                &:after {

                    content: "";
                    width: 2px;
                    height: 100%;
                    background-color: #bdc0c3;
                    position: absolute;
                    top: 0;
                    left: -4px;
                }

                .type-list{

                    display: none;

                }

                .drawing-data{

                    display: none;

                }

            }

            .as-build-table__wrapper { 

                width: calc(100% - 8px);

                .table-expand-btn{

                    transform: rotate(180deg);
                    
                }

            }

        }

    }

}